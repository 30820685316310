import dayjs from "dayjs";

import { useTranslation } from "react-i18next";

import Stack from "components/Stack";
import Text from "components/Text";

import { StickerIcon } from "icons";

import { INotes } from "entities/FamilySpecialist/sdk";

interface IProps {
  item: INotes;
}

const NoteActivityBox = ({ item }: IProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Stack direction="row" gap="15px">
          <StickerIcon />
          <Text variant="titleMedium">
            {`${item.creator.first_name} ${item.creator.last_name} `}
            {t("addedANote")}
          </Text>
        </Stack>
        <Stack>
          <Text variant="bodyMedium" color="text.secondary">
            {dayjs(item.created_at).format("MM/DD/YYYY - hh:mm A")}
          </Text>
        </Stack>
      </Stack>

      <Text
        variant={"bodyMedium"}
        sx={{ flex: 1, ml: "40px", my: "16px" }}
        color="text.secondary"
      >
        {item.content}
      </Text>
    </>
  );
};

export default NoteActivityBox;
