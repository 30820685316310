import { post } from "utils/sdk";
import { BASE_URL } from "config/urls";

export interface IParent {
  first_name: string;
  last_name: string;
  gender: "MEN" | "WOMEN";
  preferred_name: string;
  preferred_language: string;
  employment_status: "EMPLOYED" | "TRAININGPROGRAM" | "IN_SCHOOL" | "OTHER";
  employer_name: string;
  other_status_description: string;
  date_of_birth?: string;
}

export const parentCreateInUserFamily = ({
  data,
}: {
  data: Partial<IParent>;
}) => post(`${BASE_URL}/api/family-members/parent/create/`, data);

export const parentUpdateInUserFamily = ({
  parentId,
  data,
}: {
  parentId: number;
  data: Partial<IParent>;
}) => post(`${BASE_URL}/api/family-members/parent/${parentId}/update/`, data);

export const parentCreateAsSpecialist = ({
  familyId,
  data,
}: {
  familyId: number;
  data: Partial<IParent>;
}) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${familyId}/parent/create/`,
    data
  );

export const parentUpdateAsSpecialist = ({
  parentId,
  data,
}: {
  parentId: number;
  data: Partial<IParent>;
}) =>
  post(`${BASE_URL}/api/family-specialists/parent/${parentId}/update/ `, data);
