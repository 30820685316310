import { useEffect } from "react";

import { useParams } from "react-router-dom";

import { signInMagicLink } from "entities/Auth/sdk";

import { URLS } from "config/urls";

const MagicLinkPage = () => {
  const { magicToken } = useParams<{ magicToken: string }>();

  useEffect(() => {
    signInMagicLink(magicToken!)
      .then(() => {
        window.location.href = "/";
      })
      .catch(() => {
        window.location.href = URLS.EXPIRED_MAGIC_LINK;
      });
  }, []);

  return null;
};

export default MagicLinkPage;
