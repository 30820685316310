import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";
import StyledSelect from "components/StyledSelect";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";

import { specialistAssign, useSpecialistData } from "entities/Documents/sdk";
import { colors } from "theme";
import { URLS } from "config/urls";
import { reverse } from "utils/urls";
import { useSignedUser } from "entities/Auth/sdk";

const Assign = () => {
  const { familyId: familyIdParam } = useParams<{
    familyId: string;
  }>();
  const familyId = parseInt(String(familyIdParam));

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: specialistData } = useSpecialistData();
  const specialistDataOptions =
    specialistData?.map((item) => ({
      value: item.id.toString(),
      label: `${item.first_name} ${item.last_name}`,
    })) || [];
  const matches = useMediaQuery("(min-width:600px)");
  const { data: user } = useSignedUser();
  const { control, getValues, reset } = useForm({
    defaultValues: {
      familyAdministrator: "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (user) {
      reset({
        familyAdministrator: user.id.toString(),
      });
    }
  }, [user, reset]);

  const handleSave = () => {
    const values = getValues();

    const assignData = {
      specialist_id: Number(values.familyAdministrator),
    };
    if (familyId) {
      if (!Number(values.familyAdministrator)) {
        navigate(
          reverse(URLS.FAMILY_SPECIALIST_FAMILY_DETAIL, {
            id: familyId.toString(),
          })
        );
        return;
      }
      setIsSubmitting(true);
      specialistAssign(familyId, assignData)
        .then(() => {
          setIsSubmitting(false);
          navigate(
            reverse(URLS.FAMILY_SPECIALIST_FAMILY_DETAIL, {
              id: familyId.toString(),
            })
          );
        })
        .catch(() => {
          const errorMessage = t("errorSavingData");
          toast.error(errorMessage);
          setIsSubmitting(false);
        });
    }
  };

  return (
    <Stack sx={{ flex: 1, justifyContent: "space-between" }}>
      <div>
        <Stack sx={{ mb: "30px" }} gap="15px">
          <Text variant="titleMedium">{t("assign")}</Text>
          <Divider />
        </Stack>
        <form>
          <Stack spacing={2}>
            <Controller
              name="familyAdministrator"
              control={control}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  label={t("familyAdministrator")}
                  options={specialistDataOptions}
                />
              )}
            />
          </Stack>
        </form>
      </div>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("save")}
            onClick={handleSave}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Assign;
