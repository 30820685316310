import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import AddButton from "components/AddButton";
import AuthorizationItem from "components/AuthorizationItem";
import Stack from "components/Stack";

import AddAuthorizationForm, {
  AddAuthorizationFormRef,
} from "../AddAuthorizationForm";
import useReadableStatus, {
  CompletedStatus,
  IVoucher,
} from "entities/FamilySpecialist/sdk";
import { formatToCamelCase } from "utils/format";
import EditPanel from "../index";
import { useData } from "../DataContext";

interface IProps {
  refetchFamilyDetail: () => void;
}

const AuthorizationList = ({ refetchFamilyDetail }: IProps) => {
  const { t } = useTranslation();
  const { getReadableCompletedStatus } = useReadableStatus();
  const familyData = useData();
  const [isAdding, setIsAdding] = useState(false);
  const [selectedAuthorization, setSelectedAuthorization] =
    useState<IVoucher | null>(null);
  const addAuthorizationFormRef = useRef<AddAuthorizationFormRef>(null);

  const handleAddAuthorizationClick = () => {
    setSelectedAuthorization(null);
    setIsAdding(true);
  };

  const handleSaveAuthorization = () => {
    setIsAdding(false);
  };

  const handleEditAuthorization = (authorization: IVoucher) => {
    setSelectedAuthorization(authorization);
    setIsAdding(true);
  };

  const handleSaveClick = () => {
    if (addAuthorizationFormRef.current) {
      addAuthorizationFormRef.current.submitForm();
    }
  };

  if (familyData === null) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <EditPanel
        open={isAdding}
        onClose={() => setIsAdding(false)}
        title={t(
          selectedAuthorization ? "editAuthorization" : "addAuthorization"
        )}
        onSaveClick={handleSaveClick}
      >
        <AddAuthorizationForm
          onSave={handleSaveAuthorization}
          initialData={selectedAuthorization}
          ref={addAuthorizationFormRef}
          familyId={familyData.id}
          refetchFamilyDetail={refetchFamilyDetail}
          children={familyData.children}
        />
      </EditPanel>
      <>
        {familyData.vouchers.map((voucher, index) => (
          <AuthorizationItem
            key={index}
            title={`Authorization ${index + 1}`}
            lastActivity={voucher.updated_at}
            type={t(formatToCamelCase(voucher.type))}
            actionRequired={getReadableCompletedStatus(
              voucher.status as CompletedStatus
            )}
            onEdit={() => handleEditAuthorization(voucher)}
          />
        ))}
        <Stack alignItems="center" style={{ marginBottom: "20px" }}>
          <AddButton
            label={t("addAuthorization")}
            onClick={handleAddAuthorizationClick}
          />
        </Stack>
      </>
    </Stack>
  );
};

export default AuthorizationList;
