import { useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";
import TextField from "components/TextField";
import StyledSelect from "components/StyledSelect";
import ParsedPrimaryContactContactMethodRow from "entities/ParsedPrimaryContact/components/ContactMethodRow";
import BasicButton from "components/BasicButton";
import FormControl from "components/FormControl";
import Box from "components/Box";
import ToggleButton, { ToggleButtonGroup } from "components/ToggleButton";
import Menu from "components/Menu";
import MenuItem from "components/MenuItem";

import { useLanguageOptions } from "entities/FamilyMember/sdk";
import { PreferredContactMethod } from "entities/FamilyEdit/sdk";
import { PhoneStatus, EmailStatus } from "entities/FamilyEdit/sdk";

import { colors } from "theme";
import { ChatIcon, CheckIcon, MailOutlinedIcon } from "icons";
import { DeepPartial } from "utils/common";
import { useFormWarnings } from "utils/forms";

interface IProps {
  initialValues: DeepPartial<FormData>;
  fieldsWithWarnings?: string[];
  onSave: (values: DeepPartial<FormData>) => Promise<any>;
}

interface FormData {
  creator: {
    first_name: string;
    last_name: string;
    preferred_language: "english" | "spanish" | "capeverdean" | "haitian";
    preferred_contact_method: PreferredContactMethod;
    email_contact_methods: Array<{
      id?: number;
      email: string;
      status: "ACTIVE" | "INACTIVE" | "NO_CONCEPT";
    }>;
    phone_contact_methods: Array<{
      id?: number;
      phone_number: string;
      status: "ACTIVE" | "INACTIVE" | "NO_CONCEPT";
    }>;
  };
  street_address: string;
  unit_address: string;
  city: string;
  zip_code: string;
}

const emailContactMethodOptions = [
  { label: "Active", value: EmailStatus.ACTIVE },
  { label: "Inactive", value: EmailStatus.INACTIVE },
  { label: "No Concept", value: EmailStatus.NO_CONCEPT },
] as const;

const phoneContactMethodOptions = [
  { label: "Active", value: PhoneStatus.ACTIVE },
  { label: "Inactive", value: PhoneStatus.INACTIVE },
  { label: "No Concept", value: PhoneStatus.NO_CONCEPT },
] as const;

const PrimaryContactForm = ({
  initialValues,
  fieldsWithWarnings,
  onSave,
}: IProps) => {
  const { t } = useTranslation();
  const languageOptions = useLanguageOptions();

  const { control, handleSubmit, setError, formState } = useForm<FormData>({
    defaultValues: initialValues,
  });

  const { fieldWarningsAndErrors } = useFormWarnings({
    formState,
    fieldsWithWarnings,
  });

  const emailContactControl = useFieldArray({
    control,
    name: "creator.email_contact_methods",
  });
  const phoneContactControl = useFieldArray({
    control,
    name: "creator.phone_contact_methods",
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmit = (values: any) => {
    onSave(values).catch((errors) => errors.setFormErrors(setError));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ flex: 1, justifyContent: "space-between" }}>
        <div>
          <Controller
            name="creator.first_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("firstName")}
                fullWidth
                margin="normal"
                placeholder={t("firstName")}
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <Controller
            name="creator.last_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("lastName")}
                fullWidth
                margin="normal"
                placeholder={t("lastName")}
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <Controller
            name="creator.preferred_language"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("preferredLanguage")}
                options={languageOptions}
                fullWidth
                margin="normal"
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <FormControl
            component="fieldset"
            margin="normal"
            sx={{ width: "100%" }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Text variant="labelLarge">{t("preferredContactMethod")}</Text>
            </Box>
            <Controller
              name="creator.preferred_contact_method"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ToggleButtonGroup
                  value={value}
                  exclusive
                  onChange={(newValue) => onChange(newValue)}
                  fullWidth
                  sx={{
                    "& .MuiToggleButtonGroup-grouped": {
                      margin: "8px 0",
                      border: `2px solid ${colors.outline}`,
                      textTransform: "capitalize",
                      justifyContent: "flex-start",
                      "&.Mui-selected": {
                        bgcolor: colors.secondaryContainer,
                        color: colors.onSecondaryContainer,
                        "&:hover": {
                          bgcolor: colors.onHoverSecondaryContainer,
                          color: colors.onSecondaryContainer,
                        },
                      },
                      "&:not(:first-of-type)": {
                        borderRadius: "0 50px 50px 0",
                      },
                      "&:first-of-type": {
                        borderRadius: "50px 0 0 50px",
                        borderRight: "1px solid",
                        borderColor: colors.outline,
                      },
                    },
                  }}
                >
                  <ToggleButton
                    value="SMS"
                    style={{
                      justifyContent: "flex-start",
                      paddingLeft: "30px",
                      height: "40px",
                    }}
                  >
                    {value === "SMS" && (
                      <CheckIcon style={{ marginRight: "15px" }} />
                    )}
                    {t("textMessage")}
                  </ToggleButton>
                  <ToggleButton
                    value="MAIL"
                    style={{
                      justifyContent: "flex-start",
                      paddingLeft: "30px",
                      height: "40px",
                    }}
                  >
                    {value === "MAIL" && (
                      <CheckIcon style={{ marginRight: "15px" }} />
                    )}
                    {t("email")}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </FormControl>
          <Divider />
          <Stack sx={{ mt: "10px", mb: "20px" }}>
            <Stack spacing={2}>
              {emailContactControl.fields.map((contactMethod, index) => (
                <ParsedPrimaryContactContactMethodRow
                  key={index}
                  options={emailContactMethodOptions}
                  status={contactMethod.status}
                  onChange={({ value, status }) => {
                    emailContactControl.update(index, {
                      email: value,
                      status,
                    });
                  }}
                  placeholder={t("email")}
                  type={"email"}
                  contact={contactMethod.email}
                  error={_.get(
                    formState.errors,
                    `creator.email_contact_methods.${index}.email.message`
                  )}
                  onRemove={() => {
                    emailContactControl.remove(index);
                  }}
                />
              ))}
              {phoneContactControl.fields.map((contactMethod, index) => (
                <ParsedPrimaryContactContactMethodRow
                  key={index}
                  options={phoneContactMethodOptions}
                  status={contactMethod.status}
                  onChange={({ value, status }) => {
                    phoneContactControl.update(index, {
                      phone_number: value,
                      status,
                    });
                  }}
                  placeholder={t("phone")}
                  type="phone"
                  contact={contactMethod.phone_number}
                  error={_.get(
                    formState.errors,
                    `creator.phone_contact_methods.${index}.phone_number.message`
                  )}
                  onRemove={() => {
                    phoneContactControl.remove(index);
                  }}
                />
              ))}
              <Box sx={{ position: "relative" }}>
                <BasicButton
                  label={t("addAnotherContactMethod")}
                  onClick={handleClick}
                  backgroundColor={colors.secondaryContainer}
                  color={colors.onSecondaryContainer}
                  onHoverBackground={colors.onHoverSecondaryContainer}
                  sx={{ width: "100%" }}
                />
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      width: anchorEl ? anchorEl.clientWidth : "auto",
                    },
                  }}
                  MenuListProps={{
                    sx: {
                      backgroundColor: colors.surfaceContainerHigh,
                    },
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      emailContactControl.append({
                        email: "",
                        status: EmailStatus.ACTIVE,
                      })
                    }
                  >
                    <MailOutlinedIcon />{" "}
                    <Text variant="titleSmall" sx={{ ml: "10px" }}>
                      {t("email")}
                    </Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      phoneContactControl.append({
                        phone_number: "",
                        status: PhoneStatus.ACTIVE,
                      })
                    }
                  >
                    <ChatIcon />{" "}
                    <Text variant="titleSmall" sx={{ ml: "10px" }}>
                      {t("phoneNumber")}
                    </Text>
                  </MenuItem>
                </Menu>
              </Box>
            </Stack>
          </Stack>
          <Controller
            name="street_address"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("addressLine1")}
                fullWidth
                margin="normal"
                placeholder={t("streetName")}
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <Controller
            name="unit_address"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("addressLine2")}
                fullWidth
                margin="normal"
                placeholder={t("addressInfo")}
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("city")}
                fullWidth
                margin="normal"
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
          <Controller
            name="zip_code"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("zipCode")}
                fullWidth
                margin="normal"
                {...fieldWarningsAndErrors(field)}
              />
            )}
          />
        </div>
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton label={t("save")} buttonType="submit" />
        </Stack>
      </Stack>
    </form>
  );
};

export default PrimaryContactForm;
