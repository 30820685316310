import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Container from "components/Container";
import FullPageDialog from "components/FullPageDialog";
import PlacementForm from "entities/Placement/components/Form";

import {
  placementCreateInUserFamily,
  IPlacement,
} from "entities/Placement/sdk";

import { URLS } from "config/urls";

const PlacementAddPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSave = (values: Partial<IPlacement>) =>
    placementCreateInUserFamily({ data: values }).then(() => {
      navigate(URLS.MISSING_INFORMATION_PLACEMENT_LIST);
    });

  return (
    <FullPageDialog
      title={t("placements")}
      progressBarPercentage={40}
      backUrl={URLS.MISSING_INFORMATION_PLACEMENT_LIST}
      closeUrl={URLS.HOME}
    >
      <Container maxWidth="sm" sx={{ paddingTop: 2 }}>
        <PlacementForm
          onCancel={() => {
            navigate(URLS.MISSING_INFORMATION_PLACEMENT_LIST);
          }}
          onSave={handleSave}
        />
      </Container>
    </FullPageDialog>
  );
};

export default PlacementAddPage;
