import { URLS } from "../config/urls";

export const reverse = (
  url: URLS,
  params: { [key: string]: string | number } = {}
) => {
  let reversedUrl = url.toString();
  Object.keys(params).forEach((key) => {
    reversedUrl = reversedUrl.replaceAll(`:${key}`, params[key].toString());
  });

  return reversedUrl;
};
