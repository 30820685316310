import dayjs from "dayjs";

import { useTranslation } from "react-i18next";

import Chip from "components/Chip";
import Stack from "components/Stack";
import Text from "components/Text";

import { colors } from "theme";

import { ChatIcon, MailOutlinedIcon } from "icons";

import { IMessages } from "entities/FamilySpecialist/sdk";

interface IProps {
  item: IMessages;
  handleChipClick: (file: string) => void;
}

const MessageActivityBox = ({ item, handleChipClick }: IProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Stack direction="row" gap="15px">
          {item.communication_type === "EMAIL" ? (
            <MailOutlinedIcon />
          ) : (
            <ChatIcon />
          )}
          <Text variant="titleMedium">
            {`${item.sent_from.first_name} ${item.sent_from.last_name} `}
            {t("sentAMessage")}
          </Text>
        </Stack>
        <Stack>
          <Text variant="bodyMedium" color="text.secondary">
            {dayjs(item.created_at).format("MM/DD/YYYY - hh:mm A")}
          </Text>
        </Stack>
      </Stack>
      {item.communication_type === "EMAIL" && (
        <Text variant="bodyLarge" ml="40px" mt="10px" fontWeight={"500"}>
          {item.subject}
        </Text>
      )}
      {item.communication_type === "EMAIL" ? (
        <Text
          variant="bodyMedium"
          sx={{
            flex: 1,
            mt: item.is_intake ? "16px" : 0,
            ml: "40px",
          }}
          color="text.secondary"
          dangerouslySetInnerHTML={{ __html: item.body }}
        />
      ) : (
        <Text
          variant="bodyMedium"
          color="text.secondary"
          sx={{
            flex: 1,
            my: "16px",
            whiteSpace: "pre-line",
            ml: "40px",
          }}
        >
          {item.body}
        </Text>
      )}

      <Stack
        direction="row"
        flexWrap="wrap"
        gap="10px"
        sx={{ mt: item.is_intake ? "16px" : 0 }}
      >
        {item.files.map((file) => (
          <Chip
            key={file.id}
            label={file.original_file_name}
            onClick={() => handleChipClick(file.file)}
            sx={{
              border: `1px solid ${colors.outline}`,
              color: "text.secondary",
              backgroundColor: "background.paper",
              borderRadius: "8px",
              maxWidth: "450px",
            }}
          />
        ))}
      </Stack>
    </>
  );
};

export default MessageActivityBox;
