import { styled } from "@mui/system";

import Box from "components/Box";
import Text from "components/Text";

import { colors } from "theme";

const StyledLabelContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  margin: theme.spacing(2, 0),
}));

const StyledLine = styled(Box)(({}) => ({
  flexGrow: 1,
  height: "1px",
  backgroundColor: colors.outlineVariant,
}));

const LabelText = styled(Text)(({ theme }) => ({
  margin: theme.spacing(0, 2),
  color: colors.onSurface,
  fontsize: "16px",
  fontWeight: "500",
}));

function LabelWithLines({ label }: { label: string }) {
  return (
    <StyledLabelContainer>
      <StyledLine />
      <LabelText>{label}</LabelText>
      <StyledLine />
    </StyledLabelContainer>
  );
}

export default LabelWithLines;
