import { useState } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import Chip from "components/Chip";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import Text from "components/Text";

import AuthorizationList from "entities/FamilyEdit/components/AuthorizationList";
import EditPanel from "entities/FamilyEdit/components";
import {
  useFamilyDetail,
  useReadableStatus,
} from "entities/FamilySpecialist/sdk";

import { EditIcon, CheckIcon } from "icons";

import { colors } from "theme";

import { formatToCamelCase } from "utils/format";

interface IProps {
  familyId: number;
}

const VOUCHER_SPECIAL_CIRCUMSTANCES = [
  "family_has_experienced_domestic_violence",
  "family_is_experiencing_homelessness",
  "parent_has_a_disability",
  "parent_is_a_member_of_the_us_military",
  "parent_is_an_early_educator",
  "parent_is_receiving_treatment_for_substance_abuse",
  "teen_parent",
  "child_only_needs_coverage_during_school_closures",
] as const;

const AuthorizationSection = ({ familyId }: IProps) => {
  const { t } = useTranslation();
  const { getReadableCompletedStatus } = useReadableStatus();
  const { data: familyData, mutate: refetchFamilyDetail } =
    useFamilyDetail(familyId);

  const specialCircumstancesTranslations = {
    family_has_experienced_domestic_violence: t("domesticViolence"),
    family_is_experiencing_homelessness: t("homelessness"),
    parent_has_a_disability: t("parentDisability"),
    parent_is_a_member_of_the_us_military: t("militaryMember"),
    parent_is_an_early_educator: t("earlyEducator"),
    parent_is_receiving_treatment_for_substance_abuse: t(
      "substanceAbuseTreatment"
    ),
    teen_parent: t("teenParent"),
    child_only_needs_coverage_during_school_closures: t(
      "schoolClosureCoverage"
    ),
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Stack spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text variant="titleMedium" color={colors.onSurfaceVariant}>
            {t("authorizations")}
          </Text>
          <IconButton onClick={() => setIsOpen(true)}>
            <EditIcon width="24px" height="24px" />
          </IconButton>
        </Stack>
        {familyData?.vouchers.map((voucher, index) => (
          <div key={index}>
            <Stack
              direction="row"
              justifyContent="space-between"
              key={voucher.id}
            >
              <Text variant="bodyLarge" sx={{ flex: 2 }} fontWeight="bold">
                {t(formatToCamelCase(voucher.type))}
              </Text>
              <Text variant="bodyLarge" sx={{ flex: 2 }} fontWeight="bold">
                {getReadableCompletedStatus(voucher.status)}
              </Text>
              <Text variant="bodyLarge" sx={{ flex: 1, textAlign: "right" }}>
                Last Activity: {dayjs(voucher.updated_at).format("MM/DD/YYYY")}
              </Text>
            </Stack>
            {voucher.type === "WAITLIST" && (
              <Stack spacing={1}>
                {VOUCHER_SPECIAL_CIRCUMSTANCES.filter(
                  (key) => voucher[key]
                ).map((key, index) => (
                  <div>
                    <CheckIcon />
                    <Text
                      variant="bodyLarge"
                      sx={{ marginLeft: (theme) => theme.spacing(1) }}
                      key={index}
                    >
                      {specialCircumstancesTranslations[key]}
                    </Text>
                  </div>
                ))}
              </Stack>
            )}
            <div>
              <Stack
                spacing={2}
                direction="row"
                sx={{ justifyContent: "flex-start" }}
              >
                {voucher.children.map((child) => (
                  <Chip
                    key={child.id}
                    label={
                      <Text
                        variant="labelLarge"
                        sx={{
                          fontWeight: "bold",
                          color: colors.onSurfaceVariant,
                        }}
                      >
                        {!child.first_name &&
                          !child.last_name &&
                          `Unknown Name (ID: ${child.id})`}
                        {child.first_name} {child.last_name}
                      </Text>
                    }
                  />
                ))}
              </Stack>
            </div>
          </div>
        ))}
      </Stack>
      <EditPanel
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Edit Panel"
        data={familyData}
        onSaveClick={() => setIsOpen(false)}
      >
        <AuthorizationList refetchFamilyDetail={refetchFamilyDetail} />
      </EditPanel>
    </>
  );
};

export default AuthorizationSection;
