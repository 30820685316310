import { useState } from "react";
import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import _ from "lodash";

import Box from "components/Box";

import { useDetailDocumentData } from "entities/Documents/sdk";

const LeftPanel = () => {
  const { documentId: documentIdParam } = useParams<{
    documentId: string;
  }>();
  const documentId = parseInt(String(documentIdParam));

  const { data } = useDetailDocumentData(documentId);
  const fileUrl = data?.file.file;
  const fileType = data?.file.file_type;

  const [numPages, setNumPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <Box
      sx={{
        flex: 2,
        display: "flex",
        justifyContent: "center",
        overflow: "auto",
        maxHeight: "85vh",
      }}
    >
      {fileType === "application/pdf" ? (
        <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
          {_.range(1, numPages + 1).map((pageNumber) => (
            <Page
              key={`page_${pageNumber}`}
              pageNumber={pageNumber}
              renderTextLayer={false}
              scale={1.5}
              width={600}
            />
          ))}
        </Document>
      ) : (
        <img
          src={fileUrl}
          alt="Uploaded File"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
          }}
        />
      )}
    </Box>
  );
};

export default LeftPanel;
