import { post, BASE_URL } from "utils/sdk";

export interface IPlacement {}

export const placementCreateInUserFamily = ({
  data,
}: {
  data: Partial<IPlacement>;
}) => post(`${BASE_URL}/api/family-members/family/placement/create/`, data);

export const placementUpdateInUserFamily = ({
  placementId,
  data,
}: {
  placementId: number;
  data: any;
}) =>
  post(
    `${BASE_URL}/api/family-members/family/placement/${placementId}/update/`,
    data
  );
