import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Container from "components/Container";
import FullPageDialog from "components/FullPageDialog";

import AuthorizationForm from "entities/Authorization/components/Form";
import { useMyFamily, IFamily } from "entities/Family/sdk";
import { authorizationUpdate } from "entities/Authorization/sdk";

import { URLS } from "config/urls";

interface ISubmitValues extends Omit<IFamily["vouchers"][number], "children"> {
  children: string[];
}

const AuthorizationEditPage = () => {
  const { t } = useTranslation();
  const { authorizationId: authorizationIdParam } = useParams<{
    authorizationId: string;
  }>();
  const authorizationId = parseInt(String(authorizationIdParam));
  const navigate = useNavigate();

  const { data: family } = useMyFamily();
  const authorization = family?.vouchers.find(
    (authorization) => authorization.id === authorizationId
  );

  const fieldsWithWarnings = family?.missing_fields.find(
    (voucher) => voucher.voucher_id === authorizationId
  )?.missing_fields.voucher_missing_fields;

  const handleSave = (data: Partial<ISubmitValues>) => {
    return authorizationUpdate({ authorizationId, data }).then(() => {
      navigate(URLS.MISSING_INFORMATION_AUTHORIZATION_LIST);
    });
  };

  if (!authorization) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("authorizations")}
      progressBarPercentage={40}
      backUrl={URLS.MISSING_INFORMATION_AUTHORIZATION_LIST}
      closeUrl={URLS.HOME}
    >
      <Container maxWidth="sm">
        <AuthorizationForm
          initialValues={authorization}
          fieldsWithWarnings={fieldsWithWarnings}
          onSave={handleSave}
        />
      </Container>
    </FullPageDialog>
  );
};

export default AuthorizationEditPage;
