export const getDefaultLanguageValue = (language: string) => {
  switch (language) {
    case "es":
      return "spanish";
    case "en":
      return "english";
    case "kea":
      return "capeverdean";
    case "ht":
      return "haitian";
    default:
      return "english";
  }
};
