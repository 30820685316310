import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import AddButton from "components/AddButton";
import useMediaQuery from "components/useMediaQuery";
import FormHelperText from "components/FormHelperText";

import { URLS } from "config/urls";
import { colors } from "theme";

import { useFamilySpecialistParents } from "entities/FamilyEdit/sdk";
import {
  getEmploymentStatusDisplayValue,
  useParents,
} from "entities/FamilyMember/sdk";
import { reverse } from "utils/urls";

const Parents = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { familyId: familyIdParam } = useParams<{ familyId: string }>();
  const familyId = familyIdParam ? parseInt(familyIdParam) : undefined;
  const matches = useMediaQuery("(min-width:600px)");
  const { data: parentsData, error } = familyId
    ? useFamilySpecialistParents(familyId)
    : useParents();
  const [showError, setShowError] = useState(false);

  const handleNextClick = () => {
    if (!parentsData || parentsData.family_members.length === 0) {
      setShowError(true);
    } else {
      setShowError(false);
      if (familyId) {
        navigate(
          reverse(URLS.FAMILY_SPECIALIST_MAILING_ADDRESS, {
            familyId,
          })
        );
      } else {
        navigate(URLS.FAMILY_INFORMATION_MAILING_ADDRESS);
      }
    }
  };

  const handleAddButtonClick = () => {
    if (familyId) {
      navigate(reverse(URLS.FAMILY_SPECIALIST_ADD_PARENT, { familyId }));
    } else {
      navigate(URLS.FAMILY_INFORMATION_ADD_PARENT);
    }
  };

  if (!parentsData) {
    return null;
  }

  return (
    <>
      <Stack
        sx={{ mt: "30px", width: matches ? "50%" : "100%" }}
        alignSelf="center"
        alignItems="center"
      >
        {error && <Text color="error">{t("errorLoadingData")}</Text>}
        <Stack
          sx={{
            mb: "20px",
            borderBottom: "1px solid",
            borderColor: colors.outline,
            width: "100%",
          }}
        >
          {parentsData.family_members.map((parent, index) => (
            <Stack
              key={index}
              sx={{
                py: 2,
                borderTop: "1px solid",
                borderColor: colors.outline,
              }}
              direction="row"
              justifyContent="space-between"
            >
              <Text variant="titleMedium">
                {!parent.first_name && !parent.last_name && "Unknown name"}
                {parent.first_name} {parent.last_name}
              </Text>
              <Text variant="titleMedium">
                <Text variant="titleMedium" fontWeight="bold">
                  {parent.employment_status &&
                    getEmploymentStatusDisplayValue(parent.employment_status)}
                </Text>{" "}
                {parent.employer_name} {parent.other_status_description}
              </Text>
            </Stack>
          ))}
        </Stack>
        <AddButton
          label={t("addParent")}
          backgroundColor={colors.secondaryContainer}
          onClick={handleAddButtonClick}
        />
        {showError && (
          <FormHelperText error sx={{ mt: "20px" }}>
            {t("youMustAddAtLeastOneChild")}
          </FormHelperText>
        )}
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton label={t("next")} onClick={handleNextClick} />
        </Stack>
      </Stack>
    </>
  );
};

export default Parents;
