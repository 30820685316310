import axios from "axios";
import { post, useFetch } from "utils/sdk";

import { BASE_URL, URLS } from "config/urls";

import { CompletedStatus } from "entities/FamilySpecialist/sdk";

type FamilyMemberFlowStep =
  | "CHILDCARE_NEEDS"
  | "CHILDCARE_NEEDS_CHECK_NEEDS"
  | "OVERVIEW"
  | "YOUR_STATUS"
  | "PARENTS"
  | "ADD_PARENT"
  | "MAILING_ADDRESS"
  | "CHILDREN"
  | "ADD_CHILD"
  | "INCOME_VERIFICATION"
  | "ADDITIONAL_INFORMATION"
  | "COMPLETE";

export const stepToUrlMapping = {
  CHILDCARE_NEEDS: URLS.CHILD_CARE_NEEDS,
  CHILDCARE_NEEDS_CHECK_NEEDS: URLS.CHILD_CARE_NEEDS_CHECK_NEEDS,
  OVERVIEW: URLS.FAMILY_INFORMATION_OVERVIEW,
  YOUR_STATUS: URLS.FAMILY_INFORMATION_YOUR_STATUS,
  PARENTS: URLS.FAMILY_INFORMATION_PARENTS,
  ADD_PARENT: URLS.FAMILY_INFORMATION_ADD_PARENT,
  MAILING_ADDRESS: URLS.FAMILY_INFORMATION_MAILING_ADDRESS,
  CHILDREN: URLS.FAMILY_INFORMATION_CHILDREN,
  ADD_CHILD: URLS.FAMILY_INFORMATION_ADD_CHILD,
  INCOME_VERIFICATION: URLS.FAMILY_INFORMATION_INCOME_VERIFICATION,
  ADDITIONAL_INFORMATION: URLS.FAMILY_INFORMATION_ADDITIONAL_INFORMATION,
  COMPLETE: URLS.FAMILY_INFORMATION_COMPLETE,
  HOME: URLS.HOME,
};

export interface ISignUpData {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  preferred_contact_method: string;
  consent_text: boolean;
  consent_email: boolean;
}

interface SIgnUpResponse {
  magic_token: string;
  user_type: string;
}

interface ILoginData {
  email?: string;
  phone_number?: string;
}

interface IResendMagicLinkData {
  email?: string;
  phone_number?: string;
  redirect_id: number;
}

export interface ILoginResponse {
  magic_token: string;
  user_type: string;
}

export enum UserType {
  FAMILY_MEMBER = "FAMILY_MEMBER",
  FAMILY_SPECIALIST = "FAMILY_SPECIALIST",
}

interface IUserData {
  id: number;
  email: string;
  user_type: UserType;
  vouchers: {
    status: CompletedStatus;
    type: string;
    id: number;
  }[];
  family_member_flow_step: FamilyMemberFlowStep;
  first_name: string;
  last_name: string;
  household_id: string;
}

export const useSignedUser = () => useFetch<IUserData>("/api/auth/me/");

export const getSignedUser = () =>
  axios.get<IUserData>(`${BASE_URL}/api/auth/me/`, { withCredentials: true });

export const signUp = (signUpData: ISignUpData) =>
  post<SIgnUpResponse>(`${BASE_URL}/api/family-members/register/`, signUpData);

export const signInMagicLink = (magicToken: string) =>
  post(`${BASE_URL}/api/users/login/`, {
    magic_token: magicToken,
  });

export const login = (loginData: ILoginData) =>
  post<ILoginResponse>(`${BASE_URL}/api/users/magic-link-login/`, loginData);

export const logout = () => post(`${BASE_URL}/api/users/logout/`);

export const resendMagicLink = (resendMagicLinkData: IResendMagicLinkData) =>
  post<ILoginResponse>(
    `${BASE_URL}/api/users/resend-magic-link/`,
    resendMagicLinkData
  );
