import Button from "components/Button";
import Text from "components/Text";
import useMediaQuery from "components/useMediaQuery";

import theme, { colors } from "theme";

import { PlusIcon } from "icons";

interface ButtonProps {
  backgroundColor?: string;
  color?: string;
  label: string;
  height?: string;
  width?: string;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
}

const AddButton = ({
  backgroundColor,
  label,
  color,
  height,
  width,
  onClick,
}: ButtonProps) => {
  const matches = useMediaQuery("(min-width:600px)");
  const labelDefaultWidth = matches ? "305px" : "100%";
  const btnWidth = width || labelDefaultWidth;

  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        backgroundColor: backgroundColor
          ? backgroundColor
          : colors.secondaryContainer,
        height: height ? height : "52px",
        width: btnWidth,
        borderRadius: "50px",
        boxShadow: "none",
        "&:hover": {
          backgroundColor: colors.onHoverSecondaryContainer,
          "& .text": {
            color: color ? backgroundColor : colors.onSecondaryContainer,
          },
        },
      }}
    >
      <PlusIcon style={{ marginLeft: "-20px" }} />
      <Text
        variant="labelLarge"
        className="text"
        sx={{
          color: color ? color : colors.onSecondaryContainer,
          ml: theme.spacing(2),
          textTransform: "none",
        }}
      >
        {label}
      </Text>
    </Button>
  );
};

export default AddButton;
