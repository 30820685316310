import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import TextField from "components/TextField";
import Container from "components/Container";
import StyledSelect from "components/StyledSelect";
import FormControlLabel from "components/FormControlLabel";
import FormHelperText from "components/FormHelperText";
import FormControl from "components/FormControl";
import Radio, { RadioGroup } from "components/Radio";

import {
  useGenderOptions,
  useLanguageOptions,
} from "entities/FamilyMember/sdk";

import { MissingInformationLabel, useFormWarnings } from "utils/forms";

import { colors } from "theme";

interface FormData {
  first_name: string;
  last_name: string;
  gender: "MEN" | "WOMEN";
  preferred_name: string;
  preferred_language: string;
  employment_status: "EMPLOYED" | "TRAININGPROGRAM" | "IN_SCHOOL" | "OTHER";
  employer_name: string;
  other_status_description: string;
}

interface IProps {
  initialValues?: Partial<FormData>;
  fieldsWithWarnings?: string[];
  onSave: (values: Partial<FormData>) => Promise<any>;
}

const ParentForm = ({ initialValues, fieldsWithWarnings, onSave }: IProps) => {
  const { t } = useTranslation();

  const genderOptions = useGenderOptions();

  const { control, handleSubmit, watch, setError, formState } =
    useForm<FormData>({
      defaultValues: initialValues,
    });

  const { fieldWarningsAndErrors } = useFormWarnings({
    formState,
    fieldsWithWarnings,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const employmentStatus = watch("employment_status");
  const languageOptions = useLanguageOptions();

  const onSubmit = (data: FormData) => {
    setIsSubmitting(true);
    onSave(data)
      .catch((errors) => errors.setFormErrors(setError))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Stack alignItems={"center"} sx={{ flex: 1 }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ flex: 1, display: "flex", width: "100%" }}
      >
        <Stack
          sx={{ flex: 1 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Container maxWidth="xs">
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("firstName")}
                  placeholder={t("firstName")}
                  fullWidth
                  margin="normal"
                  {...fieldWarningsAndErrors(field)}
                />
              )}
            />
            <Controller
              name="last_name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("lastName")}
                  placeholder={t("lastName")}
                  fullWidth
                  margin="normal"
                  {...fieldWarningsAndErrors(field)}
                />
              )}
            />
            <Controller
              name="preferred_name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("preferredName")}
                  fullWidth
                  margin="normal"
                  {...fieldWarningsAndErrors(field)}
                />
              )}
            />
            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  label={t("gender")}
                  options={genderOptions}
                  fullWidth
                  margin="normal"
                  {...fieldWarningsAndErrors(field)}
                />
              )}
            />
            <Controller
              name="preferred_language"
              control={control}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  label={t("preferredLanguage")}
                  options={languageOptions}
                  fullWidth
                  margin="normal"
                  {...fieldWarningsAndErrors(field)}
                />
              )}
            />
            <FormControl
              component="fieldset"
              margin="normal"
              sx={{ width: "100%" }}
              error={!!formState.errors.employment_status}
            >
              <Controller
                name="employment_status"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    aria-label="employment status"
                    sx={{ flexDirection: "column" }}
                  >
                    <FormControlLabel
                      value="EMPLOYED"
                      control={<Radio />}
                      label={t("iAmEmployed")}
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: colors.outlineVariant,
                        marginRight: 0,
                        padding: "10px 0",
                      }}
                    />
                    <FormControlLabel
                      value="IN_SCHOOL"
                      control={<Radio />}
                      label={t("iAmInSchool")}
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: colors.outlineVariant,
                        marginRight: 0,
                        padding: "10px 0",
                      }}
                    />
                    <FormControlLabel
                      value="TRAININGPROGRAM"
                      control={<Radio />}
                      label={t("iAmInTraining")}
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: colors.outlineVariant,
                        marginRight: 0,
                        padding: "10px 0",
                      }}
                    />
                    <FormControlLabel
                      value="OTHER"
                      control={<Radio />}
                      label={t("other")}
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: colors.outlineVariant,
                        marginRight: 0,
                        padding: "10px 0",
                      }}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
            {!!formState.errors.employment_status && (
              <FormHelperText error>
                {formState.errors.employment_status?.message}
              </FormHelperText>
            )}
            {!!fieldsWithWarnings?.find((x) => x === "employment_status") && (
              <MissingInformationLabel />
            )}
            {employmentStatus === "EMPLOYED" && (
              <Controller
                name="employer_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("employerName")}
                    fullWidth
                    margin="normal"
                    {...fieldWarningsAndErrors(field)}
                  />
                )}
              />
            )}

            {employmentStatus === "OTHER" && (
              <Controller
                name="other_status_description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("other")}
                    fullWidth
                    margin="normal"
                    {...fieldWarningsAndErrors(field)}
                  />
                )}
              />
            )}
          </Container>
          <Stack
            sx={{
              alignItems: "center",
              width: "100%",
              borderTop: "1px solid",
              borderColor: colors.outline,
            }}
          >
            <Stack sx={{ mt: "30px", width: "100%" }} alignItems="center">
              <BasicButton
                buttonType="submit"
                label={t("next")}
                isDisabled={isSubmitting}
              />
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default ParentForm;
