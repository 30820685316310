import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Stack from "components/Stack";
import Text from "components/Text";
import Container from "components/Container";
import BasicButton from "components/BasicButton";
import AddButton from "components/AddButton";
import Link from "components/Link";
import FormHelperText from "components/FormHelperText";
import IconButton from "components/IconButton";

import { useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { colors } from "theme";
import { EditIcon } from "icons";
import { reverse } from "utils/urls";

const Children = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: family } = useMyFamily();

  const children = family?.children;

  const [showError, setShowError] = useState(false);

  const handleNextClick = () => {
    if (children && children.length === 0) {
      setShowError(true);
    } else {
      navigate(URLS.FAMILY_INFORMATION_INCOME_VERIFICATION);
    }
  };

  return (
    <Stack
      sx={{
        justifyContent: "space-between",
        flex: 1,
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          mt: "30px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Text variant="titleMedium" sx={{ m: 2 }}>
          {t("childrenHeader")}
        </Text>
        <Stack
          sx={{
            mb: "20px",
            borderBottom: "1px solid",
            borderColor: colors.outline,
            width: "100%",
          }}
        >
          {children?.map((child, index) => (
            <Stack
              key={index}
              sx={{
                py: 1,
                borderTop: "1px solid",
                borderColor: colors.outline,
                justifyContent: "space-between",
                alignItems: "center",
              }}
              direction="row"
            >
              <Text variant="titleMedium">
                {child.first_name} {child.last_name}
              </Text>
              <div>
                <Text variant="titleMedium">{child.age} yrs</Text>
                <Link
                  to={reverse(URLS.FAMILY_INFORMATION_EDIT_CHILD, {
                    childId: child.id,
                  })}
                >
                  <IconButton size="small">
                    <EditIcon style={{ width: "18px", height: "18px" }} />
                  </IconButton>
                </Link>
              </div>
            </Stack>
          ))}
        </Stack>
        <Link to={URLS.FAMILY_INFORMATION_ADD_CHILD}>
          <AddButton
            label={t("addChild")}
            backgroundColor={colors.secondaryContainer}
          />
        </Link>
        {showError && (
          <FormHelperText error sx={{ mt: "20px" }}>
            {t("youMustAddAtLeastOneChild")}
          </FormHelperText>
        )}
      </Container>
      <Stack
        sx={{
          borderTop: "1px solid " + colors.outline,
          pt: "30px",
          alignItems: "center",
        }}
      >
        <BasicButton label={t("next")} onClick={handleNextClick} />
      </Stack>
    </Stack>
  );
};

export default Children;
