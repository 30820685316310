import { DeepPartial } from "utils/common";
import { BASE_URL, post, useFetch } from "utils/sdk";
import { PreferredContactMethod } from "entities/FamilyEdit/sdk";
import { AuthorizationStatus } from "entities/Authorization/sdk";

export interface IFamily {
  creator: {
    id: number;
    first_name: string;
    last_name: string;
    preferred_language: "english" | "spanish" | "capeverdean" | "haitian";
    preferred_contact_method: PreferredContactMethod;
    contact_methods: {
      phone_contact_methods: Array<{
        id: number;
        phone_number: string;
        status: "ACTIVE" | "INACTIVE" | "NO_CONCEPT";
      }>;
      email_contact_methods: Array<{
        id: number;
        email: string;
        status: "ACTIVE" | "INACTIVE" | "NO_CONCEPT";
      }>;
    };
  };
  family_members: Array<{
    id: number;
    first_name: string;
    last_name: string;
    date_of_birth: string;
    preferred_name: string;
    gender: "MEN" | "WOMEN";
    preferred_language: "english" | "spanish" | "capeverdean" | "haitian";
    employment_status: "EMPLOYED" | "TRAININGPROGRAM" | "IN_SCHOOL" | "OTHER";
    employer_name: string;
    other_status_description: string;
  }>;
  children: Array<{
    id: number;
    first_name: string;
    last_name: string;
    gender: "MEN" | "WOMEN";
    preferred_language: "english" | "spanish" | "capeverdean" | "haitian";
    date_of_birth: string;
    relationship: "naturalChild" | "fosterChild" | "guardianChild";
    date_when_child_care_is_needed: string;
    child_has_a_disability: boolean;
    child_is_in_head_start_and_needs_more_hours_of_coverage: boolean;
    age: number | null;
  }>;
  vouchers: Array<{
    id: number;
    type:
      | "UNKNOWN"
      | "WAITLIST"
      | "OTHER"
      | "DTA_REFERRAL"
      | "DCF_REFERRAL"
      | "HOMELESS_REFERRAL";
    status: AuthorizationStatus;
    created_at: string;
    updated_at: string;
    children: Array<{
      id: number;
      first_name: string;
      last_name: string;
    }>;
    referral_source: string;
    family_has_experienced_domestic_violence: boolean;
    family_is_experiencing_homelessness: boolean;
    parent_has_a_disability: boolean;
    parent_is_a_member_of_the_us_military: boolean;
    parent_is_an_early_educator: boolean;
    parent_is_receiving_treatment_for_substance_abuse: boolean;
    teen_parent: boolean;
    child_only_needs_coverage_during_school_closures: boolean;
    proof_of_identification_verified: boolean;
    is_on_waitlist: boolean;
    referral_date: string;
    child_care_status: string;
    shelter: string;
    shelter_contact: string;
    shelter_contact_email: string;
    shelter_phone: string;
    dta_referral_number: string;
    ccrr: string;
    dta_program: string;
    service_need: string;
    dcf_case_number: string;
    dcf_authorization_number: string;
    dcf_region: string;
    issuing_location: string;
    tafdc_closing_date: string;
    dta_referral_type: string;
    placements: Array<{
      id: number;
      voucher: {
        id: number;
        created_at: string;
        updated_at: string;
        type:
          | "UNKNOWN"
          | "WAITLIST"
          | "OTHER"
          | "DTA_REFERRAL"
          | "DCF_REFERRAL"
          | "HOMELESS_REFERRAL";
      };
      child_care_need: "FULL_TIME" | "PART_TIME";
      provider_name: string;
      child_care_duration: string;
      preferred_program_type: "FULL_TIME" | "PART_TIME";
      child_care_transportation_needs:
        | "NO_NEED"
        | "HOME_TO_PROVIDER"
        | "PROVIDER_TO_HOME"
        | "HOME_TO_PROVIDER_AND_PROVIDER_TO_HOME";
      provider_program: string;
      updated_at: string;
      child: {
        id: number;
        first_name: string;
        last_name: string;
      };
    }>;
  }>;
  missing_fields: Array<{
    voucher_id: number;
    missing_fields: {
      family_missing_fields: string[];
      parent_missing_fields: Array<{
        id: number;
        missing_fields: string[];
      }>;
      child_missing_fields: Array<{
        id: number;
        missing_fields: string[];
      }>;
      voucher_missing_fields: string[];
      global_errors: string[];
      placement_missing_fields: Array<{
        id: number;
        missing_fields: string[];
      }>;
      child_with_missing_placement: Array<{
        child_id: number;
      }>;
    };
  }>;
  street_address: string;
  unit_address: string;
  city: string;
  zip_code: string;
}

export const familyUpdate = ({ data }: { data: DeepPartial<IFamily> }) =>
  post(`${BASE_URL}/api/family-members/family/update/`, data);

export const useMyFamily = () =>
  useFetch<IFamily>("/api/family-members/family/detail/");
