import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import Link from "components/Link";

import { colors } from "theme";

import CompleteImage from "assets/complete.png";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const Complete = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { familyId: familyIdParam } = useParams<{ familyId: string }>();
  const familyId = familyIdParam ? parseInt(familyIdParam) : undefined;

  const goToHomePage = () => {
    if (familyId) {
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_FAMILY_DETAIL, {
          id: familyId.toString(),
        })
      );
    } else {
      navigate(URLS.HOME);
    }
  };
  return (
    <Stack
      justifyContent={"center"}
      gap="30px"
      sx={{ marginTop: "50px" }}
      alignItems="center"
    >
      <img src={CompleteImage} alt="Completed" style={{ maxWidth: "400px" }} />
      <Text
        variant="titleLarge"
        sx={{ textAlign: "center", marginBottom: "50px" }}
      >
        {t("completeHeader")}
      </Text>
      {!familyId && (
        <Text variant="titleMedium">{t("familyAdministratorFollowUp")}</Text>
      )}
      {!familyId && (
        <Stack alignItems={"center"} gap="10px" sx={{ mt: "50px" }}>
          <Text variant="titleSmall" color={colors.onSurfaceVariant}>
            {t("promptDontWantToWait")}
          </Text>
          <Link
            to={"https://booknow.appointment-plus.com/180n6yhv/"}
            external
            target="_blank"
          >
            <Text variant="titleSmall" color={colors.primary}>
              {t("actionBookAppointment")}
            </Text>
          </Link>
          <Text variant="titleSmall" color={colors.onSurfaceVariant}>
            {t("descriptionWithFamilyAdministrator")}
          </Text>
        </Stack>
      )}
      <BasicButton
        label={familyId ? t("viewFamilyDetail") : t("home")}
        backgroundColor={colors.secondaryContainer}
        color={colors.onSecondaryContainer}
        onHoverBackground={colors.onHoverSecondaryContainer}
        onClick={goToHomePage}
      />
    </Stack>
  );
};

export default Complete;
