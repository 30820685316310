import React from "react";

import MenuItem from "components/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";

import { colors } from "theme";
import { FormControl, InputLabel, Theme, useTheme } from "@mui/material";
import FormHelperText from "components/FormHelperText";

type OptionType = {
  label: string;
  value: string;
};

type MultiSelectProps = {
  options: OptionType[];
  label: string;
  error?: boolean;
  helperText?: string;
} & SelectProps;

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  label,
  error,
  helperText,
  ...props
}) => {
  const theme = useTheme();
  const value = props.value as string[];

  const getStyles = (value: string, values: string[], theme: Theme) => {
    return {
      fontWeight: values.includes(value)
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
    };
  };

  return (
    <FormControl error={error}>
      <InputLabel id="label-id">{label}</InputLabel>
      <Select
        multiple
        labelId={"label-id"}
        label={label}
        {...props}
        sx={{
          borderRadius: "4px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: colors.outline,
            },
            "&:hover fieldset": {
              borderColor: colors.primary,
            },
            "&.Mui-focused fieldset": {
              borderColor: colors.primary,
            },
          },
          "& .MuiFormLabel-root": {
            color: colors.onSurface,

            "&.Mui-focused": {
              color: colors.primary,
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={getStyles(option.value, value, theme)}
            sx={{
              borderBottom: `1px solid ${colors.outline}`,
              backgroundColor: colors.surfaceContainer,
              paddingTop: "10px",
              paddingBottom: "10px",
              "&:last-child": {
                borderBottom: "none",
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default MultiSelect;
