import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// import { toast } from "react-toastify";

// import BasicButton from "components/BasicButton";
import Text from "components/Text";
import Stack from "components/Stack";
// import useMediaQuery from "components/useMediaQuery";

import LinkSended from "assets/link-sended.png";

// import { colors } from "theme";

// import { resendMagicLink } from "entities/Auth/sdk";

const LoginVerification = () => {
  const location = useLocation();
  // const matches = useMediaQuery("(min-width:600px)");
  const { emailOrPhone } = location.state || {};
  const { t } = useTranslation();

  // const handleResendLink = () => {
  //   const contactMethod = emailOrPhone.includes("@") ? "email" : "phone";
  //   const resendLinkData = {
  //     email: contactMethod === "email" ? emailOrPhone : undefined,
  //     phone_number: contactMethod === "phone" ? emailOrPhone : undefined,
  //     redirect_id: 2,
  //   };
  //   resendMagicLink(resendLinkData)
  //     .then(() => {
  //       toast.success(t("resendLinkSuccess"));
  //     })
  //     .catch((error) => {
  //       const errorMessage =
  //         error.detail?.non_field_errors?.[0] || t("resendLinkError");
  //       toast.error(errorMessage);
  //     });
  // };

  return (
    <Stack
      justifyContent={"center"}
      gap="30px"
      alignItems="center"
      sx={{ marginTop: "50px" }}
    >
      <img src={LinkSended} alt="Link sended" style={{ maxWidth: "400px" }} />
      <Stack>
        <Text
          variant="titleLarge"
          sx={{ textAlign: "center", wordBreak: "break-word" }}
        >
          {t("weSentLink", { contactMethod: emailOrPhone })}
        </Text>
        <Text variant="titleLarge" sx={{ textAlign: "center", mt: 2 }}>
          {t("checkYourLink", {
            contactMethod: emailOrPhone.includes("@")
              ? t("email").toLowerCase()
              : t("phone"),
          })}
        </Text>
      </Stack>
      {/* <Stack
        justifyContent="center"
        sx={{ mt: 4, width: "100%" }}
        gap="15px"
        alignItems={matches ? "center" : "flex-start"}
      >
        <BasicButton
          label={t("resendLink")}
          onClick={handleResendLink}
          backgroundColor={colors.secondaryContainer}
          color={colors.onSecondaryContainer}
          onHoverBackground={colors.onHoverSecondaryContainer}
        />
      </Stack> */}
    </Stack>
  );
};

export default LoginVerification;
