import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import AddButton from "components/AddButton";
import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";
import ChildItem from "components/ChildItem";
import useMediaQuery from "components/useMediaQuery";
import BasicButton from "components/BasicButton";

import { useDetailDocumentData } from "entities/Documents/sdk";

import { colors } from "theme";
import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const Children = () => {
  const { documentId: documentIdParam } = useParams<{
    documentId: string;
  }>();
  const documentId = parseInt(String(documentIdParam));
  const navigate = useNavigate();

  const { data } = useDetailDocumentData(documentId);

  const calculateAge = (dob: string): number => {
    const birthDate = dayjs(dob);
    const today = dayjs();
    const result = today.diff(birthDate, "year");
    return result >= 0 ? result : 0;
  };

  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");

  const handleSave = () => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS, {
        documentId: String(documentId),
        stepname: "authorization-type",
      })
    );
  };

  const openAddChild = () => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS_ADD_CHILD, {
        documentId: String(documentId),
      })
    );
  };

  const handleChildEdit = (parsedChildId: number) => {
    if (parsedChildId) {
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS_EDIT_CHILD, {
          documentId: String(documentId),
          parsedChildId,
        })
      );
    }
  };

  if (!data) {
    return null;
  }
  return (
    <>
      <Stack sx={{ mb: "30px" }} gap="15px">
        <Text variant="titleMedium">{t("children")}</Text>
        <Divider />
      </Stack>
      <Stack
        justifyContent={"space-between"}
        spacing={2}
        sx={{ height: "100%" }}
      >
        <Stack>
          {data.family_data.children_data.map((child, index) => (
            <ChildItem
              key={index}
              name={`${child.first_name} ${child.last_name}`}
              years={calculateAge(child.date_of_birth)}
              onEdit={() => handleChildEdit(child.id)}
            />
          ))}
          <Stack alignItems="center" style={{ marginBottom: "20px" }}>
            <AddButton label={t("addChild")} onClick={openAddChild} />
          </Stack>
        </Stack>
        <Stack
          sx={{
            borderTop: matches ? "1px solid" : "none",
            borderColor: colors.outline,
            paddingTop: "30px",
            alignItems: "center",
          }}
        >
          <BasicButton label={t("save")} onClick={handleSave} />
        </Stack>
      </Stack>
    </>
  );
};

export default Children;
