import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import _ from "lodash";
import dayjs from "dayjs";

import { toast } from "react-toastify";

import Stack from "components/Stack";
import TextField from "components/TextField";
import StyledSelect from "components/StyledSelect";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";
import DateInput from "components/DateInput";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";
import MultiSelect from "components/MultiSelect";

import { phoneMask } from "utils/masks";

import {
  IDetailDocumentData,
  parsedVoucherUpdate,
  useDocumentsOptions,
} from "entities/Documents/sdk";
import { colors } from "theme";
import { reverse } from "utils/urls";
import { URLS } from "config/urls";

interface IProps {
  data: IDetailDocumentData;
  documentId: number;
}
const HomelessReferralAuthorization = ({ data, documentId }: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    authorizationTypeOptions,
    childCareStatusOptions,
    referralSourceOptions,
  } = useDocumentsOptions();
  const matches = useMediaQuery("(min-width:600px)");

  const homeless_data = data.family_data.voucher_data;
  const { control, getValues } = useForm({
    defaultValues: {
      authorizationType: data.document_type,
      referralDate: dayjs(homeless_data?.referral_date || new Date()).format(
        "MM/DD/YYYY"
      ),
      shelter: homeless_data?.shelter || "",
      referralSource:
        homeless_data?.referral_source || referralSourceOptions[0].value,
      shelterContact: homeless_data?.shelter_contact || "",
      shelterContactEmail: homeless_data?.shelter_contact_email || "",
      shelterPhone: homeless_data?.shelter_phone || "",
      childCareStatus: homeless_data?.child_care_status || "",
      proof_of_identification_verified:
        homeless_data?.proof_of_identification_verified || false,
      CCRR: homeless_data?.ccrr || "",
      children: data.family_data.voucher_data.children.map((child) =>
        String(child.id)
      ),
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = () => {
    if (homeless_data) {
      const values = getValues();

      const homelessData = {
        authorization_type: data.document_type,
        referral_date: values.referralDate
          ? dayjs(values?.referralDate, "MM/DD/YYYY").format("YYYY-MM-DD")
          : undefined,
        child_care_status: values.childCareStatus,
        referral_source: values.referralSource,
        shelter: values.shelter,
        shelter_contact: values.shelterContact,
        shelter_contact_email: values.shelterContactEmail,
        shelter_phone: values.shelterPhone,
        proof_of_identification_verified:
          values.proof_of_identification_verified,
        ccrr: values.CCRR,
        children: values.children,
      };

      setIsSubmitting(true);
      parsedVoucherUpdate({
        parsedVoucherId: homeless_data.id,
        data: homelessData,
      })
        .then(() => {
          setIsSubmitting(false);
          navigate(
            reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS, {
              documentId: String(documentId),
              stepname: "placement",
            })
          );
        })
        .catch(() => {
          const errorMessage = t("errorSavingData");
          toast.error(errorMessage);
          setIsSubmitting(false);
        });
    }
  };

  const childrenOptions = data.family_data.children_data.map((child) => ({
    label: `${child.first_name} ${child.last_name}`,
    value: String(child.id),
  }));

  return (
    <>
      <form>
        <Stack spacing={2}>
          <Controller
            name="children"
            control={control}
            rules={{ required: t("fieldRequired") }}
            render={({ field }) => (
              <MultiSelect
                {...field}
                label={t("childrenAuthorized")}
                // error={!!formMethods.formState.errors.children}
                // helperText={formMethods.formState.errors.children?.message}
                options={childrenOptions}
              />
            )}
          />
          <Controller
            name="authorizationType"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("authorizationType")}
                options={authorizationTypeOptions}
                disabled
              />
            )}
          />
          <DateInput
            label={t("referralDate")}
            name="referralDate"
            control={control}
          />
          <Controller
            name="childCareStatus"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("childCareStatus")}
                options={childCareStatusOptions}
              />
            )}
          />
          <Controller
            name="referralSource"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("referralSource")}
                options={referralSourceOptions}
              />
            )}
          />
          <Controller
            name="shelter"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("shelter")} />
            )}
          />
          <Controller
            name="shelterContact"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("shelterContact")} />
            )}
          />{" "}
          <Controller
            name="shelterContactEmail"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("shelterContactEmail")} />
            )}
          />
          <Controller
            name="shelterPhone"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("shelterContactPhone")}
                inputRef={(ref) => {
                  field.ref(ref);
                  phoneMask(ref);
                }}
              />
            )}
          />
          <Controller
            name="CCRR"
            control={control}
            render={({ field }) => <TextField {...field} label={t("CCRR")} />}
          />
          <Controller
            name="proof_of_identification_verified"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("proofOfIdentificationVerified")}
              />
            )}
          />
        </Stack>
      </form>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("save")}
            onClick={handleSave}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default HomelessReferralAuthorization;
