import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import Box from "components/Box";
import FormLabel from "components/FormLabel";
import TextField from "components/TextField";
import Text from "components/Text";
import useMediaQuery from "components/useMediaQuery";

import { colors } from "theme";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

import { additionalInformationUpdate } from "entities/FamilyMember/sdk";
import { familySpecialistAdditionalInformationUpdate } from "entities/FamilyEdit/sdk";

const AdditionalInformation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { familyId: familyIdParam } = useParams<{ familyId: string }>();
  const familyId = familyIdParam ? parseInt(familyIdParam) : undefined;
  const matches = useMediaQuery("(min-width:600px)");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [additionalInformation, setAdditionalInformation] = useState("");

  const handleNextClick = async () => {
    setIsSubmitting(true);
    const data = {
      additional_information: additionalInformation,
    };
    try {
      if (familyId) {
        await familySpecialistAdditionalInformationUpdate(familyId, data);
        navigate(reverse(URLS.FAMILY_SPECIALIST_COMPLETE, { familyId }));
      } else {
        await additionalInformationUpdate(data);
        navigate(URLS.FAMILY_INFORMATION_COMPLETE);
      }
    } catch (error: any) {
      toast.error(t("errorSavingData") + (error.response?.data?.message || ""));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAdditionalInformation(event.target.value);
  };

  return (
    <>
      <Stack alignSelf="center" sx={{ width: matches ? "50%" : "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            m: "10px 0",
          }}
        >
          <Box sx={{ flexGrow: 1, height: "1px", bgcolor: "text.primary" }} />
          <FormLabel component="legend" style={{ padding: "0 10px" }}>
            <Text variant="titleMedium" color={colors.onSurface}>
              {t("optional")}
            </Text>
          </FormLabel>
          <Box sx={{ flexGrow: 1, height: "1px", bgcolor: "text.primary" }} />
        </Box>
        <TextField
          multiline
          rows={2}
          variant="outlined"
          fullWidth
          name="additional_information"
          placeholder={t("anythingElse")}
          value={additionalInformation}
          onChange={handleTextFieldChange}
        />
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("next")}
            onClick={handleNextClick}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};
export default AdditionalInformation;
