import { useParams, useNavigate } from "react-router-dom";

import ParentForm from "entities/Parent/components/Form";

import { parentUpdateInUserFamily, IParent } from "entities/Parent/sdk";
import { useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";

const ParentEditPage = () => {
  const navigate = useNavigate();
  const { parentId: parentIdParam } = useParams<{ parentId: string }>();
  const parentId = parseInt(String(parentIdParam));
  const { data: family } = useMyFamily();

  const parent = family?.family_members.find(
    (parent) => parent.id === parentId
  );

  const handleSave = (data: Partial<IParent>) => {
    return parentUpdateInUserFamily({ parentId, data }).then(() => {
      navigate(URLS.FAMILY_INFORMATION_PARENTS);
    });
  };

  if (!parent) {
    return null;
  }

  return <ParentForm initialValues={parent} onSave={handleSave} />;
};

export default ParentEditPage;
