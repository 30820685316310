import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import FullPageDialog from "components/FullPageDialog";

import ParentForm from "entities/Parent/components/Form";

import { parentCreateInUserFamily, IParent } from "entities/Parent/sdk";

import { URLS } from "config/urls";

const ParentAddPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSave = (values: Partial<IParent>) =>
    parentCreateInUserFamily({ data: values }).then(() => {
      navigate(URLS.MISSING_INFORMATION_PARENTS);
    });

  return (
    <FullPageDialog
      title={t("parentStatus")}
      progressBarPercentage={20}
      backUrl={URLS.MISSING_INFORMATION_PARENTS}
      closeUrl={URLS.HOME}
    >
      <ParentForm onSave={handleSave} />
    </FullPageDialog>
  );
};

export default ParentAddPage;
