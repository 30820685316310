import { forwardRef } from "react";

import TextField from "components/TextField";
import MenuItem from "components/MenuItem";
import { TextFieldProps } from "@mui/material";

import { colors } from "theme";

interface OptionType {
  label: string;
  value: string | number | undefined;
}

type StyledSelectProps = {
  options: ReadonlyArray<OptionType>;
} & TextFieldProps;

const StyledSelect = forwardRef(
  ({ options, ...props }: StyledSelectProps, ref) => {
    return (
      <TextField
        {...props}
        select
        inputRef={ref}
        sx={{
          borderRadius: "4px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: colors.outline,
            },
          },
          // "& .MuiFormLabel-root": {
          //   color: colors.onSurface,
          // },
        }}
        SelectProps={{
          MenuProps: {
            MenuListProps: {
              style: {
                backgroundColor: colors.surfaceContainer,
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              borderBottom: `1px solid ${colors.outline}`,
              backgroundColor: colors.surfaceContainer,
              paddingTop: "10px",
              paddingBottom: "10px",
              "&:last-child": {
                borderBottom: "none",
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }
);

export default StyledSelect;
