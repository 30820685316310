import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import BasicButton from "components/BasicButton";
import FormControl from "components/FormControl";
import FormLabel from "components/FormLabel";
import ToggleButton, { ToggleButtonGroup } from "components/ToggleButton";
import TextField from "components/TextField";
import Stack from "components/Stack";
import Box from "components/Box";
import Text from "components/Text";
import useMediaQuery from "components/useMediaQuery";
import StyledSelect from "components/StyledSelect";
import Menu from "components/Menu";
import MenuItem from "components/MenuItem";

import {
  useGenderOptions,
  useLanguageOptions,
} from "entities/FamilyMember/sdk";
import {
  EmailStatus,
  IWaitlistCreateData,
  PhoneStatus,
  PreferredContactMethod,
  contactMethodOptions,
  waitlistCreate,
} from "entities/FamilyEdit/sdk";
import ContactMethodRow from "components/ContactMethodRow";

import { colors } from "theme";
import { ChatIcon, CheckIcon, MailOutlinedIcon } from "icons";

import { URLS } from "config/urls";
import { extractErrorMessage } from "utils/error";
import { reverse } from "utils/urls";

const PrimaryContact = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const location = useLocation();
  const genderOptions = useGenderOptions();
  const languageOptions = useLanguageOptions();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contactMethods, setContactMethods] = useState<
    {
      method: PhoneStatus | EmailStatus;
      contact: string;
      type: "email" | "phone";
      error?: string;
    }[]
  >([]);

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      preferred_name: "",
      gender: undefined,
      preferred_language: undefined,
      preferred_contact_method: "SMS",
    },
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (type: "email" | "phone") => {
    setContactMethods([
      ...contactMethods,
      { method: "ACTIVE" as PhoneStatus | EmailStatus, contact: "", type },
    ]);
    handleClose();
  };

  const handleContactMethodChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const newMethods = [...contactMethods];
    newMethods[index][name as "method" | "contact"] = value as
      | PhoneStatus
      | EmailStatus;
    newMethods[index].error = "";
    setContactMethods(newMethods);
  };

  const validateContactMethods = () => {
    const newMethods = contactMethods.map((method) => {
      return method;
    });
    setContactMethods(newMethods);
    return newMethods.every((method) => !method.error);
  };

  const handleNextClick = async () => {
    setIsSubmitting(true);
    const values = getValues();

    if (!validateContactMethods() || Object.keys(errors).length > 0) {
      setIsSubmitting(false);
      return;
    }

    const phone_contact_methods = contactMethods
      .filter((cm) => cm.type === "phone")
      .map((cm) => ({ phone_number: cm.contact, status: cm.method }));
    const email_contact_methods = contactMethods
      .filter((cm) => cm.type === "email")
      .map((cm) => ({ email: cm.contact, status: cm.method }));

    const formData: IWaitlistCreateData = {
      ...values,
      contact_methods: {
        phone_contact_methods: phone_contact_methods.map((cm) => ({
          phone_number: cm.phone_number,
          status: cm.status as PhoneStatus,
        })),
        email_contact_methods: email_contact_methods.map((cm) => ({
          email: cm.email,
          status: cm.status as EmailStatus,
        })),
      },
      completed_status: location.state?.status,
      preferred_contact_method:
        values.preferred_contact_method as PreferredContactMethod,
    };

    waitlistCreate(formData)
      .then((response) => {
        navigate(
          reverse(URLS.FAMILY_SPECIALIST_YOUR_STATUS, {
            familyId: response.id,
          })
        );
      })
      .catch((error) => {
        const errorMessage = extractErrorMessage(error);
        toast.error(errorMessage);
      });
    setIsSubmitting(false);
  };

  return (
    <>
      <Stack
        gap="20px"
        alignItems={matches ? "center" : "flex-start"}
        sx={{ mt: "20px" }}
      >
        <form style={{ height: "100%", width: "100%" }}>
          <Stack
            sx={{ height: "100%" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack
              spacing={2}
              direction="column"
              sx={{ width: matches ? "50%" : "auto" }}
            >
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={t("firstName")}
                    variant="outlined"
                    margin="normal"
                    {...field}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={t("lastName")}
                    variant="outlined"
                    margin="normal"
                    {...field}
                  />
                )}
              />
              <Controller
                name="preferred_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={t("preferredName")}
                    variant="outlined"
                    margin="normal"
                    {...field}
                  />
                )}
              />
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label={t("gender")}
                    options={genderOptions}
                    fullWidth
                    margin="normal"
                  />
                )}
              />
              <Controller
                name="preferred_language"
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label={t("preferredLanguage")}
                    options={languageOptions}
                    fullWidth
                    margin="normal"
                  />
                )}
              />
              <Stack sx={{ mt: "10px", mb: "20px" }}>
                <Stack spacing={2}>
                  {contactMethods.map((contactMethod, index) => (
                    <ContactMethodRow
                      key={index}
                      options={contactMethodOptions}
                      value={contactMethod.method}
                      onChange={(event) =>
                        handleContactMethodChange(index, event)
                      }
                      placeholder={t(
                        contactMethod.type === "email" ? "email" : "phoneNumber"
                      )}
                      type={contactMethod.type}
                      contact={contactMethod.contact}
                      error={contactMethod.error}
                    />
                  ))}
                  <Box sx={{ position: "relative" }}>
                    <BasicButton
                      label={t("addAnotherContactMethod")}
                      onClick={handleClick}
                      backgroundColor={colors.secondaryContainer}
                      color={colors.onSecondaryContainer}
                      onHoverBackground={colors.onHoverSecondaryContainer}
                      sx={{ width: "100%" }}
                    />
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          width: anchorEl ? anchorEl.clientWidth : "auto",
                        },
                      }}
                      MenuListProps={{
                        sx: {
                          backgroundColor: colors.surfaceContainerHigh,
                        },
                      }}
                    >
                      <MenuItem onClick={() => handleMenuItemClick("email")}>
                        <MailOutlinedIcon />
                        <Text variant="titleSmall" sx={{ ml: "10px" }}>
                          {t("email")}
                        </Text>
                      </MenuItem>
                      <MenuItem onClick={() => handleMenuItemClick("phone")}>
                        <ChatIcon />
                        <Text variant="titleSmall" sx={{ ml: "10px" }}>
                          {t("phoneNumber")}
                        </Text>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Stack>
              </Stack>
              <FormControl
                component="fieldset"
                margin="normal"
                sx={{ width: "100%" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{ flexGrow: 1, height: "1px", bgcolor: "text.primary" }}
                  />
                  <FormLabel component="legend" style={{ padding: "0 10px" }}>
                    {t("whichDoYouPrefer")}
                  </FormLabel>
                  <Box
                    sx={{ flexGrow: 1, height: "1px", bgcolor: "text.primary" }}
                  />
                </Box>
                <Controller
                  name="preferred_contact_method"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ToggleButtonGroup
                      value={value}
                      exclusive
                      onChange={(newValue) => onChange(newValue)}
                      fullWidth
                      sx={{
                        "& .MuiToggleButtonGroup-grouped": {
                          margin: "8px 0",
                          border: `2px solid ${colors.outline}`,
                          textTransform: "capitalize",
                          justufyContent: "flex-start",
                          "&.Mui-selected": {
                            bgcolor: colors.secondaryContainer,
                            color: colors.onSecondaryContainer,
                            "&:hover": {
                              bgcolor: colors.onHoverSecondaryContainer,
                              color: colors.onSecondaryContainer,
                            },
                          },
                          "&:not(:first-of-type)": {
                            borderRadius: "0 50px 50px 0",
                          },
                          "&:first-of-type": {
                            borderRadius: "50px 0 0 50px",
                            borderRight: "1px solid",
                            borderColor: colors.outline,
                          },
                        },
                      }}
                    >
                      <ToggleButton
                        value="SMS"
                        style={{
                          justifyContent: "flex-start",
                          paddingLeft: "30px",
                        }}
                      >
                        {value == "SMS" && (
                          <CheckIcon
                            onClick={(e) => e.stopPropagation()}
                            style={{ marginRight: "15px" }}
                          />
                        )}
                        {t("textMessage")}
                      </ToggleButton>
                      <ToggleButton
                        value="MAIL"
                        style={{
                          justifyContent: "flex-start",
                          paddingLeft: "30px",
                        }}
                      >
                        {value == "MAIL" && (
                          <CheckIcon
                            onClick={(e) => e.stopPropagation()}
                            style={{ marginRight: "15px" }}
                          />
                        )}
                        {t("email")}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </FormControl>
            </Stack>
          </Stack>
        </form>
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("next")}
            onClick={handleSubmit(handleNextClick)}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default PrimaryContact;
