export const colors = {
  primary: "#B30838",
  primaryContainer: "#DA2C52",
  onHoverPrimaryContainer: "#B91C48",
  secondary: "#515B59",
  secondaryContainer: "#A4E3DC",
  onSecondaryContainer: "#2A221B",
  onHoverSecondaryContainer: "#97D1CA",
  tertiary: "#675D54",
  tertiaryContainer: "#E7DDD6",
  onTertiaryContainer: "#463C35",
  error: "#964409",
  errorContainer: "#BA5A00",
  surfaceDim: "#E9E1DE",
  surface: "#FDFAFB",
  surfaceBright: "#FCF6F5",
  surfaceContainerLow: "#F9F3F2",
  surfaceContainer: "#F4EFED",
  surfaceContainerHigh: "#EEE9E7",
  surfaceContainerHigher: "#EAE0DB",
  onSurface: "#312F2D",
  onSurfaceVariant: "#675B57",
  outline: "#CFC8C3",
  outlineVariant: "#C8C0BB",
  white: "#FFFFFF",
};
