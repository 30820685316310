import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";

import ParsedChildEditForm from "entities/ParsedChild/components/Form";

import {
  IDetailDocumentData,
  useDetailDocumentData,
} from "entities/Documents/sdk";
import { parsedChildUpdate } from "entities/ParsedChild/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const DocumentsContainerChildEdit = () => {
  const { t } = useTranslation();
  const { documentId: documentIdParam, parsedChildId: parsedChildIdParam } =
    useParams<{
      documentId: string;
      parsedChildId: string;
    }>();
  const documentId = parseInt(String(documentIdParam));
  const parsedChildId = parseInt(String(parsedChildIdParam));

  const navigate = useNavigate();

  const { data } = useDetailDocumentData(documentId);

  const childData = data?.family_data.children_data.find(
    (child) => child.id === parsedChildId
  );

  const handleUpdate = (
    values: Partial<IDetailDocumentData["family_data"]["children_data"][number]>
  ) => {
    return parsedChildUpdate({ parsedChildId, data: values }).then(() => {
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS, {
          documentId: String(documentId),
          stepname: "children",
        })
      );
    });
  };

  if (!childData) {
    return null;
  }

  return (
    <>
      <Stack sx={{ mb: "30px" }} gap="15px">
        <Text variant="titleMedium">{t("children")}</Text>
        <Divider />
      </Stack>
      <ParsedChildEditForm initialValues={childData} onSave={handleUpdate} />
    </>
  );
};

export default DocumentsContainerChildEdit;
