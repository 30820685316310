import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AddButton from "components/AddButton";
import ParentStatusItem from "components/ParentStatusItem";
import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";

import { getEmploymentStatusDisplayValue } from "entities/FamilyMember/sdk";
import { useDetailDocumentData } from "entities/Documents/sdk";

import { reverse } from "utils/urls";

import { colors } from "theme";
import { URLS } from "config/urls";

const ParentStatus = () => {
  const { documentId: documentIdParam } = useParams<{ documentId: string }>();
  const documentId = parseInt(String(documentIdParam));
  const navigate = useNavigate();

  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");

  const { data } = useDetailDocumentData(documentId);

  const parentsData = _.orderBy(data?.family_data.parents_data, "id");

  if (!parentsData) {
    return null;
  }

  const handleSave = () => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS, {
        documentId: String(documentId),
        stepname: "children",
      })
    );
  };

  const openAddParent = () => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS_ADD_PARENT, {
        documentId: String(documentId),
        stepname: "parents/add",
      })
    );
  };

  const handleParentEdit = (parentId: number) => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS_EDIT_PARENT, {
        documentId: String(documentId),
        parentId: parentId,
      })
    );
  };

  return (
    <>
      <Stack sx={{ mb: "30px" }} gap="15px">
        <Text variant="titleMedium">{t("parentStatus")}</Text>
        <Divider />
      </Stack>
      <Stack spacing={2} sx={{ flex: 1, justifyContent: "space-between" }}>
        <Stack>
          <>
            {parentsData.map((parent) => (
              <ParentStatusItem
                key={parent.id}
                name={parent.first_name + " " + parent.last_name}
                employmentStatus={
                  parent.employment_status !== null
                    ? getEmploymentStatusDisplayValue(parent.employment_status)
                    : "Unknown status"
                }
                employerName={parent.employer_name}
                onEdit={() => handleParentEdit(parent.id!)}
              />
            ))}
            <Stack alignItems="center" style={{ marginBottom: "20px" }}>
              <AddButton label={t("addParent")} onClick={openAddParent} />
            </Stack>
          </>
        </Stack>
        <Stack
          sx={{
            borderTop: matches ? "1px solid" : "none",
            borderColor: colors.outline,
          }}
        >
          <Stack
            gap="20px"
            alignItems="center"
            sx={{
              textAlign: "right",
              mt: "30px",
            }}
          >
            <BasicButton label={t("save")} onClick={handleSave} />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ParentStatus;
