import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircleFlag } from "react-circle-flags";
import { Outlet } from "react-router-dom";

import AppBar from "components/AppBar";
import Box from "components/Box";
import IconButton from "components/IconButton";
import MenuItem from "components/MenuItem";
import Stack from "components/Stack";
import Text from "components/Text";
import useMediaQuery from "components/useMediaQuery";
import FormControl from "components/FormControl";
import Select, { SelectChangeEvent } from "components/Select";

import { getTitle } from "utils/titles";
import { URLS } from "config/urls";
import { saveLanguage } from "utils/storage";

import { ArrowLeftIcon } from "icons";

import { colors } from "theme";

const AnonimousUserLayout = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const title = getTitle(location.pathname);

  const handleFlagChange = (event: SelectChangeEvent) => {
    const newLang = event.target.value as string;
    i18n.changeLanguage(newLang);
    saveLanguage(newLang);
  };

  const goBack = () => {
    if (location.pathname === URLS.CHILD_CARE_NEEDS_DESCRIPTION) {
      navigate(URLS.INITIAL);
    } else {
      navigate(-1);
    }
  };

  const getFlagIcon = (lang: string) => {
    switch (lang) {
      case "en":
        return <CircleFlag countryCode="us" height="24" />;
      case "es":
        return <CircleFlag countryCode="es" height="24" />;
      case "kea":
        return <CircleFlag countryCode="cv" height="24" />;
      case "ht":
        return <CircleFlag countryCode="ht" height="24" />;
      default:
        return <CircleFlag countryCode="us" height="24" />;
    }
  };

  return (
    <>
      <AppBar
        sx={{
          paddingLeft: 0,
          height: "64px",
          backgroundColor: colors.surface,
          justifyContent: "center",
          boxShadow: "none",
          position: "fixed",
          top: 0,
          margin: 0,
        }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            marginLeft: matches ? 0 : "25px",
            marginRight: matches ? 0 : "25px",
            borderBottom: `2px solid ${colors.outlineVariant}`,
            height: "100%",
          }}
          spacing={matches ? 0 : 6}
        >
          <Stack
            sx={{
              flex: 1,
              marginLeft: matches ? "35px" : "0px",
            }}
            direction="row"
            alignItems="center"
          >
            <IconButton onClick={goBack} sx={{ mr: "15px" }}>
              <ArrowLeftIcon style={{ fill: colors.onSurfaceVariant }} />
            </IconButton>
            <Text variant="titleLarge" color={colors.onSurfaceVariant}>
              {title}
            </Text>
          </Stack>

          <Stack>
            <FormControl
              variant="outlined"
              size="small"
              sx={{
                m: 1,
                border: "none",
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
            >
              <Select
                value={i18n.language}
                onChange={handleFlagChange}
                displayEmpty
                inputProps={{ "aria-label": "Language Selector" }}
                IconComponent={() => null}
                sx={{
                  height: "32px",
                  "& .MuiSelect-select": {
                    paddingLeft: 1,
                    paddingRight: "0 !important",
                    display: "flex",
                    alignItems: "center",
                  },
                  "& .MuiOutlinedInput-root": {
                    border: "none",
                    boxShadow: "none",
                  },
                }}
                renderValue={() => (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {getFlagIcon(i18n.language)}
                  </Stack>
                )}
              >
                <MenuItem value="en">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <CircleFlag countryCode="us" height="24" />
                    <span>English</span>
                  </Stack>
                </MenuItem>
                <MenuItem value="es">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <CircleFlag countryCode="es" height="24" />
                    <span>Spanish</span>
                  </Stack>
                </MenuItem>
                <MenuItem value="kea">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <CircleFlag countryCode="cv" height="24" />
                    <span>Cape Verdean Creole</span>
                  </Stack>
                </MenuItem>
                <MenuItem value="ht">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <CircleFlag countryCode="ht" height="24" />
                    <span>Haitian Creole</span>
                  </Stack>
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </AppBar>
      <Box
        sx={{
          p: matches ? "90px 25px" : "80px 25px 20px 25px",
          backgroundColor: colors.surface,
        }}
      >
        <Outlet />
      </Box>
    </>
  );
};

export default AnonimousUserLayout;
