const BASE_BACKEND_URL = import.meta.env.VITE_BASE_BACKEND_URL;
export const BASE_URL = `${BASE_BACKEND_URL}`;

export enum URLS {
  INITIAL = "/",
  LOGIN = "/login",
  HOME = "/home",
  SIGNUP = "/signup",
  GET_HELP = "/get-help",
  THANK_YOU = "/thank-you",
  REGISTRATION_VERIFY = "/registration-verify",
  LOGIN_VERIFY = "/login-verify",
  CHILD_CARE_NEEDS = "/child-care-needs",
  CHILD_CARE_NEEDS_DESCRIPTION = "/child-care-needs/description",
  CHILD_CARE_NEEDS_CHECK_NEEDS = "/child-care-needs/check-needs",
  CHILD_CARE_NEEDS_UPDATE_INFORMATION = "/child-care-needs/update-information",
  FAMILY_INFORMATION = "/family-information",
  MAGIC_LINK = "/magic-link/:magicToken",
  EXPIRED_MAGIC_LINK = "/expired-magic-link",
  FAMILY_INFORMATION_OVERVIEW = "/family-information/overview",
  FAMILY_INFORMATION_ABOUT_YOU = "/family-information/about-you",
  FAMILY_INFORMATION_YOUR_STATUS = "/family-information/your-status",
  FAMILY_INFORMATION_PRIMARY_CONTACT = "/family-information/primary-contact",
  FAMILY_INFORMATION_PARENTS = "/family-information/parents",
  FAMILY_INFORMATION_ADD_PARENT = "/family-information/add-parent",
  FAMILY_INFORMATION_EDIT_PARENT = "/family-information/parent/:parentId/edit",
  FAMILY_INFORMATION_MAILING_ADDRESS = "/family-information/mailing-address",
  FAMILY_INFORMATION_CHILDREN = "/family-information/children",
  FAMILY_INFORMATION_ADD_CHILD = "/family-information/add-child",
  FAMILY_INFORMATION_EDIT_CHILD = "/family-information/child/:childId/edit",
  FAMILY_INFORMATION_INCOME_VERIFICATION = "/family-information/income-verification",
  FAMILY_INFORMATION_HOUSEHOLD_DEFINITIONS = "/family-information/household-definitions",
  FAMILY_INFORMATION_SPECIAL_CIRCUMSTANCES = "/family-information/special-circumstances",
  FAMILY_INFORMATION_ADDITIONAL_INFORMATION = "/family-information/additional-information",
  FAMILY_INFORMATION_COMPLETE = "/family-information/complete",

  MISSING_INFORMATION_PRIMARY_CONTACT = "/missing-information/primary-contact",
  MISSING_INFORMATION_PARENTS = "/missing-information/parents",
  MISSING_INFORMATION_ADD_PARENT = "/missing-information/add-parent",
  MISSING_INFORMATION_EDIT_PARENT = "/missing-information/parent/:parentId/edit",
  MISSING_INFORMATION_CHILDREN = "/missing-information/children",
  MISSING_INFORMATION_ADD_CHILD = "/missing-information/add-child",
  MISSING_INFORMATION_EDIT_CHILD = "/missing-information/child/:childId/edit",
  MISSING_INFORMATION_AUTHORIZATION_LIST = "/missing-information/authorizations",
  MISSING_INFORMATION_AUTHORIZATION_EDIT = "/missing-information/authorization/:authorizationId/edit",
  MISSING_INFORMATION_PLACEMENT_LIST = "/missing-information/placements",
  MISSING_INFORMATION_PLACEMENT_ADD = "/missing-information/placements/add",
  MISSING_INFORMATION_PLACEMENT_EDIT = "/missing-information/placement/:placementId/edit",

  // Specialist URLs
  FAMILY_SPECIALIST_HOME = "/family-specialist/home", // unused
  FAMILY_SPECIALIST_FAMILY_LIST = "/family-specialist/family-list",
  FAMILY_SPECIALIST_FAMILY_DETAIL = "/family-specialist/family-detail/:id",
  FAMILY_SPECIALIST_AUTHORIZATION = "/family-specialist/family-information/authorization",
  FAMILY_SPECIALIST_PRIMARY_CONTACT = "/family-specialist/family-information/primary-contact",
  FAMILY_SPECIALIST_YOUR_STATUS = "/family-specialist/family-information/family/:familyId/your-status",
  FAMILY_SPECIALIST_PARENTS = "/family-specialist/family-information/family/:familyId/parents",
  FAMILY_SPECIALIST_ADD_PARENT = "/family-specialist/family-information/family/:familyId/add-parent",
  FAMILY_SPECIALIST_MAILING_ADDRESS = "/family-specialist/family-information/family/:familyId/mailing-address",
  FAMILY_SPECIALIST_CHILDREN = "/family-specialist/family-information/family/:familyId/children",
  FAMILY_SPECIALIST_ADD_CHILD = "/family-specialist/family-information/family/:familyId/add-child",
  FAMILY_SPECIALIST_SPECIAL_CIRCUMSTANCES = "/family-specialist/family-information/family/:familyId/special-circumstances",
  FAMILY_SPECIALIST_ADDITIONAL_INFORMATION = "/family-specialist/family-information/family/:familyId/additional-information",
  FAMILY_SPECIALIST_COMPLETE = "/family-specialist/family-information/family/:familyId/complete",
  FAMILY_SPECIALIST_DOCUMENTS = "/family-specialist/family-information/documents/:documentId/:stepname",
  FAMILY_SPECIALIST_DOCUMENTS_ADD_PARENT = "/family-specialist/family-information/documents/:documentId/parents/add",
  FAMILY_SPECIALIST_DOCUMENTS_EDIT_PARENT = "/family-specialist/family-information/documents/:documentId/parents/:parentId/edit",
  FAMILY_SPECIALIST_DOCUMENTS_ADD_CHILD = "/family-specialist/family-information/documents/:documentId/parsed-child/add",
  FAMILY_SPECIALIST_DOCUMENTS_EDIT_CHILD = "/family-specialist/family-information/documents/:documentId/parsed-child/:parsedChildId/edit",
  FAMILY_SPECIALIST_DOCUMENTS_ADD_PLACEMENT = "/family-specialist/family-information/documents/:documentId/placement/add",
  FAMILY_SPECIALIST_DOCUMENTS_EDIT_PLACEMENT = "/family-specialist/family-information/documents/:documentId/placement/:placementId/edit",
  FAMILY_SPECIALIST_FAMILY_ASSIGN_SPECIALIST = "/family-specialist/family-information/documents/:documentId/family/:familyId/assign-specialist",
  FAMILY_SPECIALIST_MESSAGES_LIST = "/family-specialist/messages-list",
  FAMILY_SPECIALIST_ADMIN = "/family-specialist/admin",
}
