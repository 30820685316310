import Stack from "components/Stack";
import { Outlet } from "react-router-dom";

const FamilyInformationPage = () => {
  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        minHeight: "calc(100vh - 140px)",
      }}
    >
      <Outlet />
    </Stack>
  );
};

export default FamilyInformationPage;
