import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import TextField from "components/TextField";
import useMediaQuery from "components/useMediaQuery";

import { URLS } from "config/urls";

import { addressUpdate, IAddressUpdate } from "entities/FamilyMember/sdk";

import { colors } from "theme";
import { familySpecialistAddressUpdate } from "entities/FamilyEdit/sdk";
import { extractErrorMessage } from "utils/error";
import { reverse } from "utils/urls";

const MailingAddress = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { familyId: familyIdParam } = useParams<{ familyId: string }>();
  const familyId = familyIdParam ? parseInt(familyIdParam) : undefined;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [streetAddressError, setStreetAddressError] = useState<string | null>(
    null
  );
  const [cityError, setCityError] = useState<string | null>(null);
  const [zipCodeError, setZipCodeError] = useState<string | null>(null);
  const matches = useMediaQuery("(min-width:600px)");

  const { control, watch } = useForm<IAddressUpdate>({});
  const watchAllFields = watch();

  const handleNextClick = async () => {
    let valid = true;
    if (!familyId) {
      if (!watchAllFields.street_address) {
        setStreetAddressError(t("fieldRequired"));
        valid = false;
      } else {
        setStreetAddressError(null);
      }

      if (!watchAllFields.city) {
        setCityError(t("fieldRequired"));
        valid = false;
      } else {
        setCityError(null);
      }

      if (!watchAllFields.zip_code) {
        setZipCodeError(t("fieldRequired"));
        valid = false;
      } else if (!/^\d{5}(?:[-\s]\d{4})?$/.test(watchAllFields.zip_code)) {
        setZipCodeError(t("invalidZipCode"));
        valid = false;
      } else {
        setZipCodeError(null);
      }
    }

    if (valid) {
      setIsSubmitting(true);
      const data = {
        street_address: watchAllFields.street_address,
        unit_address: watchAllFields.unit_address,
        city: watchAllFields.city,
        zip_code: watchAllFields.zip_code,
      };

      try {
        if (familyId) {
          await familySpecialistAddressUpdate(data, familyId);
          navigate(reverse(URLS.FAMILY_SPECIALIST_CHILDREN, { familyId }));
        } else {
          await addressUpdate(data);
          navigate(URLS.FAMILY_INFORMATION_CHILDREN);
        }
      } catch (error: any) {
        const errorMessage = extractErrorMessage(error);
        toast.error(errorMessage);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <Stack alignItems="center">
        <form style={{ width: matches ? "50%" : "auto" }}>
          <Controller
            name="street_address"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t("addressLine1")}
                fullWidth
                margin="normal"
                placeholder={t("streetName")}
                error={!!streetAddressError}
                helperText={streetAddressError}
              />
            )}
          />
          <Controller
            name="unit_address"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t("addressLine2")}
                fullWidth
                margin="normal"
                placeholder={t("addressInfo")}
              />
            )}
          />
          <Stack
            sx={{
              flexDirection: matches ? "row" : "column",
              gap: matches ? "20px" : 0,
            }}
          >
            <Controller
              name="city"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("city")}
                  fullWidth
                  margin="normal"
                  error={!!cityError}
                  helperText={cityError}
                />
              )}
            />
            <Controller
              name="zip_code"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("zipCode")}
                  fullWidth
                  margin="normal"
                  error={!!zipCodeError}
                  helperText={zipCodeError}
                />
              )}
            />
          </Stack>
        </form>
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("next")}
            onClick={handleNextClick}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default MailingAddress;
