import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";

import ParsedChildAddForm from "entities/ParsedChild/components/Form";

import {
  IDetailDocumentData,
  useDetailDocumentData,
} from "entities/Documents/sdk";
import { parsedChildCreate } from "entities/ParsedChild/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const DocumentsContainerChildAdd = () => {
  const { t } = useTranslation();
  const { documentId: documentIdParam } = useParams<{
    documentId: string;
  }>();
  const documentId = parseInt(String(documentIdParam));
  const navigate = useNavigate();

  const { data } = useDetailDocumentData(documentId);
  const parsedFamilyId = data?.family_data.id;

  const handleSubmit = (
    values: Partial<IDetailDocumentData["family_data"]["children_data"][number]>
  ) => {
    if (!parsedFamilyId) {
      return Promise.reject();
    }

    return parsedChildCreate({ parsedFamilyId, data: values }).then(() => {
      navigate(
        reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS, {
          documentId: String(documentId),
          stepname: "children",
        })
      );
    });
  };

  if (!parsedFamilyId) {
    return null;
  }

  return (
    <>
      <Stack sx={{ mb: "30px" }} gap="15px">
        <Text variant="titleMedium">{t("children")}</Text>
        <Divider />
      </Stack>
      <ParsedChildAddForm onSave={handleSubmit} />
    </>
  );
};

export default DocumentsContainerChildAdd;
