import { URLS } from "config/urls";
import i18n from "i18next"; // Import i18n instance from your i18next configuration

export const getTitle = (pathname: string) => {
  switch (pathname) {
    case URLS.HOME:
      return i18n.t("home");
    case URLS.FAMILY_INFORMATION_OVERVIEW:
      return i18n.t("overview");
    case URLS.FAMILY_INFORMATION_ABOUT_YOU:
      return i18n.t("aboutYou");
    case URLS.FAMILY_INFORMATION_YOUR_STATUS:
      return i18n.t("yourStatus");
    case URLS.FAMILY_INFORMATION_PARENTS:
      return i18n.t("parents");
    case URLS.FAMILY_INFORMATION_ADD_PARENT:
      return i18n.t("addParent");
    case URLS.FAMILY_INFORMATION_MAILING_ADDRESS:
      return i18n.t("mailingAddress");
    case URLS.FAMILY_INFORMATION_CHILDREN:
      return i18n.t("children");
    case URLS.FAMILY_INFORMATION_ADD_CHILD:
      return i18n.t("addChild");
    case URLS.FAMILY_INFORMATION_INCOME_VERIFICATION:
      return i18n.t("incomeVerification");
    case URLS.FAMILY_INFORMATION_HOUSEHOLD_DEFINITIONS:
      return i18n.t("householdDefinitions");
    case URLS.FAMILY_INFORMATION_SPECIAL_CIRCUMSTANCES:
      return i18n.t("specialCircumstance");
    case URLS.FAMILY_INFORMATION_ADDITIONAL_INFORMATION:
      return i18n.t("additionalInformation");
    case URLS.FAMILY_INFORMATION_COMPLETE:
      return i18n.t("complete");
    case URLS.GET_HELP:
      return i18n.t("getHelp");
    case URLS.THANK_YOU:
      return i18n.t("thankYou");
    case URLS.FAMILY_INFORMATION_PRIMARY_CONTACT:
      return i18n.t("primaryContact");
    default:
      return null;
  }
};
