import { Outlet } from "react-router-dom";

import Stack from "components/Stack";

const InterviewCreatePage = () => {
  return (
    <Stack
      flexDirection={"column"}
      justifyContent={"space-between"}
      sx={{
        minHeight: `calc(100vh - 140px)`,
      }}
    >
      <Outlet />
    </Stack>
  );
};

export default InterviewCreatePage;
