import MenuItem from "components/MenuItem";
import TextField from "components/TextField";
import IconButton from "components/IconButton";

import { CloseIcon } from "icons";

import { phoneMask } from "utils/masks";
import { colors } from "theme";

type OptionType<T> = {
  label: string;
  value: T;
};

type ContactMethodRowProps<T> = {
  options: ReadonlyArray<OptionType<T>>;
  status: T;
  onChange: (changed: { value: string; status: T }) => void;
  placeholder: string;
  type: "email" | "phone";
  contact: string;
  error?: string;
  onRemove?: () => void;
};

const ParsedPrimaryContactContactMethodRow = <T extends string>({
  options,
  status,
  onChange,
  placeholder,
  type,
  contact,
  error,
  onRemove,
}: ContactMethodRowProps<T>) => {
  const handleContactChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (type === "phone") {
      phoneMask(event.target);
    }
    onChange({ value: event.target.value, status });
  };

  return (
    <div
      style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
    >
      <TextField
        select
        value={status}
        onChange={(event) =>
          onChange({ value: contact, status: event.target.value as T })
        }
        variant="outlined"
        sx={{
          marginRight: "16px",
          minWidth: "150px",
          "& .MuiInputBase-root": {
            height: "35px",
          },
        }}
        name="method"
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        name="contact"
        value={contact}
        onChange={handleContactChange}
        placeholder={placeholder}
        variant="outlined"
        sx={{ flex: 1, marginRight: "16px" }}
        type={type === "email" ? "email" : "text"}
        error={!!error}
        helperText={error}
      />
      {onRemove && (
        <IconButton onClick={onRemove}>
          <CloseIcon style={{ fill: colors.onSurfaceVariant }} />
        </IconButton>
      )}
    </div>
  );
};

export default ParsedPrimaryContactContactMethodRow;
