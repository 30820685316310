import { forwardRef, useImperativeHandle } from "react";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import TextField from "components/TextField";
import StyledSelect from "components/StyledSelect";
import Stack from "components/Stack";
import Box from "components/Box";
import Text from "components/Text";

import { useDocumentsOptions } from "entities/Documents/sdk";

import { colors } from "theme";
import {
  IPlacement,
  placementCreate,
  placementUpdate,
} from "entities/FamilyEdit/sdk";
import { extractErrorMessage } from "utils/error";
import WeeklySchedule from "components/WeeklySchedule";
import dayjs from "dayjs";
import _ from "lodash";

interface IProps {
  onSave: () => void;
  familyId: number;
  refetchFamilyDetail: () => void;
  initialData?:
    | (IPlacement & {
        voucher: {
          id: number;
        };
      })
    | null;
  vouchers: {
    id: number;
    type: string;
    created_at: string;
    children: {
      id: number;
      first_name: string;
      last_name: string;
    }[];
  }[];
}

interface IPlacementFormValues {
  child: number;
  voucher: number;
  child_care_need?: string;
  child_care_duration?: string;
  preferred_program_type?: string;
  child_care_transportation_needs?: string;
  provider_name?: string;
  provider_program?: string;

  monday_schedule?: string;
  monday_transportation?: string;
  tuesday_schedule?: string;
  tuesday_transportation?: string;
  wednesday_schedule?: string;
  wednesday_transportation?: string;
  thursday_schedule?: string;
  thursday_transportation?: string;
  friday_schedule?: string;
  friday_transportation?: string;
  saturday_schedule?: string;
  saturday_transportation?: string;
  sunday_schedule?: string;
  sunday_transportation?: string;
}
const getDefaultValues = (initialData?: IProps["initialData"]) => {
  return {
    ...initialData,
    child: initialData?.child.id,
    voucher: initialData?.voucher?.id,
  };
};

export interface AddPlacementFormRef {
  submitForm: () => void;
}

const AddPlacementForm = forwardRef<AddPlacementFormRef, IProps>(
  ({ onSave, familyId, refetchFamilyDetail, initialData, vouchers }, ref) => {
    const { t } = useTranslation();
    const isEditMode = Boolean(initialData);
    const { control, handleSubmit, watch, formState } =
      useForm<IPlacementFormValues>({
        defaultValues: getDefaultValues(initialData),
      });

    const voucher_id = watch("voucher");

    const childOptions =
      vouchers
        .find((voucher) => voucher.id === voucher_id)
        ?.children?.map((child) => {
          let label = `${child.first_name} ${child.last_name}`;
          if (!child.first_name && !child.last_name) {
            label = `Unknown name (ID ${child.id})`;
          }
          return {
            label,
            value: child.id as number,
          };
        }) || [];

    const voucherOptions = vouchers
      .filter((voucher) =>
        ["DTA_REFERRAL", "DCF_REFERRAL", "HOMELESS_REFERRAL"].includes(
          voucher.type
        )
      )
      .map((voucher) => ({
        value: voucher.id,
        label: `${voucher.type} - ${dayjs(voucher.created_at).format("MM/DD/YYYY")}`,
      }));

    const { childCareOptions, transportationNeedsOptions, childCareNeed } =
      useDocumentsOptions();

    const onSubmit: SubmitHandler<IPlacementFormValues> = async (data) => {
      const values = _.omitBy(
        data,
        (obj) => _.isNil(obj) || obj === ""
      ) as IPlacementFormValues;

      try {
        if (initialData) {
          await placementUpdate(familyId, initialData.id, values);
        } else {
          await placementCreate(familyId, values);
        }
        await refetchFamilyDetail();
        onSave();
        return true;
      } catch (error) {
        const errorMessage = extractErrorMessage(error);
        toast.error(errorMessage);
        return false;
      }
    };

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    return (
      <>
        <Controller
          name="voucher"
          control={control}
          rules={{ required: t("fieldRequired") }}
          disabled={isEditMode || voucherOptions.length === 0}
          render={({ field }) => (
            <StyledSelect
              {...field}
              label={t("authorization")}
              options={voucherOptions}
              error={!!formState.errors.voucher || voucherOptions.length === 0}
              helperText={
                formState.errors.voucher?.message ||
                (voucherOptions.length === 0 &&
                  "No Referral Authorizations found!")
              }
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="child"
          control={control}
          rules={{ required: t("fieldRequired") }}
          render={({ field }) => (
            <StyledSelect
              {...field}
              label={t("child")}
              options={childOptions}
              error={!!formState.errors.child}
              helperText={formState.errors.child?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="child_care_need"
          control={control}
          render={({ field }) => (
            <StyledSelect
              {...field}
              label={t("childCareNeed")}
              options={childCareNeed}
              error={!!formState.errors.child_care_need}
              helperText={formState.errors.child_care_need?.message}
              fullWidth
              margin="normal"
            />
          )}
        />
        <Controller
          name="child_care_duration"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("childcareDuration")}
              fullWidth
              margin="normal"
              error={!!formState.errors.child_care_duration}
              helperText={formState.errors.child_care_duration?.message}
            />
          )}
        />
        <Controller
          name="preferred_program_type"
          control={control}
          render={({ field }) => (
            <StyledSelect
              {...field}
              label={t("preferredProgramType")}
              options={childCareOptions}
              fullWidth
              margin="normal"
              error={!!formState.errors.preferred_program_type}
              helperText={formState.errors.preferred_program_type?.message}
            />
          )}
        />
        <Controller
          name="child_care_transportation_needs"
          control={control}
          render={({ field }) => (
            <StyledSelect
              {...field}
              label={t("childcareTransportationNeeds")}
              options={transportationNeedsOptions}
              fullWidth
              margin="normal"
              error={!!formState.errors.child_care_transportation_needs}
              helperText={
                formState.errors.child_care_transportation_needs?.message
              }
            />
          )}
        />
        <Controller
          name="provider_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("providerName")}
              fullWidth
              margin="normal"
              error={!!formState.errors.provider_name}
              helperText={formState.errors.provider_name?.message}
            />
          )}
        />
        <Controller
          name="provider_program"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("providerProgram")}
              fullWidth
              margin="normal"
              error={!!formState.errors.provider_program}
              helperText={formState.errors.provider_program?.message}
            />
          )}
        />
        <Stack sx={{ marginTop: "30px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              mb: "20px",
            }}
          >
            <Text
              variant="labelLarge"
              fontWeight={"600"}
              style={{ paddingRight: "10px" }}
            >
              {t("weeklySchedule")}
            </Text>
            <Box
              sx={{
                flexGrow: 1,
                height: "1px",
                bgcolor: colors.outlineVariant,
              }}
            />
          </Box>
          <WeeklySchedule control={control} formState={formState} />
        </Stack>
      </>
    );
  }
);

export default AddPlacementForm;
