import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";
import Box from "components/Box";

import { URLS } from "config/urls";
import { colors } from "theme";
import { QuestionMarkIcon } from "icons";

const HouseholdDefinitions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");

  const handleNextClick = () => {
    navigate(URLS.FAMILY_INFORMATION_INCOME_VERIFICATION);
  };

  return (
    <>
      <Stack
        alignItems="center"
        alignSelf="center"
        sx={{ maxWidth: matches ? "50%" : "100%" }}
      >
        <QuestionMarkIcon />
        <Text
          variant="titleMedium"
          sx={{
            mt: 2,
            mb: 2,
            color: colors.onSurfaceVariant,
            textAlign: "center",
            maxWidth: matches ? "100%" : "60%",
          }}
        >
          {t("getHelpUnderstandingHouseholdSizeIncome")}
        </Text>
        <Stack spacing={2}>
          <Box
            sx={{
              background: colors.surfaceContainer,
              p: 2,
              borderRadius: 1,
            }}
          >
            <Stack>
              <Text variant="titleMedium" sx={{ mb: 1 }}>
                {t("howIsHouseholdSizeDetermined")}
              </Text>
              <Text variant="bodyMedium">{t("householdSizeDefinition")}</Text>
            </Stack>
          </Box>
          <Box
            sx={{
              background: colors.surfaceContainer,
              p: 2,
              borderRadius: 1,
            }}
          >
            <Stack>
              <Text variant="titleMedium" sx={{ mb: 1 }}>
                {t("howIsHouseholdIncomeDetermined")}
              </Text>
              <Text variant="bodyMedium" sx={{ mb: 1 }}>
                {t("householdIncomeDefinitionPart1")}
              </Text>
              <Text variant="bodyMedium">
                {t("householdIncomeDefinitionPart2")}
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
          marginTop: "30px",
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton label={t("next")} onClick={handleNextClick} />
        </Stack>
      </Stack>
    </>
  );
};

export default HouseholdDefinitions;
