import { styled } from "@mui/system";
import OriginalCheckbox from "@mui/material/Checkbox";

import { colors } from "theme";

const Checkbox = styled(OriginalCheckbox)(() => ({
  "&.Mui-checked": {
    color: colors.primary,
  },
}));

export default Checkbox;
