import React, { createContext, useContext } from "react";
import { IFamilyDetail } from "entities/FamilySpecialist/sdk";

const DataContext = createContext<IFamilyDetail | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export const useData = () => {
  return useContext(DataContext);
};

export const DataProvider: React.FC<{
  value: IFamilyDetail | null;
  children: React.ReactNode;
}> = ({ value, children }) => {
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
