const typographyVariants = {
  displayLarge: {
    fontSize: "57px",
    lineHeight: "64px",
    letterSpacing: "0.25px",
  },
  displayMedium: {
    fontSize: "45px",
    lineHeight: "52px",
    letterSpacing: "0.15px",
  },
  displaySmall: {
    fontSize: "36px",
    lineHeight: "44px",
    letterSpacing: "0px",
  },
  headlineLarge: {
    fontSize: "32px",
    lineHeight: "40px",
    letterSpacing: "0px",
    fontWeight: 500,
  },
  headlineMedium: {
    fontSize: "28px",
    lineHeight: "36px",
    letterSpacing: "0px",
    fontWeight: 500,
  },
  headlineSmall: {
    fontSize: "24px",
    lineHeight: "30px",
    letterSpacing: "0px",
    fontWeight: 500,
  },
  titleLarge: {
    fontSize: "22px",
    lineHeight: "28px",
    letterSpacing: "0px",
    fontWeight: 500,
  },
  titleMedium: {
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.15px",
    fontWeight: 500,
  },
  titleSmall: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
    fontWeight: 500,
  },
  labelLarge: {
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.1px",
  },
  labelMedium: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.15px",
  },
  labelSmall: {
    fontSize: "11px",
    lineHeight: "16px",
    letterSpacing: "0.25px",
  },
  bodyLarge: {
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0px",
  },
  bodyMedium: {
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.1px",
  },
  bodySmall: {
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.15px",
  },
};

export default typographyVariants;
