import { useForm, Controller, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import Checkbox from "components/Checkbox";
import BasicButton from "components/BasicButton";
import Divider from "components/Divider";
import FormControlLabel from "components/FormControlLabel";
import Stack from "components/Stack";
import StyledSelect from "components/StyledSelect";
import TextField from "components/TextField";
import POWERFULSelect from "components/POWERFULSelect";

import DCFReferral from "entities/FamilyEdit/components/AddAuthorizationForm/components/DCFReferral";
import HomelessReferral from "entities/FamilyEdit/components/AddAuthorizationForm/components/HomelessReferral";
import DTAReferral from "entities/FamilyEdit/components/AddAuthorizationForm/components/DTAReferral";

import { useStatusOptions, useTypeOptions } from "entities/FamilyEdit/sdk";
import { useMyFamily, IFamily } from "entities/Family/sdk";
import { colors } from "theme";
import { useFormWarnings } from "utils/forms";

interface IInitialValues {
  children: IFamily["vouchers"][number]["children"];
  created_at: string;
  referral_source: string;
  type: IFamily["vouchers"][number]["type"];
  status: IFamily["vouchers"][number]["status"];
  family_has_experienced_domestic_violence: boolean;
  family_is_experiencing_homelessness: boolean;
  parent_has_a_disability: boolean;
  parent_is_a_member_of_the_us_military: boolean;
  parent_is_an_early_educator: boolean;
  parent_is_receiving_treatment_for_substance_abuse: boolean;
  teen_parent: boolean;
  child_only_needs_coverage_during_school_closures: boolean;
  proof_of_identification_verified: boolean;
  is_on_waitlist: boolean;
  referral_date: string;
  child_care_status: string;
  shelter: string;
  shelter_contact: string;
  shelter_contact_email: string;
  shelter_phone: string;
  dta_referral_number: string;
  ccrr: string;
  dta_program: string;
  service_need: string;
  dcf_case_number: string;
  dcf_authorization_number: string;
  dcf_region: string;
  issuing_location: string;
  tafdc_closing_date: string;
  dta_referral_type: string;
}

interface ISubmitValues extends Omit<IInitialValues, "children"> {
  children: string[];
}

interface IProps {
  onSave: (data: Partial<ISubmitValues>) => Promise<void>;
  fieldsWithWarnings?: string[];
  initialValues?: IInitialValues;
}

export interface AuthorizationFormRef {
  submitForm: () => void;
}

const AuthorizationForm = ({
  onSave,
  fieldsWithWarnings,
  initialValues,
}: IProps) => {
  const { t } = useTranslation();

  const formMethods = useForm<Partial<ISubmitValues>>({
    defaultValues: {
      ...initialValues,
      children: initialValues?.children?.map((child) => String(child.id)),
      created_at: initialValues?.created_at
        ? dayjs(initialValues?.created_at).format("MM/DD/YYYY")
        : undefined,
      referral_date: initialValues?.referral_date
        ? dayjs(initialValues?.referral_date, "YYYY-MM-DD").format("MM/DD/YYYY")
        : undefined,
    },
  });
  const { setError, handleSubmit, control, formState, watch } = formMethods;
  const { fieldWarningsAndErrors } = useFormWarnings({
    formState,
    fieldsWithWarnings,
  });
  const typeOptions = useTypeOptions();
  const statusOptions = useStatusOptions();
  const type = watch("type");

  const onSubmit = (values: Partial<ISubmitValues>) => {
    const data = {
      ...values,
      referral_date: values.referral_date
        ? dayjs(values.referral_date, "MM/DD/YYYY").format("YYYY-MM-DD")
        : undefined,
    };

    onSave(data).catch(
      (errors) => errors?.setFormErrors && errors.setFormErrors(setError)
    );
  };
  const { data: family } = useMyFamily();
  const children = family?.children || [];

  const childrenOptions = children.map((child) => {
    let label = `${child.first_name} ${child.last_name}`;

    if (!child.first_name && !child.last_name) {
      label = `Unknown Name (ID: ${child.id})`;
    }

    return {
      label,
      value: child.id?.toString() as string,
    };
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ padding: 2 }}>
          <Controller
            name="type"
            control={control}
            rules={{ required: t("fieldRequired") }}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("type")}
                options={typeOptions}
                fullWidth
                margin="normal"
                {...fieldWarningsAndErrors(field)}
                disabled={!!initialValues}
              />
            )}
          />

          <Controller
            name="children"
            control={control}
            render={({ field }) => (
              <POWERFULSelect
                multiple
                value={field.value || []}
                onChange={field.onChange}
                label={t("childrenAuthorized")}
                {...fieldWarningsAndErrors(field)}
                options={childrenOptions}
              />
            )}
          />
          {initialValues && initialValues.type === "WAITLIST" && (
            <Controller
              name="status"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  label={t("status")}
                  options={statusOptions}
                  fullWidth
                  margin="normal"
                  {...fieldWarningsAndErrors(field)}
                  disabled
                />
              )}
            />
          )}
          {initialValues?.created_at && initialValues.type === "WAITLIST" && (
            <Controller
              name="created_at"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("createdAt")}
                  fullWidth
                  margin="normal"
                  {...fieldWarningsAndErrors(field)}
                  disabled
                />
              )}
            />
          )}
          {type === "WAITLIST" && (
            <Controller
              name="is_on_waitlist"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t("isOnWaitlist")}
                />
              )}
            />
          )}
          {type === "WAITLIST" && (
            <>
              <Controller
                name="family_has_experienced_domestic_violence"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("domesticViolence")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="family_is_experiencing_homelessness"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("homelessness")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="parent_has_a_disability"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("parentDisability")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="parent_is_a_member_of_the_us_military"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("militaryMember")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="parent_is_an_early_educator"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("earlyEducator")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="parent_is_receiving_treatment_for_substance_abuse"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("substanceAbuseTreatment")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="teen_parent"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("teenParent")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="child_only_needs_coverage_during_school_closures"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("schoolClosureCoverage")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
            </>
          )}
          {type === "DTA_REFERRAL" && (
            <DTAReferral fieldsWithWarnings={fieldsWithWarnings} />
          )}
          {type === "DCF_REFERRAL" && (
            <DCFReferral fieldsWithWarnings={fieldsWithWarnings} />
          )}
          {type === "HOMELESS_REFERRAL" && (
            <HomelessReferral fieldsWithWarnings={fieldsWithWarnings} />
          )}
          {(type === "DTA_REFERRAL" ||
            type === "DCF_REFERRAL" ||
            type === "HOMELESS_REFERRAL") && (
            <Controller
              name="proof_of_identification_verified"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t("proofOfIdentificationVerified")}
                />
              )}
            />
          )}
        </Stack>
        <Stack
          sx={{
            width: "100%",
            borderTop: "1px solid " + colors.outline,
            alignItems: "center",
          }}
        >
          <Stack sx={{ mt: "30px", width: "100%" }} alignItems="center">
            <BasicButton buttonType="submit" label={t("save")} />
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default AuthorizationForm;
