export const extractErrorMessage = (error: any): string => {
  if (typeof error === "string") {
    return error;
  }

  if (error.non_field_errors && error.non_field_errors.length) {
    return error.non_field_errors[0];
  }

  for (const key in error) {
    if (error.hasOwnProperty(key)) {
      const subError = error[key];
      if (Array.isArray(subError)) {
        for (let item of subError) {
          const message = extractErrorMessage(item);
          if (message) return message;
        }
      } else if (typeof subError === "object") {
        const message = extractErrorMessage(subError);
        if (message) return message;
      }
    }
  }

  return "";
};
