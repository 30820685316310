import { BASE_URL } from "config/urls";
import { ISender } from "entities/FamilySpecialist/sdk";
import { post, useFetch } from "utils/sdk";

export interface ICommunicationPhoneContactMethod {
  id: number;
  phone_number: string;
  is_active: boolean;
}

export interface ICommunicationEmailContactMethod {
  id: number;
  email: string;
  is_active: boolean;
}

export interface ICreator {
  first_name: string;
  last_name: string;
  phone_contact_methods: ICommunicationPhoneContactMethod[];
  email_contact_methods: ICommunicationEmailContactMethod[];
}

export interface IFamilyContactMethods {
  id: number;
  creator: ICreator;
}

export interface ICommunicationsTemplates {
  id: number;
  name: string;
  communication_type: string;
}

export interface ICommunicationTemplateReponse {
  subject: string;
  body: string;
}

export interface IMessageCreate {
  family: number;
  template?: number;
  subject?: string;
  body?: string;
  communication_type: string;
  files: number[];
  phone_contact_methods: number[];
  email_contact_methods: number[];
}

interface IMessageListCreator {
  id: number;
  first_name: string;
  last_name: string;
}
interface IFamily {
  id: number;
  creator: IMessageListCreator;
}
export interface IMessagesList {
  id: number;
  subject: string;
  body: string;
  communication_type: string;
  created_at: string;
  sent_from: ISender;
  family: IFamily;
}

export interface IMeesageListPagination {
  count: number;
  next: string;
  previous: string;
  limit: number;
  offset: number;
  results: IMessagesList[];
}

export interface IFamilyContactMethodsPagination {
  count: number;
  next: string;
  previous: string;
  limit: number;
  offset: number;
  results: IFamilyContactMethods[];
}

export const useFamilyContactMethods = (queryParams: {
  search?: string;
  family_id?: number;
}) => {
  return useFetch<IFamilyContactMethodsPagination>(
    `/api/family-specialists/communications/family-contact-methods/`,
    queryParams
  );
};

export const useCommunicationsTemplates = () => {
  return useFetch<ICommunicationsTemplates[]>(
    `/api/family-specialists/communications/templates/`
  );
};

export const communicationTemplateRender = (
  template_id: number,
  data: { family: number }
) =>
  post<ICommunicationTemplateReponse>(
    `${BASE_URL}/api/family-specialists/communications/templates/${template_id}/render/`,
    data
  );

export const messageCreate = (data: IMessageCreate) =>
  post(
    `${BASE_URL}/api/family-specialists/communications/message/create/`,
    data
  );

export const useMessagesList = (limit: number, offset: number) => {
  return useFetch<IMeesageListPagination>(
    `/api/family-specialists/communications/message/list/`,
    { limit, offset }
  );
};
