import { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";

import { DatePicker as DefaultDatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

interface IProps {
  onChange: (newDate: string) => void;
  label: string;
  initialValue?: string | null;
}

const DatePicker = ({ onChange, label, initialValue }: IProps) => {
  const [value, setValue] = useState<Dayjs | null>(
    initialValue ? dayjs(initialValue) : null
  );

  useEffect(() => {
    if (initialValue) {
      setValue(dayjs(initialValue));
    }
  }, [initialValue]);

  const handleDateChange = (newValue: Dayjs | null) => {
    setValue(newValue);
    if (newValue) {
      const formattedDate = newValue.format("YYYY-MM-DD");
      onChange(formattedDate);
    } else {
      onChange("");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DefaultDatePicker
        value={value}
        onChange={handleDateChange}
        label={label}
        format="MM/DD/YYYY"
        sx={{ width: "100%" }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
