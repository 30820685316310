import { useTranslation } from "react-i18next";

import AddButton from "components/AddButton";
import BasicButton from "components/BasicButton";
import Stack from "components/Stack";
import Text from "components/Text";
import { ChatIcon, MailIcon, SuitcaseIcon } from "icons";

import { colors } from "theme";

const FamilySpecialistHome = () => {
  const { t } = useTranslation();
  return (
    <Stack
      justifyContent="flex-start"
      gap="20px"
      sx={{ height: "calc(100vh - 60px)" }}
    >
      <Stack sx={{ backgroundColor: colors.surface, padding: "15px 30px" }}>
        <Stack sx={{ mb: "10px" }}>
          <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
            {t("actionNeeded")}
          </Text>
        </Stack>
        <Stack
          direction="row"
          gap="15px"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderTop: "1px solid",
            borderColor: colors.outline,
            py: "10px",
          }}
        >
          <Stack direction="row" gap="20px" alignItems="center">
            <SuitcaseIcon />
            <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
              Ryan Knight
            </Text>
            <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
              {t("newCase")}
            </Text>
          </Stack>
          <AddButton label={t("addToWaitlist")} />
        </Stack>
        <Stack
          direction="row"
          gap="15px"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderTop: "1px solid",
            borderColor: colors.outline,
            py: "10px",
          }}
        >
          <Stack direction="row" gap="20px" alignItems="center">
            <SuitcaseIcon />

            <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
              Casey Choe
            </Text>
            <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
              {t("addedDocuments")}
            </Text>
          </Stack>
          <BasicButton
            label={t("reviewDocuments")}
            backgroundColor={colors.secondaryContainer}
            color={colors.onSecondaryContainer}
            onHoverBackground={colors.onHoverSecondaryContainer}
          />
        </Stack>
      </Stack>
      <Stack sx={{ backgroundColor: colors.surface, padding: "15px 30px" }}>
        <Stack sx={{ mb: "10px" }}>
          <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
            {t("recentMessages")}
          </Text>
        </Stack>
        <Stack
          direction="row"
          gap="15px"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderTop: "1px solid",
            borderColor: colors.outline,
            py: "10px",
          }}
        >
          <Stack direction="row" gap="20px" alignItems="center">
            <MailIcon />
            <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
              Ryan Knight
            </Text>
            <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
              {t("submittedApplication")}
            </Text>
          </Stack>
          <Text variant="titleSmall" fontStyle="italic">
            05/12/2024 - 10:30AM
          </Text>
        </Stack>
        <Stack
          direction="row"
          gap="15px"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            borderTop: "1px solid",
            borderColor: colors.outline,
            py: "10px",
          }}
        >
          <Stack direction="row" gap="20px" alignItems="center">
            <ChatIcon />
            <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
              Casey Choe
            </Text>
            <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
              {t("reviewDocuments")}
            </Text>
          </Stack>
          <Text variant="titleSmall" fontStyle="italic">
            05/12/2024 - 10:30AM
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FamilySpecialistHome;
