import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import BasicButton from "components/BasicButton";
import Text from "components/Text";
import Stack from "components/Stack";
import TextField from "components/TextField";
import FormHelperText from "components/FormHelperText";
import useMediaQuery from "components/useMediaQuery";

// import { MicIcon } from "icons";

// import { colors } from "theme";

import { URLS } from "config/urls";

const GetHelpPage = () => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      needHelp: "",
    },
  });
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");

  const watchData = watch("needHelp");

  const onSubmit = () => {
    if (!watchData) {
      setError("needHelp", {
        type: "manual",
        message: t("fieldRequired"),
      });
      return;
    }

    navigate(URLS.THANK_YOU);
  };

  return (
    <Stack
      flexDirection={"column"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{
        minHeight: `calc(100vh - 140px)`,
      }}
    >
      <Stack
        gap="20px"
        alignItems={"flex-start"}
        sx={{ width: matches ? "50%" : "100%" }}
      >
        <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
          {t("describeNeeds")}
        </Text>
        <Controller
          name="needHelp"
          control={control}
          render={({ field }) => (
            <>
              <TextField
                {...field}
                fullWidth
                multiline
                minRows={3}
                variant="outlined"
                label={t("describeWhatYouNeddHelpWIth")}
                // InputProps={{
                //   endAdornment: <MicIcon color="primary" />,
                // }}
                sx={{ mb: 0 }}
                error={!!errors.needHelp}
              />
              {!!errors.needHelp && (
                <FormHelperText error>{errors.needHelp.message}</FormHelperText>
              )}
            </>
          )}
        />
        {/* <Text
          variant="labelMedium"
          sx={{ pl: "15px", color: colors.onSurfaceVariant }}
        >
          {t("textOrAudio")}
        </Text> */}
      </Stack>
      <Stack
        gap="20px"
        alignItems="center"
        sx={{
          textAlign: "right",
          mt: "30px",
        }}
      >
        <BasicButton
          label={t("sendToChildCareCircuit")}
          onClick={handleSubmit(onSubmit)}
        />
      </Stack>
    </Stack>
  );
};

export default GetHelpPage;
