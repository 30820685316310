import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import AddButton from "components/AddButton";
import Stack from "components/Stack";
import EditPanel from "../index";
import { useData } from "../DataContext";
import PlacementItem from "components/PlacementItem";
import AddPlacementForm, { AddPlacementFormRef } from "../AddPlacementForm";
import { IPlacement } from "entities/FamilyEdit/sdk";
import { IVoucher } from "entities/FamilySpecialist/sdk";
import _ from "lodash";

interface IProps {
  refetchFamilyDetail: () => void;
}

interface IPlacementWithVoucher extends IPlacement {
  voucher: IVoucher;
}

const PlacementList = ({ refetchFamilyDetail }: IProps) => {
  const { t } = useTranslation();
  const familyData = useData();
  const [isAdding, setIsAdding] = useState(false);
  const [selectedPlacement, setSelectedPlacement] =
    useState<IPlacementWithVoucher | null>(null);
  const addPlacementFormRef = useRef<AddPlacementFormRef>(null);

  const handleAddPlacementClick = () => {
    setSelectedPlacement(null);
    setIsAdding(true);
  };

  const handleSavePlacement = () => {
    setIsAdding(false);
    refetchFamilyDetail();
  };

  const handleEditPlacement = (placement: IPlacementWithVoucher) => {
    setSelectedPlacement(placement);
    setIsAdding(true);
  };

  const handleSaveClick = () => {
    if (addPlacementFormRef.current) {
      addPlacementFormRef.current.submitForm();
    }
  };

  if (familyData === null) {
    return null;
  }

  const allPlacements: IPlacementWithVoucher[] = _.orderBy(
    familyData.vouchers
      .map((voucher) =>
        voucher.placements.map((placement) => ({
          ...placement,
          voucher: voucher,
        }))
      )
      .flat(),
    "id",
    "desc"
  );

  return (
    <Stack spacing={2}>
      <EditPanel
        open={isAdding}
        onClose={() => setIsAdding(false)}
        title={t(selectedPlacement ? "editPlacement" : "addPlacement")}
        onSaveClick={handleSaveClick}
      >
        <AddPlacementForm
          onSave={handleSavePlacement}
          initialData={selectedPlacement}
          ref={addPlacementFormRef}
          familyId={familyData.id}
          refetchFamilyDetail={refetchFamilyDetail}
          vouchers={familyData.vouchers}
        />
      </EditPanel>
      {allPlacements.map((placement) => (
        <PlacementItem
          key={placement.id}
          voucher={placement.voucher}
          placement={placement}
          onEdit={() => handleEditPlacement(placement)}
        />
      ))}
      <Stack alignItems="center" style={{ marginBottom: "20px" }}>
        <AddButton
          label={t("addPlacement")}
          onClick={handleAddPlacementClick}
        />
      </Stack>
    </Stack>
  );
};

export default PlacementList;
