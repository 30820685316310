import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";

import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";
import Text from "components/Text";
import Divider from "components/Divider";

import {
  ISpecialCircumstances,
  specialCircumstanceUpdate,
} from "entities/FamilyMember/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

import { colors } from "theme";
import { familySpecialistSpecialCircumstanceUpdate } from "entities/FamilyEdit/sdk";
import { toast } from "react-toastify";

interface IProps {
  specialCircumstances?: ISpecialCircumstances;
}

const SpecialCircumstance = ({ specialCircumstances }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");

  const { familyId: familyIdParam } = useParams<{ familyId: string }>();
  const familyId = familyIdParam ? parseInt(familyIdParam) : undefined;

  const { control, handleSubmit } = useForm<ISpecialCircumstances>({
    defaultValues: {
      family_has_experienced_domestic_violence:
        specialCircumstances?.family_has_experienced_domestic_violence || false,
      family_is_experiencing_homelessness:
        specialCircumstances?.family_is_experiencing_homelessness || false,
      parent_has_a_disability:
        specialCircumstances?.parent_has_a_disability || false,
      parent_is_a_member_of_the_us_military:
        specialCircumstances?.parent_is_a_member_of_the_us_military || false,
      parent_is_an_early_educator:
        specialCircumstances?.parent_is_an_early_educator || false,
      parent_is_receiving_treatment_for_substance_abuse:
        specialCircumstances?.parent_is_receiving_treatment_for_substance_abuse ||
        false,
      teen_parent: specialCircumstances?.teen_parent || false,
      child_only_needs_coverage_during_school_closures:
        specialCircumstances?.child_only_needs_coverage_during_school_closures ||
        false,
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data: ISpecialCircumstances) => {
    setIsSubmitting(true);

    const specialCircumstancesData = {
      family_has_experienced_domestic_violence:
        data.family_has_experienced_domestic_violence,
      family_is_experiencing_homelessness:
        data.family_is_experiencing_homelessness,
      parent_has_a_disability: data.parent_has_a_disability,
      parent_is_a_member_of_the_us_military:
        data.parent_is_a_member_of_the_us_military,
      parent_is_an_early_educator: data.parent_is_an_early_educator,
      parent_is_receiving_treatment_for_substance_abuse:
        data.parent_is_receiving_treatment_for_substance_abuse,
      teen_parent: data.teen_parent,
      child_only_needs_coverage_during_school_closures:
        data.child_only_needs_coverage_during_school_closures,
    };
    try {
      if (familyId) {
        await familySpecialistSpecialCircumstanceUpdate(
          familyId,
          specialCircumstancesData
        );
        navigate(
          reverse(URLS.FAMILY_SPECIALIST_ADDITIONAL_INFORMATION, { familyId })
        );
      } else {
        await specialCircumstanceUpdate(specialCircumstancesData);
        navigate(URLS.FAMILY_INFORMATION_ADDITIONAL_INFORMATION);
      }
    } catch (error: any) {
      toast.error(t("errorSavingData") + (error.response?.data?.message || ""));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Stack alignItems={"center"} sx={{ height: "calc(100vh - 180px)" }}>
        <Text variant="titleMedium" sx={{ my: 3 }}>
          {t("priorityNotice")}
        </Text>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: "100%", height: "100%" }}
        >
          <Stack
            sx={{ height: "100%" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack sx={{ width: matches ? "400px" : "100%" }}>
              <Controller
                name="family_has_experienced_domestic_violence"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("domesticViolence")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="family_is_experiencing_homelessness"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("homelessness")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="parent_has_a_disability"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("parentDisability")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="parent_is_a_member_of_the_us_military"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("militaryMember")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="parent_is_an_early_educator"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("earlyEducator")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="parent_is_receiving_treatment_for_substance_abuse"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("substanceAbuseTreatment")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="teen_parent"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("teenParent")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
              <Controller
                name="child_only_needs_coverage_during_school_closures"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label={t("schoolClosureCoverage")}
                  />
                )}
              />
              <Divider sx={{ my: "10px" }} />
            </Stack>
            <Stack
              alignItems={matches ? "center" : "flex-end"}
              sx={{
                width: "100%",
                borderTop: matches ? "1px solid" : "none",
                borderColor: colors.outline,
              }}
            >
              <Stack sx={{ mt: "30px", width: "100%" }} alignItems="center">
                <BasicButton
                  buttonType="submit"
                  label={t("next")}
                  isDisabled={isSubmitting}
                />
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default SpecialCircumstance;
