import { useNavigate } from "react-router-dom";

import ChildForm from "entities/Child/components/Form";

import { childCreateInUserFamily, IChild } from "entities/Child/sdk";

import { URLS } from "config/urls";

const ChildAddPage = () => {
  const navigate = useNavigate();

  const handleSave = (values: Partial<IChild>) =>
    childCreateInUserFamily({ data: values }).then(() => {
      navigate(URLS.FAMILY_INFORMATION_CHILDREN);
    });

  return <ChildForm onSave={handleSave} />;
};

export default ChildAddPage;
