import React, { useCallback } from "react";
import { SelectProps } from "@mui/material/Select";
import _ from "lodash";

import { MenuItem } from "components/Menu";
import TextField, { ITextField } from "components/TextField";
import Checkbox from "components/Checkbox";

type BaseSelectProperties = Omit<ITextField, "variant" | "onChange"> & {
  placeholder?: string;
  label?: string;
  options: { value: string; label: string; meta?: any }[];
  selectProps?: Omit<SelectProps, "variant">;
  dropdownOptionsDataTestid?: string;
  renderOption?: (
    option: {
      value: string;
      label: string;
      meta?: any;
    },
    isSelected: boolean
  ) => React.ReactNode;
};

interface MultipleSelect extends BaseSelectProperties {
  multiple: true;
  value: string[];
  defaultValue?: string[];
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string[]
  ) => void;
}

interface SingleSelect extends BaseSelectProperties {
  multiple?: false;
  value: string;
  defaultValue?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
}

export type ISelect = MultipleSelect | SingleSelect;

const defaultRenderOption = (
  option: ISelect["options"][number],
  isSelected: boolean
) => (
  <span>
    <Checkbox checked={isSelected} />
    {option.label}
  </span>
);

const renderValuesLabels = (values: string[], options: ISelect["options"]) => {
  return values
    .map(
      (selectedValue) =>
        _.find(options, (opt) => opt.value === selectedValue)?.label
    )
    .join(", ");
};

const Select = ({
  label,
  options,
  onChange,
  placeholder,
  value,
  defaultValue,
  renderOption = defaultRenderOption,
  dropdownOptionsDataTestid,
  multiple,
  selectProps,
  ...restProps
}: ISelect) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!onChange) return;
      if (multiple) {
        return onChange(event, event.target.value as unknown as string[]);
      }
      return onChange(event, event.target.value);
    },
    [onChange]
  );

  return (
    <TextField
      select
      label={label}
      SelectProps={{
        ...selectProps,
        multiple,
        renderValue: (value) => (
          <>
            {_.isArray(value)
              ? renderValuesLabels(value, options)
              : _.find(options, (opt) => opt.value === value)?.label}
          </>
        ),
      }}
      InputLabelProps={{ shrink: true }}
      onChange={handleChange}
      defaultValue={defaultValue}
      value={value}
      {...restProps}
    >
      {placeholder && (
        <MenuItem disabled value=" " selected>
          {placeholder}
        </MenuItem>
      )}
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          data-testid={dropdownOptionsDataTestid}
          disabled={option.meta?.disabled}
          sx={{ whiteSpace: "normal" }}
        >
          {renderOption(
            option,
            multiple ? value.indexOf(option.value) >= 0 : value == option.value
          )}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Select;
