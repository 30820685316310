import _ from "lodash";

/**
 * Allows Partial<> to be applied recursively onto nested types of T
 */
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

/**
 *
 *
 */
const flattenObject = (obj: Record<string, any>): Record<string, any> => {
  return Object.keys(obj).reduce((prev, key) => {
    if (
      typeof obj[key] === "string" ||
      typeof obj[key] === "number" ||
      typeof obj[key] === "boolean" ||
      obj[key] === null
    ) {
      return { ...prev, [key]: obj[key] };
    }
    return Object.keys(obj[key]).reduce(
      (innerPrev, innerKey) => ({
        ...innerPrev,
        [`${key}.${innerKey}`]: obj[key][innerKey],
      }),
      prev
    );
  }, {});
};

/**
 *
 *
 */
export const flattenObjectDeep = (
  obj: Record<string, any>,
  endCondition: (obj: Record<string, any>) => boolean
): Record<string, any> => {
  if (endCondition(obj)) {
    return obj;
  }
  return flattenObjectDeep(flattenObject(obj), endCondition);
};

const objectKeys = <Key extends string, V>(obj: Record<Key, V>): Key[] => {
  return Object.keys(obj) as Key[];
};

export const isArrayOfStrings = (obj: Record<string, any>) => {
  return (
    objectKeys(obj).filter(
      (key) =>
        (_.isArray(obj[key]) && obj[key].length === 0) ||
        (_.isArray(obj[key]) &&
          obj[key].length > 0 &&
          typeof obj[key][0] === "string")
    ).length > 0
  );
};

export const capitalCaseToCamelCase = (str: string): string => {
  const words = str.toLowerCase().split("_");
  return words
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join("");
};

export const snakeCaseToCapitalisedText = (str: string) => {
  const words = str.toLowerCase().split("_");
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
