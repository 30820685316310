import { Controller, Control, FieldValues, Path } from "react-hook-form";

import TextField from "components/TextField";

const formatDateString = (value: string) => {
  const cleanValue = value.replace(/\D/g, "");
  if (cleanValue.length <= 2) return cleanValue;
  if (cleanValue.length <= 4)
    return `${cleanValue.slice(0, 2)}/${cleanValue.slice(2)}`;
  return `${cleanValue.slice(0, 2)}/${cleanValue.slice(2, 4)}/${cleanValue.slice(4, 8)}`;
};

interface DateInputProps<T extends FieldValues> {
  control: Control<T>;
  label: string;
  name: Path<T>;
  error?: boolean;
  helperText?: string;
  required?: boolean;
}

const DateInput = <T extends FieldValues>({
  control,
  label,
  name,
  error,
  helperText,
  required = true,
  ...props
}: DateInputProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required ? "Date of Birth is required" : false,
        pattern: {
          value: /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/,
          message: "Invalid date format. Use MM/DD/YYYY",
        },
      }}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error: fieldError },
      }) => (
        <TextField
          {...props}
          label={label}
          value={value || ""}
          onChange={(e) => onChange(formatDateString(e.target.value))}
          onBlur={onBlur}
          placeholder="MM/DD/YYYY"
          fullWidth
          margin="normal"
          error={error || !!fieldError}
          helperText={
            helperText ||
            (fieldError ? fieldError.message : "Format: MM/DD/YYYY")
          }
          inputRef={ref}
        />
      )}
    />
  );
};

export default DateInput;
