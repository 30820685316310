import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";

import { useDetailDocumentData } from "entities/Documents/sdk";

import DTAReferralAuthorization from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/DTAReferralAuthorization";
import DCFReferralAuthorization from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/DCFReferralAuthorization";
import HomelessReferralAuthorization from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/components/HomelessReferralAuthorization";

const DocumentContainerAuthorizationTypeEdit = () => {
  const { t } = useTranslation();
  const { documentId: documentIdParam } = useParams<{
    documentId: string;
  }>();
  const documentId = parseInt(String(documentIdParam));
  const { data } = useDetailDocumentData(documentId);

  const familyId = data?.family_data.id;

  if (!data || !familyId) {
    return null;
  }

  return (
    <>
      <Stack sx={{ mb: "30px" }} gap="15px">
        <Text variant="titleMedium">{t("authorization")}</Text>
        <Divider />
      </Stack>
      <Stack sx={{ flex: 1, justifyContent: "space-between" }}>
        {data.document_type === "DTA_REFERRAL" && (
          <DTAReferralAuthorization data={data} documentId={documentId} />
        )}
        {data.document_type === "DCF_REFERRAL" && (
          <DCFReferralAuthorization data={data} documentId={documentId} />
        )}
        {data.document_type === "HOMELESS_REFERRAL" && (
          <HomelessReferralAuthorization data={data} documentId={documentId} />
        )}
      </Stack>
    </>
  );
};

export default DocumentContainerAuthorizationTypeEdit;
