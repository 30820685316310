import { post } from "utils/sdk";
import { BASE_URL } from "config/urls";
import { IDetailDocumentData } from "entities/Documents/sdk";

export const parsedChildUpdate = ({
  parsedChildId,
  data,
}: {
  parsedChildId: number;
  data: Partial<IDetailDocumentData["family_data"]["children_data"][number]>;
}) =>
  post(
    `${BASE_URL}/api/parsed-documents/parsed-child/${parsedChildId}/update/`,
    data
  );

export const parsedChildCreate = ({
  parsedFamilyId,
  data,
}: {
  parsedFamilyId: number;
  data: Partial<IDetailDocumentData["family_data"]["children_data"][number]>;
}) =>
  post(
    `${BASE_URL}/api/parsed-documents/parsed-family/${parsedFamilyId}/child-create/`,
    data
  );
