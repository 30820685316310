import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Stack from "components/Stack";
import StyledSelect from "components/StyledSelect";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";
import TextField from "components/TextField";
import WeeklySchedule from "components/WeeklySchedule";
import Box from "components/Box";
import Text from "components/Text";

import {
  IDetailDocumentData,
  useDocumentsOptions,
} from "entities/Documents/sdk";
import { colors } from "theme";

interface IProps {
  initialData?: IDetailDocumentData["family_data"]["voucher_data"]["placements"][number];
  childrenOptions: { id: number; first_name: string; last_name: string }[];
  onCancel: () => void;
  onSave: (values: any) => Promise<any>;
}

const ParsedPlacementForm = ({
  initialData,
  childrenOptions,
  onCancel,
  onSave,
}: IProps) => {
  const { t } = useTranslation();
  const { childCareOptions, transportationNeedsOptions, childCareNeed } =
    useDocumentsOptions();
  const matches = useMediaQuery("(min-width:600px)");
  const { control, getValues, formState, setError } = useForm({
    defaultValues: {
      child: initialData?.child.id,
      child_care_need: initialData?.child_care_need,
      child_care_duration: initialData?.child_care_duration,
      preferred_program_type: initialData?.preferred_program_type,
      child_care_transportation_needs:
        initialData?.child_care_transportation_needs,
      provider_name: initialData?.provider_name,
      provider_program: initialData?.provider_program,
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const childOptions = (childrenOptions ?? []).map((child) => {
    let label = `${child.first_name} ${child.last_name}`;
    // Sometimes there's no known names for the child, so this makes the label go blank
    if (label == " ") {
      label = "Unknown Name";
    }
    return {
      label,
      value: child.id?.toString(),
    };
  });

  const handleSave = () => {
    const values = getValues();
    setIsSubmitting(true);
    onSave(values)
      .catch((errors) => {
        errors.setFormErrors && errors.setFormErrors(setError);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <form>
        <Stack spacing={2}>
          <Controller
            name="child"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("child")}
                options={childOptions}
                fullWidth
                margin="normal"
                error={!!formState.errors.child}
                helperText={formState.errors.child?.message}
              />
            )}
          />
          <Controller
            name="child_care_need"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("childCareNeed")}
                options={childCareNeed}
              />
            )}
          />
          <Controller
            name="child_care_duration"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("childcareDuration")} />
            )}
          />
          <Controller
            name="preferred_program_type"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("preferredProgramType")}
                options={childCareOptions}
              />
            )}
          />
          <Controller
            name="child_care_transportation_needs"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("childcareTransportationNeeds")}
                options={transportationNeedsOptions}
              />
            )}
          />
          <Controller
            name="provider_name"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("providerName")} />
            )}
          />
          <Controller
            name="provider_program"
            control={control}
            render={({ field }) => (
              <TextField {...field} label={t("providerProgram")} />
            )}
          />
        </Stack>
      </form>
      <Stack sx={{ marginTop: "30px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            mb: "20px",
          }}
        >
          <Text
            variant="labelLarge"
            fontWeight={"600"}
            style={{ paddingRight: "10px" }}
          >
            {t("weeklySchedule")}
          </Text>
          <Box
            sx={{ flexGrow: 1, height: "1px", bgcolor: colors.outlineVariant }}
          />
        </Box>
        <WeeklySchedule control={control} formState={formState} />
      </Stack>

      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton
            label={t("back")}
            onClick={onCancel}
            isDisabled={isSubmitting}
          />
          <BasicButton
            label={t("save")}
            onClick={handleSave}
            isDisabled={isSubmitting}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default ParsedPlacementForm;
