import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";

import ParsedParentAddForm from "entities/ParsedParent/components/Form";

import { useDetailDocumentData } from "entities/Documents/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const DocumentsContainerParentAdd = () => {
  const { t } = useTranslation();
  const { documentId: documentIdParam } = useParams<{
    documentId: string;
  }>();
  const documentId = parseInt(String(documentIdParam));
  const navigate = useNavigate();

  const { data } = useDetailDocumentData(documentId);
  const parsedFamilyId = data?.family_data.id;

  if (!parsedFamilyId) {
    return null;
  }

  return (
    <>
      <Stack sx={{ mb: "30px" }} gap="15px">
        <Text variant="titleMedium">{t("parentStatus")}</Text>
        <Divider />
      </Stack>
      <ParsedParentAddForm
        setStepname={() =>
          navigate(
            reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS, {
              documentId: String(documentId),
              stepname: "parents",
            })
          )
        }
        documentId={documentId}
        parsedFamilyId={parsedFamilyId}
      />
    </>
  );
};

export default DocumentsContainerParentAdd;
