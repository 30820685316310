import axios from "axios";
import { BASE_URL, getConfig } from "utils/sdk";

export const filesUpload = (data: { file: File }) => {
  const postData = new FormData();
  postData.append("file", data.file);

  let postParams = getConfig({ isFileUpload: true });
  return axios.post<{ id: number }>(
    `${BASE_URL}/api/files/upload/standard/`,
    postData,
    postParams
  );
};
