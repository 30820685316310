import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FullPageDialog from "components/FullPageDialog";

import ChildForm from "entities/Child/components/Form";

import { childCreateInUserFamily, IChild } from "entities/Child/sdk";

import { URLS } from "config/urls";

const ChildAddPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSave = (values: Partial<IChild>) =>
    childCreateInUserFamily({ data: values }).then(() => {
      navigate(URLS.MISSING_INFORMATION_CHILDREN);
    });

  return (
    <FullPageDialog
      title={t("childInformation")}
      progressBarPercentage={30}
      backUrl={URLS.MISSING_INFORMATION_CHILDREN}
      closeUrl={URLS.HOME}
    >
      <ChildForm onSave={handleSave} />
    </FullPageDialog>
  );
};

export default ChildAddPage;
