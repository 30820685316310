import _ from "lodash";
import Box from "components/Box";
import IconButton from "components/IconButton";
import Text from "components/Text";
import Stack from "components/Stack";

import { EditIcon } from "icons";
import { colors } from "theme";

type IProps = {
  name: string;
  years: number | null;
  onEdit: () => void;
};

const ChildItem = ({ name, years, onEdit }: IProps) => (
  <Box
    sx={{
      border: "1px solid",
      borderColor: colors.outline,
      borderRadius: "8px",
      padding: "16px",
      marginBottom: "16px",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    }}
  >
    <Stack gap="15px">
      <Text variant="titleMedium">
        {name.trim() === "" && "Unknown name"}
        {name}
      </Text>
      <Stack direction="row" gap="15px">
        <Text variant="titleMedium">
          {_.isNull(years) ? "No age information" : `${years} yrs`}
        </Text>
      </Stack>
    </Stack>
    <IconButton
      sx={{
        position: "absolute",
        top: "16px",
        right: "16px",
        height: "40px",
        width: "40px",
      }}
      onClick={(e) => {
        e.stopPropagation();
        onEdit();
      }}
    >
      <EditIcon />
    </IconButton>
  </Box>
);

export default ChildItem;
