import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { phoneMask } from "utils/masks";
import { URLS } from "config/urls";

import BasicButton from "components/BasicButton";
import Checkbox from "components/Checkbox";
import FormControl from "components/FormControl";
import FormControlLabel from "components/FormControlLabel";
import FormLabel from "components/FormLabel";
import ToggleButton, { ToggleButtonGroup } from "components/ToggleButton";
import TextField from "components/TextField";
import Text from "components/Text";
import Stack from "components/Stack";
import Box from "components/Box";
import useMediaQuery from "components/useMediaQuery";
import FormHelperText from "components/FormHelperText";

import { colors } from "theme";

import { CheckIcon } from "icons";

import { signUp, ISignUpData } from "entities/Auth/sdk";

const SignUpPage = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      preferred_contact_method: "SMS",
      consent_text: true,
      consent_email: true,
    },
  });

  const cleanPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.replace(/\D/g, "");
  };

  const onSubmit = (data: ISignUpData) => {
    const cleanedPhoneNumber = cleanPhoneNumber(data.phone_number);
    const contactData =
      data.preferred_contact_method === "MAIL"
        ? data.email
        : `+${cleanedPhoneNumber}`;

    setIsSubmitting(true);
    signUp({ ...data, phone_number: `+${cleanedPhoneNumber}` })
      .then((response) => {
        navigate(URLS.REGISTRATION_VERIFY, {
          state: {
            emailOrPhone: contactData,
            magicToken: response.magic_token,
            userType: response.user_type,
          },
        });
        setIsSubmitting(false);
      })
      .catch((error) => {
        let errorMessage = t("errorCreatingUser");
        if (error.detail && typeof error.detail === "object") {
          for (const key in error.detail) {
            if (
              Array.isArray(error.detail[key]) &&
              error.detail[key].length > 0
            ) {
              errorMessage = error.detail[key][0];
              break;
            }
          }
        }
        toast.error(errorMessage);
        setIsSubmitting(false);
      });
  };

  const validateConsent = () => {
    const { consent_text, consent_email } = getValues();
    if (!consent_text && !consent_email) {
      setError("consent_text", { type: "manual", message: t("fieldRequired") });
      setError("consent_email", {
        type: "manual",
        message: t("fieldRequired"),
      });
      return false;
    } else {
      clearErrors("consent_text");
      clearErrors("consent_email");
      return true;
    }
  };

  const handleFormSubmit = (data: ISignUpData) => {
    if (validateConsent()) {
      onSubmit(data);
    }
  };

  return (
    <Stack
      gap="20px"
      alignItems={matches ? "center" : "flex-start"}
      sx={{ height: "calc(100vh - 180px)" }}
    >
      <Text variant="titleLarge">{t("getStarted")}</Text>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        style={{ height: "100%", width: "100%" }}
      >
        <Stack
          sx={{ height: "100%" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            spacing={2}
            direction="column"
            sx={{ width: matches ? "50%" : "auto" }}
          >
            <Controller
              name="first_name"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={t("firstName")}
                  variant="outlined"
                  margin="normal"
                  error={!!errors.first_name}
                  helperText={errors.first_name?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="last_name"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={t("lastName")}
                  variant="outlined"
                  margin="normal"
                  error={!!errors.last_name}
                  helperText={errors.last_name?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="phone_number"
              control={control}
              rules={{ required: t("fieldRequired") }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={t("phoneNumber")}
                  variant="outlined"
                  margin="normal"
                  error={!!errors.phone_number}
                  helperText={errors.phone_number?.message}
                  inputRef={(ref) => {
                    field.ref(ref);
                    phoneMask(ref);
                  }}
                  {...field}
                />
              )}
            />
            <Controller
              name="consent_text"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t("textMessagesConsent")}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{
                required: t("fieldRequired"),
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: t("invalidEmail"),
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={t("email")}
                  variant="outlined"
                  margin="normal"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="consent_email"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label={t("emailConsent")}
                />
              )}
            />
            {(errors.consent_text || errors.consent_email) && (
              <FormHelperText error>{t("consentRequired")}</FormHelperText>
            )}
            <FormControl
              component="fieldset"
              margin="normal"
              sx={{ width: "100%" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{ flexGrow: 1, height: "1px", bgcolor: "text.primary" }}
                />
                <FormLabel component="legend" style={{ padding: "0 10px" }}>
                  {t("whichDoYouPrefer")}
                </FormLabel>
                <Box
                  sx={{ flexGrow: 1, height: "1px", bgcolor: "text.primary" }}
                />
              </Box>
              <Controller
                name="preferred_contact_method"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ToggleButtonGroup
                    value={value}
                    exclusive
                    onChange={(newValue) => onChange(newValue)}
                    fullWidth
                    sx={{
                      "& .MuiToggleButtonGroup-grouped": {
                        margin: "8px 0",
                        border: `2px solid ${colors.outline}`,
                        textTransform: "capitalize",
                        justufyContent: "flex-start",
                        "&.Mui-selected": {
                          bgcolor: colors.secondaryContainer,
                          color: colors.onSecondaryContainer,
                          "&:hover": {
                            bgcolor: colors.onHoverSecondaryContainer,
                            color: colors.onSecondaryContainer,
                          },
                        },
                        "&:not(:first-of-type)": {
                          borderRadius: "0 50px 50px 0",
                        },
                        "&:first-of-type": {
                          borderRadius: "50px 0 0 50px",
                          borderRight: "1px solid",
                          borderColor: colors.outline,
                        },
                      },
                    }}
                  >
                    <ToggleButton
                      value="SMS"
                      style={{
                        justifyContent: "flex-start",
                        paddingLeft: "30px",
                      }}
                    >
                      {value == "SMS" && (
                        <CheckIcon
                          onClick={(e) => e.stopPropagation()}
                          style={{ marginRight: "15px" }}
                        />
                      )}

                      {t("textMessage")}
                    </ToggleButton>
                    <ToggleButton
                      value="MAIL"
                      style={{
                        justifyContent: "flex-start",
                        paddingLeft: "30px",
                      }}
                    >
                      {value == "MAIL" && (
                        <CheckIcon
                          onClick={(e) => e.stopPropagation()}
                          style={{ marginRight: "15px" }}
                        />
                      )}

                      {t("email")}
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              />
            </FormControl>
          </Stack>
          <Stack
            alignItems={matches ? "center" : "flex-end"}
            sx={{
              width: "100%",
              borderTop: matches ? "1px solid" : "none",
              borderColor: colors.outline,
            }}
          >
            <Stack sx={{ pt: "30px", width: "100%" }} alignItems="center">
              <BasicButton
                buttonType="submit"
                label={t("next")}
                isDisabled={isSubmitting}
              />
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default SignUpPage;
