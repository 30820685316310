import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";

import ParsedPlacementEditForm from "entities/ParsedPlacement/components/Form";

import {
  useDetailDocumentData,
  parsedPlacementUpdate,
} from "entities/Documents/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const DocumentsContainerParsedPlacementEdit = () => {
  const { t } = useTranslation();
  const { documentId: documentIdParam, placementId: placementIdParam } =
    useParams<{
      documentId: string;
      placementId: string;
    }>();
  const documentId = parseInt(String(documentIdParam));
  const placementId = parseInt(String(placementIdParam));
  const navigate = useNavigate();

  const { data } = useDetailDocumentData(documentId);
  const placement = data?.family_data.voucher_data.placements.find(
    (placement) => placement.id === placementId
  );

  const navigateToPlacements = () => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS, {
        documentId: String(documentId),
        stepname: "placement",
      })
    );
  };

  const handleSave = (values: any) => {
    if (!data) {
      return Promise.reject();
    }
    return parsedPlacementUpdate({ placementId, data: values }).then(
      navigateToPlacements
    );
  };

  if (!placement || !data) {
    return null;
  }

  return (
    <>
      <Stack sx={{ mb: "30px" }} gap="15px">
        <Text variant="titleMedium">{t("placements")}</Text>
      </Stack>
      <Divider />
      <ParsedPlacementEditForm
        initialData={placement}
        onCancel={navigateToPlacements}
        childrenOptions={data.family_data.children_data}
        onSave={handleSave}
      />
    </>
  );
};

export default DocumentsContainerParsedPlacementEdit;
