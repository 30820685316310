import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BasicButton from "components/BasicButton";
import Text from "components/Text";
import Stack from "components/Stack";
import useMediaQuery from "components/useMediaQuery";

import { URLS } from "config/urls";
import { colors } from "theme";

const ExpiredMagicLink = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");

  const handleButtonClick = () => {
    navigate(URLS.LOGIN);
  };

  return (
    <Stack
      justifyContent={"space-between"}
      gap="30px"
      alignItems="center"
      sx={{ minHeight: "calc(100vh - 180px)" }}
    >
      <Stack
        alignItems={matches ? "center" : "flex-start"}
        gap="15px"
        sx={{ marginTop: "50px" }}
      >
        <Text variant="titleLarge" sx={{ wordBreak: "break-word" }}>
          {t("signInLinkExpiredTitle")}
        </Text>
        <Text
          variant="bodyMedium"
          color={colors.onSurfaceVariant}
          sx={{ wordBreak: "break-word" }}
        >
          {t("signInLinkExpiredExplanation")}
        </Text>
      </Stack>
      <Stack
        justifyContent="center"
        sx={{ mt: 4, width: "100%" }}
        gap="30px"
        alignItems={"center"}
      >
        <BasicButton
          label={t("requestNewLinkButton")}
          onClick={handleButtonClick}
        />
      </Stack>
    </Stack>
  );
};

export default ExpiredMagicLink;
