import React, { CSSProperties } from "react";
import { Link as RouterLink } from "react-router-dom";

interface BaseLinkProps {
  to: string;
  target?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  style?: CSSProperties | undefined;
}

interface ExternalLinkProps extends BaseLinkProps {
  external: true;
}

interface InternalLinkProps extends BaseLinkProps {
  external?: false;
}

type ILink = ExternalLinkProps | InternalLinkProps;

export const Link = ({ to, external, children, style, ...rest }: ILink) => {
  if ((external && typeof to === "string") || rest.target === "_blank") {
    return (
      <a
        href={to}
        style={{
          color: "inherit",
          textDecoration: "none",
          ...style,
        }}
        {...rest}
      >
        {children}
      </a>
    );
  }

  return (
    <RouterLink
      style={{
        color: "inherit",
        textDecoration: "none",
        ...style,
      }}
      to={to}
    >
      {children}
    </RouterLink>
  );
};

export default Link;
