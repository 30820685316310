import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";

import ParsedPlacementAddForm from "entities/ParsedPlacement/components/Form";

import {
  useDetailDocumentData,
  parsedPlacementCreate,
} from "entities/Documents/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";

const DocumentsContainerParsedPlacementAdd = () => {
  const { t } = useTranslation();
  const { documentId: documentIdParam } = useParams<{
    documentId: string;
  }>();
  const documentId = parseInt(String(documentIdParam));
  const navigate = useNavigate();

  const { data } = useDetailDocumentData(documentId);

  const navigateToPlacements = () => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS, {
        documentId: String(documentId),
        stepname: "placement",
      })
    );
  };

  const handleSave = (values: any) => {
    if (!data) {
      return Promise.reject();
    }

    return parsedPlacementCreate({
      voucherId: data.family_data.voucher_data.id,
      data: values,
    }).then(navigateToPlacements);
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <Stack sx={{ mb: "30px" }} gap="15px">
        <Text variant="titleMedium">{t("placements")}</Text>
      </Stack>
      <Divider />
      <ParsedPlacementAddForm
        onSave={handleSave}
        onCancel={navigateToPlacements}
        childrenOptions={data.family_data.children_data}
      />
    </>
  );
};

export default DocumentsContainerParsedPlacementAdd;
