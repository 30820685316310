import { useNavigate } from "react-router-dom";

import ParentForm from "entities/Parent/components/Form";

import { parentCreateInUserFamily, IParent } from "entities/Parent/sdk";

import { URLS } from "config/urls";

const ParentAddPage = () => {
  const navigate = useNavigate();

  const handleSave = (values: Partial<IParent>) =>
    parentCreateInUserFamily({ data: values }).then(() => {
      navigate(URLS.FAMILY_INFORMATION_PARENTS);
    });

  return <ParentForm onSave={handleSave} />;
};

export default ParentAddPage;
