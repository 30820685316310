import * as Sentry from "@sentry/react";
const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const VITE_ENV_NAME = import.meta.env.VITE_ENV_NAME;

if (VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0,
    environment: VITE_ENV_NAME,
  });
}
