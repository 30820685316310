import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import Stack from "components/Stack";
import TextField from "components/TextField";

import {
  ISummary,
  familySpecialistSummaryUpdate,
} from "entities/FamilyEdit/sdk";
import { extractErrorMessage } from "utils/error";

interface IProps {
  summary: string;
  familyId: number;
  registerSaveHandler: (handleSave: () => Promise<boolean>) => void;
  refetchFamilyDetail: () => void;
}

const Summary = ({
  summary,
  familyId,
  registerSaveHandler,
  refetchFamilyDetail,
}: IProps) => {
  const { t } = useTranslation();
  const { control, getValues } = useForm({
    defaultValues: {
      child_care_need_summary: summary || "",
    },
  });

  const handleSave = async () => {
    const values = getValues();

    const data: ISummary = {
      child_care_need_summary: values.child_care_need_summary,
    };
    let isError = false;

    await familySpecialistSummaryUpdate(familyId, data).catch((error) => {
      const errorMessage = extractErrorMessage(error);
      toast.error(errorMessage);
      isError = true;
    });

    if (!isError) {
      refetchFamilyDetail();
      return true;
    }

    return false;
  };

  useEffect(() => {
    registerSaveHandler(handleSave);
  }, [handleSave]);

  return (
    <Stack spacing={2} sx={{ padding: 2 }}>
      <Controller
        name="child_care_need_summary"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            label={t("summary")}
          />
        )}
      />
    </Stack>
  );
};

export default Summary;
