import _ from "lodash";
import { useTranslation } from "react-i18next";

import { useFetch } from "utils/sdk";

import { IPlacement, PreferredContactMethod } from "entities/FamilyEdit/sdk";
import {
  employmentStatus,
  gender,
  ISpecialCircumstances,
} from "entities/FamilyMember/sdk";

export type CompletedStatus =
  | "INCOMPLETE_SIGN_UP"
  | "INCOMPLETE_PROFILE"
  | "READY_TO_ADD_TO_WAITLIST"
  | "ON_WAITLIST"
  | "MISSING_REFERRAL_LETTER"
  | "ISSUED"
  | "READY_TO_CREATE_VOUCHER"
  | "INCOMPLETE_REFERRAL_INFORMATION";

export type VoucherTypes =
  | "UNKNOWN"
  | "WAITLIST"
  | "DTA_REFERRAL"
  | "DCF_REFERRAL"
  | "HOMELESS_REFERRAL";

export const useReadableStatus = () => {
  const { t } = useTranslation();

  const keyToReadableStatus: { [key in CompletedStatus]: string } = {
    INCOMPLETE_SIGN_UP: t("incompleteSignUp"),
    INCOMPLETE_PROFILE: t("incompleteProfile"),
    READY_TO_ADD_TO_WAITLIST: t("readyToAddToWaitlist"),
    ON_WAITLIST: t("onWaitlist"),
    MISSING_REFERRAL_LETTER: t("missingReferralLetter"),
    ISSUED: t("onCCFA"),
    READY_TO_CREATE_VOUCHER: t("readyForCCFA"),
    INCOMPLETE_REFERRAL_INFORMATION: t("incompleteReferralInformation"),
  };

  const getReadableCompletedStatus = (key: CompletedStatus): string => {
    return _.get(keyToReadableStatus, key, key);
  };

  return { getReadableCompletedStatus };
};

export default useReadableStatus;

interface IPreferredContactFamilyList {
  id: number;
  first_name: string;
  last_name: string;
  updated_at: string;
}

interface ICreatorDetail {
  id: number;
  first_name: string;
  last_name: string;
  preferred_name: string;
  preferred_language: string;
  employment_status: employmentStatus;
  employer_name: string;
  other_status_description: string;
  gender: string;
  user_type: string;
  child_care_need_summary: string;
  completed_status: CompletedStatus;
  updated_at: string;
  preferred_contact_method: PreferredContactMethod;
  phone_contact_methods: IPhone[];
  email_contact_methods: IEmail[];
}

export interface IDocument {
  id: number;
  file: string;
  file_type: string;
  original_file_name: string;
}
export interface IVoucher extends ISpecialCircumstances {
  id: number;
  type: VoucherTypes;
  children: {
    id: number;
    first_name: string;
    last_name: string;
  }[];
  status: CompletedStatus;
  created_at: string;
  updated_at: string;
  documents: IDocument[];
  is_on_waitlist: boolean;
  referral_date: string;
  child_care_status: string;
  referral_source: string;
  shelter: string;
  shelter_contact: string;
  shelter_contact_email: string;
  shelter_phone: string;
  dta_referral_number: string;
  ccrr: string;
  dta_program: string;
  service_need: string;
  dcf_case_number: string;
  dcf_authorization_number: string;
  dcf_region: string;
  dta_referral_type: string;
  tafdc_closing_date: string;
  issuing_location: string;
  proof_of_identification_verified: boolean;
  placements: IPlacement[];
}

export interface IChildren {
  id: number;
  first_name: string;
  last_name: string;
  age: number;
  gender: gender;
  preferred_language: string;
  date_of_birth: string;
  relationship: string;
  date_when_child_care_is_needed: string;
  child_has_a_disability?: boolean;
  child_is_in_head_start_and_needs_more_hours_of_coverage?: boolean;
}

export interface IFamilyList {
  id: number;
  preferred_contact: IPreferredContactFamilyList;
  vouchers: IVoucher[];
}

export interface IFamilyListPagination {
  count: number;
  next: string;
  previous: string;
  limit: number;
  offset: number;
  results: IFamilyList[];
}

interface IPhone {
  id?: number;
  phone_number: string;
  status: string;
}

interface IEmail {
  id?: number;
  email: string;
  status: string;
}

export interface IFamilyMember {
  id: number;
  first_name: string;
  last_name: string;
  preferred_name: string;
  preferred_language: string;
  gender: gender;
  employment_status: employmentStatus;
  employer_name: string;
  other_status_description: string;
  date_of_birth: string;
}

export interface IPreferredContact {
  id: number;
  first_name: string;
  last_name: string;
}

export interface IMissingFields {
  voucher_id: number;
  missing_fields: {
    family_missing_fields: string[];
    voucher_missing_fields: string[];
    global_errors: string;

    parent_missing_fields: { id: number; missing_fields: string[] }[];
    child_missing_fields: { id: number; missing_fields: string[] }[];
    placement_missing_fields: { id: number; missing_fields: string[] }[];
    child_with_missing_placement: { child_id: number }[];
  };
}

export interface ISender {
  id: number;
  first_name: string;
  last_name: string;
}

export interface IMessages {
  subject: string;
  body: string;
  communication_type: string;
  created_at: string;
  sent_from: ISender;
  files: IDocument[];
  is_intake: boolean;
}

export interface INotes {
  id: number;
  content: string;
  created_at: string;
  creator: ISender;
}

export function isINotes(item: any): item is INotes {
  return (item as INotes).content !== undefined;
}

export function isIMessages(item: any): item is IMessages {
  return (item as IMessages).subject !== undefined;
}

export interface ISpecialist {
  id: number;
  first_name: string;
  last_name: string;
}
export interface IDailyData {
  id: number;
  schedule: string;
  transportation: string;
}
export interface IWeeklyScheduleDataWithId {
  id: number;
  monday: IDailyData;
  tuesday: IDailyData;
  wednesday: IDailyData;
  thursday: IDailyData;
  friday: IDailyData;
  saturday: IDailyData;
  sunday: IDailyData;
}

export interface IFamilyDetail {
  id: number;
  family_id: number;
  creator: ICreatorDetail;
  family_members: IFamilyMember[];
  preferred_contact: IPreferredContact;
  children: IChildren[];
  vouchers: IVoucher[];
  zip_code: string;
  street_address: string;
  unit_address: string;
  city: string;
  household_id: string;
  missing_fields: IMissingFields[];
  messages: IMessages[];
  notes: INotes[];
  specialist: ISpecialist;
}

export interface IFamilyParams {
  assigned_to_me: boolean;
  action_needed: boolean;
  search?: string;
  offset: number;
  limit: number;
}

export const useFamily = (queryParams: IFamilyParams) => {
  return useFetch<IFamilyListPagination>(
    `/api/family-specialists/family/`,
    queryParams
  );
};

export const useFamilyDetail = (id: number) => {
  const { data, error, mutate } = useFetch<IFamilyDetail>(
    `/api/family-specialists/family/detail/${id}/`
  );
  return { data, error, mutate };
};
