const LANGUAGE_KEY = "selectedLanguage";

export const getLanguage = () => {
  return window.localStorage.getItem(LANGUAGE_KEY) || "en";
};

export const saveLanguage = (language: string) => {
  window.localStorage.setItem(LANGUAGE_KEY, language);
};

export const removeLanguage = () => {
  window.localStorage.removeItem(LANGUAGE_KEY);
};
