import { useState } from "react";
import { useTranslation } from "react-i18next";

import Text from "components/Text";
import Box from "components/Box";
import Paper from "components/Paper";
import IconButton from "components/IconButton";
import InputBase from "components/InputBase";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";

import { MagnifierIcon } from "icons";
import { colors } from "theme";

interface FilterSearchRowProps {
  onChange: (
    actionNeeded: boolean,
    assignedToMe: boolean,
    searchText: string
  ) => void;
}

const FilterSearchRow = ({ onChange }: FilterSearchRowProps) => {
  const { t } = useTranslation();
  const [actionNeeded, setActionNeeded] = useState<boolean>(false);
  const [assignedToMe, setAssignedToMe] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const handleActionNeededSelect = () => {
    setActionNeeded(!actionNeeded);
    onChange(!actionNeeded, assignedToMe, searchText);
  };

  const handleAssignedToMeSelect = () => {
    setAssignedToMe(!assignedToMe);
    onChange(actionNeeded, !assignedToMe, searchText);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const ENTER_KEY_CODE = 13;
    if (event.which === ENTER_KEY_CODE) {
      onChange(actionNeeded, assignedToMe, searchText);
    }
  };

  const handleSearchClick = () => {
    onChange(actionNeeded, assignedToMe, searchText);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={assignedToMe}
            onChange={handleAssignedToMeSelect}
            inputProps={{ "aria-label": "Assigned to me" }}
            sx={{
              color: colors.primary,
              "&.Mui-checked": {
                color: colors.primary,
              },
            }}
          />
        }
        label={<Text variant="body1">{t("assignedToMe")}</Text>}
        sx={{ marginRight: 2 }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={actionNeeded}
            onChange={handleActionNeededSelect}
            inputProps={{ "aria-label": "Action Needed" }}
            sx={{
              color: colors.primary,
              "&.Mui-checked": {
                color: colors.primary,
              },
            }}
          />
        }
        label={<Text variant="body1">{t("actionNeeded")}</Text>}
        sx={{ marginRight: 2 }}
      />
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "4px 16px",
          borderRadius: "25px",
          backgroundColor: colors.surfaceContainerHigh,
          boxShadow: "none",
          flex: 1,
          ml: "40px",
          height: "56px",
        }}
      >
        <InputBase
          placeholder={t("search")}
          value={searchText}
          onChange={handleSearchChange}
          onKeyPress={handleKeyPress}
          inputProps={{ "aria-label": "search" }}
          sx={{ flex: 1, marginLeft: 1 }}
        />
        <IconButton onClick={handleSearchClick} aria-label="search">
          <MagnifierIcon />
        </IconButton>
      </Paper>
    </Box>
  );
};

export default FilterSearchRow;
