import CircularProgress from "components/CircularProgress";
import Box from "components/Box";
import Text from "components/Text";

import { colors } from "theme";

interface IProps {
  message?: string;
}

const LoadingScreen = ({ message }: IProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        zIndex: 9999,
      }}
    >
      <CircularProgress sx={{ color: colors.primary }} />
      {message && <Text sx={{ mt: 2 }}>{message}</Text>}
    </Box>
  );
};

export default LoadingScreen;
