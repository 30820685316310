import _ from "lodash";
import { useTranslation } from "react-i18next";

import { post, BASE_URL } from "utils/sdk";
import { IFamily } from "entities/Family/sdk";

export enum AuthorizationStatus {
  "INCOMPLETE_SIGN_UP" = "INCOMPLETE_SIGN_UP",
  "INCOMPLETE_PROFILE" = "INCOMPLETE_PROFILE",
  "READY_TO_ADD_TO_WAITLIST" = "READY_TO_ADD_TO_WAITLIST",
  "ON_WAITLIST" = "ON_WAITLIST",
  "MISSING_REFERRAL_LETTER" = "MISSING_REFERRAL_LETTER",
  "ISSUED" = "ISSUED",
  "READY_TO_CREATE_VOUCHER" = "READY_TO_CREATE_VOUCHER",
  "INCOMPLETE_REFERRAL_INFORMATION" = "INCOMPLETE_REFERRAL_INFORMATION",
}

export const useReadableStatus = () => {
  const { t } = useTranslation();

  const keyToReadableStatus = {
    INCOMPLETE_SIGN_UP: t("incompleteSignUp"),
    INCOMPLETE_PROFILE: t("incompleteProfile"),
    READY_TO_ADD_TO_WAITLIST: t("readyToAddToWaitlist"),
    ON_WAITLIST: t("onWaitlist"),
    MISSING_REFERRAL_LETTER: t("missingReferralLetter"),
    ISSUED: t("onCCFA"),
    READY_TO_CREATE_VOUCHER: t("readyForCCFA"),
    INCOMPLETE_REFERRAL_INFORMATION: t("incompleteReferralInformation"),
  };

  const getReadableCompletedStatus = (key: AuthorizationStatus): string => {
    return _.get(keyToReadableStatus, key, key);
  };

  return { getReadableCompletedStatus };
};

export const authorizationUpdate = ({
  authorizationId,
  data,
}: {
  authorizationId: number;
  data: Partial<
    Omit<IFamily["vouchers"][number], "children"> & { children: string[] }
  >;
}) =>
  post(
    `${BASE_URL}/api/family-members/authorization/${authorizationId}/update/`,
    data
  );
