import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import AddButton from "components/AddButton";
import Stack from "components/Stack";
import ChildItem from "components/ChildItem";

import AddChildForm, { AddChildFormRef } from "../AddChildForm";
import { IChildren } from "entities/FamilySpecialist/sdk";
import EditPanel from "../index";
import { useData } from "../DataContext";

interface IProps {
  refetchFamilyDetail: () => void;
}

const Children = ({ refetchFamilyDetail }: IProps) => {
  const { t } = useTranslation();
  const [isAdding, setIsAdding] = useState(false);
  const [selectedChild, setSelectedChild] = useState<IChildren | null>(null);
  const addChildFormRef = useRef<AddChildFormRef>(null);
  const familyData = useData();

  const handleAddParentClick = () => {
    setSelectedChild(null);
    setIsAdding(true);
  };

  const handleEditChild = (child: IChildren) => {
    setSelectedChild(child);
    setIsAdding(true);
  };

  const handleSaveChild = () => {
    setIsAdding(false);
  };

  const handleSaveClick = () => {
    if (addChildFormRef.current) {
      addChildFormRef.current.submitForm();
    }
  };

  if (familyData === null) {
    return null;
  }

  return (
    <Stack spacing={2} key={familyData.id}>
      <EditPanel
        open={isAdding}
        onClose={() => setIsAdding(false)}
        title={t(selectedChild ? "Edit Child" : "Add Child")}
        onSaveClick={handleSaveClick}
      >
        <AddChildForm
          ref={addChildFormRef}
          onSave={handleSaveChild}
          initialData={selectedChild}
          familyId={familyData.id}
          refetchFamilyDetail={refetchFamilyDetail}
        />
      </EditPanel>
      {familyData.children.map((child, index) => (
        <ChildItem
          key={index}
          name={`${child.first_name} ${child.last_name}`}
          years={child.age}
          onEdit={() => handleEditChild(child)}
        />
      ))}
      <Stack alignItems="center" style={{ marginBottom: "20px" }}>
        <AddButton label={t("addChild")} onClick={handleAddParentClick} />
      </Stack>
    </Stack>
  );
};

export default Children;
