import { Outlet } from "react-router-dom";

import LeftPanel from "pages/FamilyInformation/components/DocumentsContainer/FormLayout/LeftPanel";

import Stack from "components/Stack";

import { colors } from "theme";

const DocumentsLayout = () => {
  return (
    <Stack direction="row" gap="15px">
      <LeftPanel />
      <Stack
        sx={{
          flex: 1,
          padding: "20px",
          backgroundColor: colors.surface,
          maxWidth: "33.33%",
        }}
      >
        <Outlet />
      </Stack>
    </Stack>
  );
};

export default DocumentsLayout;
