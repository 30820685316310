import { useTranslation } from "react-i18next";

import AddButton from "components/AddButton";
import BasicButton from "components/BasicButton";
import Container from "components/Container";
import IconButton from "components/IconButton";
import Link from "components/Link";
import Stack from "components/Stack";
import Text from "components/Text";

import { useMyFamily } from "entities/Family/sdk";
import { getEmploymentStatusDisplayValue } from "entities/FamilyMember/sdk";

import { colors } from "theme";
import { EditIcon } from "icons";
import { reverse } from "utils/urls";
import { URLS } from "config/urls";

const ParentsListPage = () => {
  const { t } = useTranslation();
  const { data: family } = useMyFamily();
  const parents = family?.family_members;

  return (
    <Stack sx={{ justifyContent: "space-between", flex: 1 }}>
      <Container maxWidth="sm" sx={{ flex: 1, display: "flex" }}>
        <Stack sx={{ mt: "30px", width: "100%", alignItems: "center" }}>
          <Stack
            sx={{
              marginBottom: "20px",
              borderBottom: "1px solid",
              borderColor: colors.outline,
              width: "100%",
            }}
          >
            {parents?.map((parent, index) => (
              <Stack
                key={index}
                sx={{
                  paddingTop: (theme) => theme.spacing(1),
                  paddingBottom: (theme) => theme.spacing(1),
                  borderTop: "1px solid",
                  borderColor: colors.outline,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                direction="row"
              >
                <Text variant="titleSmall">
                  {parent.first_name} {parent.last_name}
                </Text>
                <div>
                  <Text variant="titleSmall">
                    <Text variant="titleSmall" fontWeight="bold">
                      {parent.employment_status &&
                        getEmploymentStatusDisplayValue(
                          parent.employment_status
                        )}
                    </Text>{" "}
                    {parent.employer_name} {parent.other_status_description}
                  </Text>
                  <Link
                    to={reverse(URLS.FAMILY_INFORMATION_EDIT_PARENT, {
                      parentId: parent.id,
                    })}
                  >
                    <IconButton size="small">
                      <EditIcon style={{ width: "18px", height: "18px" }} />
                    </IconButton>
                  </Link>
                </div>
              </Stack>
            ))}
          </Stack>
          <Link
            to={URLS.FAMILY_INFORMATION_ADD_PARENT}
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <AddButton
              label={t("addParent")}
              backgroundColor={colors.secondaryContainer}
            />
          </Link>
        </Stack>
      </Container>
      <Stack
        sx={{
          marginTop: "30px",
          alignItems: "center",
          borderTop: "1px solid " + colors.outlineVariant,
          paddingTop: (theme) => theme.spacing(3),
        }}
      >
        <Link to={URLS.FAMILY_INFORMATION_MAILING_ADDRESS}>
          <BasicButton label={t("next")} />
        </Link>
      </Stack>
    </Stack>
  );
};

export default ParentsListPage;
