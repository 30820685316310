import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import dayjs from "dayjs";

import Stack from "components/Stack";
import BasicButton from "components/BasicButton";
import TextField from "components/TextField";
import StyledSelect from "components/StyledSelect";
import FormHelperText from "components/FormHelperText";
import useMediaQuery from "components/useMediaQuery";
import FormControlLabel from "components/FormControlLabel";

import {
  useGenderOptions,
  useLanguageOptions,
} from "entities/FamilyMember/sdk";

import { colors } from "theme";
import FormControl from "components/FormControl";
import Radio, { RadioGroup } from "components/Radio";
import { ParentData } from "entities/FamilyEdit/sdk";
import { IParent } from "entities/Parent/sdk";
import {
  parsedParentCreate,
  parsedParentUpdate,
} from "entities/ParsedParent/sdk";
import DateInput from "components/DateInput";

interface IProps {
  parentData?: Partial<ParentData>;
  setStepname?: (stepName: string) => void;
  documentId?: number;
  parsedFamilyId: number;
}
const ParsedParentForm = ({
  parentData,
  setStepname,
  documentId,
  parsedFamilyId,
}: IProps) => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");

  const genderOptions = useGenderOptions();

  const { control, handleSubmit, watch } = useForm<IParent>({
    defaultValues: {
      first_name: parentData?.first_name || "",
      last_name: parentData?.last_name || "",
      gender: parentData?.gender,
      preferred_name: parentData?.preferred_name || "",
      preferred_language: parentData?.preferred_language || undefined,
      date_of_birth: parentData?.date_of_birth
        ? dayjs(parentData.date_of_birth).format("MM/DD/YYYY")
        : undefined,
      employment_status: parentData?.employment_status || undefined,
      employer_name: parentData?.employer_name || "",
      other_status_description: parentData?.other_status_description || "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    gender: "",
    preferred_name: "",
    preferred_language: "",
    employment_status: undefined,
    employer_name: "",
    other_status_description: "",
  });
  const [employmentStatusError, setEmploymentStatusError] = useState<
    string | null
  >(null);
  const [employerNameError, setEmployerNameError] = useState<string | null>(
    null
  );
  const [otherStatusDescriptionError, setOtherStatusDescriptionError] =
    useState<string | null>(null);
  const employmentStatus = watch("employment_status");
  const employerName = watch("employer_name");
  const otherStatusDescription = watch("other_status_description");
  const languageOptions = useLanguageOptions();

  const onSubmit = (data: Partial<IParent>) => {
    let valid = true;
    const newErrors = {
      first_name: "",
      last_name: "",
      gender: "",
      preferred_language: "",
      preferred_name: "",
      employment_status: undefined,
      employer_name: "",
      other_status_description: "",
    };

    if (!documentId && !parsedFamilyId) {
      if (!data.first_name) {
        newErrors.first_name = t("fieldRequired");
        valid = false;
      }
      if (!data.last_name) {
        newErrors.last_name = t("fieldRequired");
        valid = false;
      }
      if (!data.gender) {
        newErrors.gender = t("fieldRequired");
        valid = false;
      }
      if (!data.preferred_name && !documentId) {
        newErrors.preferred_name = t("fieldRequired");
        valid = false;
      }
      if (!data.preferred_language && !documentId) {
        newErrors.preferred_language = t("fieldRequired");
        valid = false;
      }
      if (!employmentStatus && !documentId) {
        setEmploymentStatusError(t("optionSelectRequired"));
        valid = false;
      } else {
        setEmploymentStatusError(null);
      }

      if (employmentStatus === "EMPLOYED" && !employerName && !documentId) {
        setEmployerNameError(t("fieldRequired"));
        valid = false;
      } else {
        setEmployerNameError(null);
      }

      if (
        employmentStatus === "OTHER" &&
        !otherStatusDescription &&
        !documentId
      ) {
        setOtherStatusDescriptionError(t("fieldRequired"));
        valid = false;
      } else {
        setOtherStatusDescriptionError(null);
      }

      setErrors(newErrors);
    }

    if (!valid) return;

    const formattedBirthDate = data.date_of_birth
      ? dayjs(data.date_of_birth, "MM/DD/YYYY").format("YYYY-MM-DD")
      : undefined;
    setIsSubmitting(true);
    const parentCreateData = {
      first_name: data.first_name,
      last_name: data.last_name,
      gender: data.gender,
      preferred_name: data.preferred_name,
      preferred_language: data.preferred_language,
      employment_status: data.employment_status,
      employer_name: data.employer_name || undefined,
      other_status_description: data.other_status_description || undefined,
      ...(data.date_of_birth && { date_of_birth: formattedBirthDate }),
    };
    if (setStepname && documentId) {
      if (parentData && parentData.id) {
        parsedParentUpdate({
          parsedParentId: parentData.id,
          data: parentCreateData,
        })
          .then(() => {
            setIsSubmitting(false);
            setStepname("parent-status");
          })
          .catch(() => {
            const errorMessage = t("errorSavingData");
            toast.error(errorMessage);
            setIsSubmitting(false);
          });
      } else {
        parsedParentCreate({ parsedFamilyId, data: parentCreateData })
          .then(() => {
            setIsSubmitting(false);
            setStepname("parent-status");
          })
          .catch(() => {
            const errorMessage = t("errorSavingData");
            toast.error(errorMessage);
            setIsSubmitting(false);
          });
      }
    }
  };

  return (
    <>
      <Stack sx={{ alignItems: "center", flex: 1 }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ flex: 1 }}>
          <Stack
            sx={{ height: "100%" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack sx={{ width: matches ? "400px" : "100%" }}>
              <Controller
                name="first_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("firstName")}
                    fullWidth
                    margin="normal"
                    error={!!errors.first_name}
                    helperText={errors.first_name}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("lastName")}
                    fullWidth
                    margin="normal"
                    error={!!errors.last_name}
                    helperText={errors.last_name}
                  />
                )}
              />
              <Controller
                name="preferred_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("preferredName")}
                    fullWidth
                    margin="normal"
                    error={!!errors.preferred_name}
                    helperText={errors.preferred_name}
                  />
                )}
              />
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label={t("gender")}
                    options={genderOptions}
                    fullWidth
                    margin="normal"
                    error={!!errors.gender}
                    helperText={errors.gender}
                  />
                )}
              />
              <Controller
                name="preferred_language"
                control={control}
                render={({ field }) => (
                  <StyledSelect
                    {...field}
                    label={t("preferredLanguage")}
                    options={languageOptions}
                    fullWidth
                    margin="normal"
                    error={!!errors.preferred_language}
                    helperText={errors.preferred_language}
                  />
                )}
              />
              {(parsedFamilyId || documentId) && (
                <DateInput
                  control={control}
                  label={t("dateOfBirth")}
                  name="date_of_birth"
                  required={false}
                />
              )}
              <FormControl
                component="fieldset"
                margin="normal"
                sx={{ width: "100%" }}
                error={!!employmentStatusError}
              >
                <Controller
                  name="employment_status"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      row
                      aria-label="employment status"
                      sx={{ flexDirection: "column" }}
                    >
                      <FormControlLabel
                        value="EMPLOYED"
                        control={<Radio />}
                        label={t("iAmEmployed")}
                        sx={{
                          borderBottom: "1px solid",
                          borderColor: colors.outlineVariant,
                          marginRight: 0,
                          padding: "10px 0",
                        }}
                      />
                      <FormControlLabel
                        value="IN_SCHOOL"
                        control={<Radio />}
                        label={t("iAmInSchool")}
                        sx={{
                          borderBottom: "1px solid",
                          borderColor: colors.outlineVariant,
                          marginRight: 0,
                          padding: "10px 0",
                        }}
                      />
                      <FormControlLabel
                        value="TRAININGPROGRAM"
                        control={<Radio />}
                        label={t("iAmInTraining")}
                        sx={{
                          borderBottom: "1px solid",
                          borderColor: colors.outlineVariant,
                          marginRight: 0,
                          padding: "10px 0",
                        }}
                      />
                      <FormControlLabel
                        value="OTHER"
                        control={<Radio />}
                        label={t("other")}
                        sx={{
                          borderBottom: "1px solid",
                          borderColor: colors.outlineVariant,
                          marginRight: 0,
                          padding: "10px 0",
                        }}
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
              {employmentStatusError && (
                <FormHelperText error>{employmentStatusError}</FormHelperText>
              )}
              {employmentStatus === "EMPLOYED" && (
                <Controller
                  name="employer_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("employerName")}
                      fullWidth
                      margin="normal"
                      error={!!employerNameError}
                      helperText={employerNameError}
                    />
                  )}
                />
              )}

              {employmentStatus === "OTHER" && (
                <Controller
                  name="other_status_description"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={t("other")}
                      fullWidth
                      margin="normal"
                      error={!!otherStatusDescriptionError}
                      helperText={otherStatusDescriptionError}
                    />
                  )}
                />
              )}
            </Stack>
            <Stack
              alignItems={matches ? "center" : "flex-end"}
              sx={{
                width: "100%",
                borderTop: matches ? "1px solid" : "none",
                borderColor: colors.outline,
              }}
            >
              <Stack sx={{ mt: "30px", width: "100%" }} alignItems="center">
                <BasicButton
                  buttonType="submit"
                  label={t("next")}
                  isDisabled={isSubmitting}
                />
              </Stack>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default ParsedParentForm;
