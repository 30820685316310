import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import BasicButton from "components/BasicButton";
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "components/Dialog";
import IconButton from "components/IconButton";
import TextField from "components/TextField";

import { CloseIcon } from "icons";
import { colors } from "theme";
import Stack from "components/Stack";
import { addNote } from "../sdk";

interface IProps {
  open: boolean;
  onClose: () => void;
  familyId: number;
}

function AddNoteDialog({ open, onClose, familyId }: IProps) {
  const { t } = useTranslation();
  const [noteContent, setNoteContent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleClose = () => {
    setNoteContent("");
    setShowError(false);
    onClose();
  };

  const handleAddNote = async () => {
    if (!noteContent.trim()) {
      setShowError(true);
      return;
    }
    setIsSubmitting(true);

    const data = {
      content: noteContent,
    };
    addNote(familyId, data)
      .then(() => {
        toast.success(t("noteAddedSuccessfully"));
        setIsSubmitting(false);
        handleClose();
      })
      .catch(() => {
        const errorMessage = t("errorSavingData");
        toast.error(errorMessage);
        setIsSubmitting(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          backgroundColor: colors.surfaceContainerLow,
        },
      }}
    >
      <DialogTitle>
        {t("addNote")}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon style={{ fill: colors.onSurfaceVariant }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            pt: "30px",
            borderTop: "1px solid",
            borderColor: colors.outlineVariant,
          }}
        >
          <TextField
            label={t("addNoteHere")}
            fullWidth
            multiline
            minRows={6}
            variant="outlined"
            required
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
            error={!noteContent.trim() && showError}
            helperText={!noteContent.trim() && showError && t("fieldRequired")}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mb: "20px" }}>
        <BasicButton
          label={t("addNote")}
          onClick={handleAddNote}
          isDisabled={isSubmitting}
        />
      </DialogActions>
    </Dialog>
  );
}

export default AddNoteDialog;
