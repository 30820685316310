import Text from "components/Text";
import { colors } from "theme";

interface INestedListProps {
  list: {
    title: string;
    items?: INestedListProps["list"];
  }[];
}

const NestedList = ({ list }: INestedListProps) => {
  return (
    <ul>
      {list.map((item) => (
        <li>
          <Text
            fontWeight="500"
            variant="labelLarge"
            color={colors.onSurfaceVariant}
          >
            {item.title}
          </Text>
          {item.items && <NestedList list={item.items} />}
        </li>
      ))}
    </ul>
  );
};

export default NestedList;
