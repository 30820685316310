import { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import { toast } from "react-toastify";
import i18n from "i18n/i18n";

import Stack from "components/Stack";
import TextField from "components/TextField";
import StyledSelect from "components/StyledSelect";
import DateInput from "components/DateInput";
import Box from "components/Box";
import DatePicker from "components/DatePicker";
import FormHelperText from "components/FormHelperText";
import FormControlLabel from "components/FormControlLabel";
import Checkbox from "components/Checkbox";

import {
  IChildCreate,
  useGenderOptions,
  useLanguageOptions,
  useRelationshipOptions,
} from "entities/FamilyMember/sdk";
import { IChildren } from "entities/FamilySpecialist/sdk";
import {
  childCreateAsSpecialist,
  childUpdateAsSpecialist,
} from "entities/Child/sdk";

import { getDefaultLanguageValue } from "utils/language";

interface IProps {
  onSave: (data: IChildCreate) => void;
  familyId: number;
  initialData?: IChildren | null;
  refetchFamilyDetail: () => void;
}

export interface AddChildFormRef {
  submitForm: () => void;
}

const AddChildForm = forwardRef<AddChildFormRef, IProps>(
  ({ onSave, familyId, initialData, refetchFamilyDetail }, ref) => {
    const { t } = useTranslation();
    const defaultLanguage = getDefaultLanguageValue(i18n.language);
    const { control, handleSubmit, reset } = useForm<IChildCreate>({
      defaultValues: {
        child_has_a_disability: false,
        child_is_in_head_start_and_needs_more_hours_of_coverage: false,
      },
    });
    const [errors, setErrors] = useState({
      first_name: "",
      last_name: "",
      date_of_birth: "",
      gender: "",
      relationship: "",
      date_when_child_care_is_needed: "",
      preferred_language: "",
    });

    useEffect(() => {
      const today = dayjs().format("YYYY-MM-DD");
      if (initialData) {
        const formattedInitialData = {
          ...initialData,
          date_of_birth: dayjs(initialData.date_of_birth).format("MM/DD/YYYY"),
          date_when_child_care_is_needed:
            initialData.date_when_child_care_is_needed
              ? dayjs(initialData.date_when_child_care_is_needed).format(
                  "YYYY-MM-DD"
                )
              : today,
        };
        reset(formattedInitialData);
      } else {
        reset({ date_when_child_care_is_needed: today });
      }
    }, [initialData, reset]);

    const genderOptions = useGenderOptions();
    const languageOptions = useLanguageOptions();
    const relationshipOptions = useRelationshipOptions();

    const onSubmit = (data: IChildCreate) => {
      let valid = true;
      const newErrors = {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        gender: "",
        relationship: "",
        date_when_child_care_is_needed: "",
        preferred_language: "",
      };

      if (!data.first_name) {
        newErrors.first_name = t("fieldRequired");
        valid = false;
      }
      if (!data.last_name) {
        newErrors.last_name = t("fieldRequired");
        valid = false;
      }
      if (!data.date_of_birth) {
        newErrors.date_of_birth = t("fieldRequired");
        valid = false;
      }
      if (!data.gender) {
        newErrors.gender = t("fieldRequired");
        valid = false;
      }
      if (!data.relationship) {
        newErrors.relationship = t("fieldRequired");
        valid = false;
      }
      if (!data.date_when_child_care_is_needed) {
        newErrors.date_when_child_care_is_needed = t("fieldRequired");
        valid = false;
      }
      if (!data.preferred_language) {
        newErrors.preferred_language = t("fieldRequired");
        valid = false;
      }

      setErrors(newErrors);

      if (!valid) return;

      const formattedBirthDate = dayjs(data.date_of_birth, "MM/DD/YYYY").format(
        "YYYY-MM-DD"
      );
      const formattedChildCareDate = dayjs(
        data.date_when_child_care_is_needed
      ).format("YYYY-MM-DD");
      const childData = {
        first_name: data.first_name,
        last_name: data.last_name,
        gender: data.gender,
        date_of_birth: formattedBirthDate,
        preferred_language: data.preferred_language,
        relationship: data.relationship,
        date_when_child_care_is_needed: formattedChildCareDate,
        child_has_a_disability: data.child_has_a_disability,
        child_is_in_head_start_and_needs_more_hours_of_coverage:
          data.child_is_in_head_start_and_needs_more_hours_of_coverage,
      };

      const saveChild =
        initialData && initialData.id
          ? childUpdateAsSpecialist(familyId, initialData.id, childData)
          : childCreateAsSpecialist(familyId, childData);

      saveChild
        .then(() => {
          onSave(data);
          refetchFamilyDetail();
        })
        .catch(() => {
          const errorMessage = t("errorSavingData");
          toast.error(errorMessage);
        });
    };

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ padding: 2 }}>
          <Controller
            name="first_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t("firstName")}
                fullWidth
                margin="normal"
                error={!!errors.first_name}
                helperText={errors.first_name}
              />
            )}
          />
          <Controller
            name="last_name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t("lastName")}
                fullWidth
                margin="normal"
                error={!!errors.last_name}
                helperText={errors.last_name}
              />
            )}
          />
          <Controller
            name="gender"
            control={control}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("gender")}
                options={genderOptions}
                fullWidth
                margin="normal"
                error={!!errors.gender}
                helperText={errors.gender}
              />
            )}
          />

          <Controller
            name="preferred_language"
            control={control}
            defaultValue={defaultLanguage}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("preferredLanguage")}
                options={languageOptions}
                fullWidth
                margin="normal"
                error={!!errors.preferred_language}
                helperText={errors.preferred_language}
              />
            )}
          />
          <DateInput
            control={control}
            label={t("dateOfBirth")}
            name="date_of_birth"
            error={!!errors.date_of_birth}
            helperText={errors.date_of_birth}
          />

          <Controller
            name="relationship"
            control={control}
            defaultValue={relationshipOptions[0].value}
            render={({ field }) => (
              <StyledSelect
                {...field}
                label={t("relationship")}
                options={relationshipOptions}
                fullWidth
                margin="normal"
                error={!!errors.relationship}
                helperText={errors.relationship}
              />
            )}
          />
          <Controller
            name="date_when_child_care_is_needed"
            control={control}
            render={({ field }) => (
              <Box sx={{ mt: "16px", mb: "8px" }}>
                <DatePicker
                  {...field}
                  label={t("dateChildCare")}
                  initialValue={field.value || dayjs().format("YYYY-MM-DD")}
                />
                {!!errors.date_when_child_care_is_needed && (
                  <FormHelperText error>
                    {errors.date_when_child_care_is_needed}
                  </FormHelperText>
                )}
              </Box>
            )}
          />
          <Controller
            name="child_has_a_disability"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("childHasADisability")}
              />
            )}
          />
          <Controller
            name="child_is_in_head_start_and_needs_more_hours_of_coverage"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t("chilsIsInHeadStart")}
              />
            )}
          />
        </Stack>
      </form>
    );
  }
);

export default AddChildForm;
