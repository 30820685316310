import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import BasicButton from "components/BasicButton";
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "components/Dialog";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import StyledSelect from "components/StyledSelect";

import { CloseIcon } from "icons";
import { colors } from "theme";

import { specialistAssign, useSpecialistData } from "entities/Documents/sdk";

interface IProps {
  open: boolean;
  onClose: () => void;
  familyId: number;
}

function ReassignFamilyDialog({ open, onClose, familyId }: IProps) {
  const { t } = useTranslation();
  const [specialistId, setSpecialistId] = useState("");
  const { data: specialistData } = useSpecialistData();

  const specialistDataOptions =
    specialistData?.map((item) => ({
      value: item.id.toString(),
      label: `${item.first_name} ${item.last_name}`,
    })) || [];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleClose = () => {
    setSpecialistId("");
    setShowError(false);
    onClose();
  };

  const handleReassign = async () => {
    if (!specialistId) {
      setShowError(true);
      return;
    }
    setIsSubmitting(true);

    const assignData = {
      specialist_id: Number(specialistId),
    };
    specialistAssign(familyId, assignData)
      .then(() => {
        toast.success(t("familyReassignedSuccessfully"));
        setIsSubmitting(false);
        handleClose();
      })
      .catch(() => {
        const errorMessage = t("errorSavingData");
        toast.error(errorMessage);
        setIsSubmitting(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          backgroundColor: colors.surfaceContainerLow,
        },
      }}
    >
      <DialogTitle>
        {t("reassignFamily")}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon style={{ fill: colors.onSurfaceVariant }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            pt: "30px",
            borderTop: "1px solid",
            borderColor: colors.outlineVariant,
          }}
        >
          <StyledSelect
            name="familyAdministrator"
            label={t("reassignTo")}
            options={specialistDataOptions}
            onChange={(e) => setSpecialistId(e.target.value)}
            error={showError}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mb: "20px" }}>
        <BasicButton
          label={t("reassign")}
          onClick={handleReassign}
          isDisabled={isSubmitting}
        />
      </DialogActions>
    </Dialog>
  );
}

export default ReassignFamilyDialog;
