import Box from "components/Box";
import IconButton from "components/IconButton";
import Text from "components/Text";
import Stack from "components/Stack";

import { EditIcon } from "icons";
import { colors } from "theme";
import dayjs from "dayjs";

type AuthorizationItemProps = {
  title: string;
  lastActivity: string;
  type: string;
  actionRequired: string;
  onEdit: () => void;
};

const AuthorizationItem = ({
  title,
  lastActivity,
  type,
  actionRequired,
  onEdit,
}: AuthorizationItemProps) => (
  <Box
    sx={{
      border: "1px solid",
      borderColor: colors.outline,
      borderRadius: "8px",
      padding: "16px",
      marginBottom: "16px",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    }}
  >
    <Stack gap="15px">
      <Text variant="titleMedium">{title}</Text>
      <Text variant="labelSmall" color="textSecondary">
        Last Activity: {dayjs(lastActivity).format("MM/DD/YYYY")}
      </Text>
      <Stack direction="row" gap="30px">
        <Text variant="titleMedium">{type}</Text>
        <Text variant="titleMedium">{actionRequired}</Text>
      </Stack>
    </Stack>
    <IconButton
      sx={{
        position: "absolute",
        top: "16px",
        right: "16px",
        height: "40px",
        width: "40px",
      }}
      onClick={(e) => {
        e.stopPropagation();
        onEdit();
      }}
    >
      <EditIcon />
    </IconButton>
  </Box>
);

export default AuthorizationItem;
