import { post } from "utils/sdk";
import { BASE_URL } from "config/urls";
import { IDetailDocumentData } from "entities/Documents/sdk";

export const parsedParentUpdate = ({
  parsedParentId,
  data,
}: {
  parsedParentId: number;
  data: Partial<IDetailDocumentData["family_data"]["parents_data"][number]>;
}) => {
  return post(
    `${BASE_URL}/api/parsed-documents/parsed-parent/${parsedParentId}/update/`,
    data
  );
};

export const parsedParentCreate = ({
  parsedFamilyId,
  data,
}: {
  parsedFamilyId: number;
  data: Partial<IDetailDocumentData["family_data"]["parents_data"][number]>;
}) => {
  return post(
    `${BASE_URL}/api/parsed-documents/parsed-family/${parsedFamilyId}/parent-create/`,
    data
  );
};
