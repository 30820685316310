import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AddButton from "components/AddButton";
import BasicButton from "components/BasicButton";
import Stack from "components/Stack";
import Text from "components/Text";
import Divider from "components/Divider";
import ParsedPlacementCard from "entities/ParsedPlacement/components/Card";
import useMediaQuery from "components/useMediaQuery";

import {
  useDetailDocumentData,
  createParsedFamily,
} from "entities/Documents/sdk";

import { reverse } from "utils/urls";
import { URLS } from "config/urls";
import { colors } from "theme";

const PlacementList = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { documentId: documentIdParam } = useParams<{
    documentId: string;
  }>();
  const documentId = parseInt(String(documentIdParam));
  const { data } = useDetailDocumentData(documentId);

  const { t } = useTranslation();

  const placements = data?.family_data.voucher_data.placements;

  const handleAddPlacement = () => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS_ADD_PLACEMENT, {
        documentId: String(documentId),
      })
    );
  };

  const handleEditPlacement = (placementId: number) => {
    navigate(
      reverse(URLS.FAMILY_SPECIALIST_DOCUMENTS_EDIT_PLACEMENT, {
        documentId: String(documentId),
        placementId,
      })
    );
  };

  const handleNextButtonClick = () => {
    if (!data) {
      return Promise.reject();
    }

    createParsedFamily({ parsedFamilyId: data.family_data.id }).then(
      ({ family_id }) => {
        navigate(
          reverse(URLS.FAMILY_SPECIALIST_FAMILY_ASSIGN_SPECIALIST, {
            documentId,
            familyId: family_id,
          })
        );
      }
    );
  };

  if (!placements) {
    return null;
  }

  return (
    <Stack sx={{ flex: 1 }}>
      <Stack sx={{ mb: "30px" }} gap="15px">
        <Text variant="titleMedium">{t("placements")}</Text>
      </Stack>
      <Divider />
      <Stack sx={{ flex: 1, justifyContent: "space-between" }}>
        <Stack spacing={2} sx={{ alignItems: "center" }}>
          {placements.map((placement) => (
            <ParsedPlacementCard
              placement={placement}
              voucher={placement.voucher}
              onEdit={() => handleEditPlacement(placement.id)}
            />
          ))}
          <div style={{ marginTop: "30px" }}>
            <AddButton label={t("addPlacement")} onClick={handleAddPlacement} />
          </div>
        </Stack>
        <Stack
          alignItems={matches ? "center" : "flex-end"}
          sx={{
            width: "100%",
            borderTop: matches ? "1px solid" : "none",
            borderColor: colors.outline,
          }}
        >
          <Stack sx={{ mt: "30px", width: "100%" }} alignItems="center">
            <BasicButton onClick={handleNextButtonClick} label={t("next")} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PlacementList;
