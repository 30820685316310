import { useNavigate } from "react-router-dom";
import Container from "components/Container";
import PrimaryContactForm from "entities/PrimaryContact/components/Form";

import { familyUpdate, IFamily, useMyFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { DeepPartial } from "utils/common";

const PrimaryContactPage = () => {
  const { data: family } = useMyFamily();
  const navigate = useNavigate();

  const handleSave = (data: DeepPartial<IFamily>) => {
    return familyUpdate({ data }).then(() => {
      navigate(URLS.FAMILY_INFORMATION_PARENTS);
    });
  };

  if (!family) {
    return null;
  }

  return (
    <Container maxWidth="xs">
      <PrimaryContactForm initialValues={family} onSave={handleSave} />
    </Container>
  );
};

export default PrimaryContactPage;
