import { useTranslation } from "react-i18next";

import MenuItem from "components/MenuItem";
import Select, { SelectChangeEvent } from "components/Select";
import FormControl from "components/FormControl";
import InputAdornment from "components/InputAdornment";

import { GlobeIcon } from "icons";
import { saveLanguage } from "utils/storage";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    const newLang = event.target.value as string;
    i18n.changeLanguage(newLang);
    saveLanguage(newLang);
  };

  return (
    <FormControl variant="outlined" size="small" sx={{ m: 1 }}>
      <Select
        value={i18n.language}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Language Selector" }}
        startAdornment={
          <InputAdornment position="start">
            <GlobeIcon />
          </InputAdornment>
        }
        sx={{
          height: "32px",
          "& .MuiSelect-select": {
            paddingLeft: 1,
          },
        }}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="es">Spanish</MenuItem>
        <MenuItem value="kea">Cape Verdean Creole</MenuItem>
        <MenuItem value="ht">Haitian Creole</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
