import { useEffect, useState } from "react";

import TextField from "../TextField";
import Autocomplete from "../Autocomplete";
import InputAdornment from "../InputAdornment";

import { TextFieldProps } from "@mui/material";

import { colors } from "../../theme";
import { MagnifierIcon } from "icons";
import "./styles.css";

type OptionType = {
  label: string;
  value: string;
  key: string;
};

type AutocompleteStyledSelectProps = {
  options: OptionType[];
  onSearch: (query: string) => void;
  onSelect: (value: string) => void;
  value: string | null;
  isReadOnly?: boolean;
} & TextFieldProps;

const AutocompleteStyledSelect: React.FC<AutocompleteStyledSelectProps> = ({
  options,
  onSearch,
  onSelect,
  value,
  isReadOnly,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

  useEffect(() => {
    if (isReadOnly) {
      setSelectedOption(
        options.find((option) => option.value === value) || null
      );
    }
  }, [value, options, isReadOnly]);
  useEffect(() => {
    onSearch(inputValue);
  }, [inputValue, onSearch]);

  const handleInputChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newInputValue: string,
    _reason: string
  ) => {
    setInputValue(newInputValue);
  };

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: OptionType | string | null
  ) => {
    event.preventDefault();

    const valueToSend =
      typeof newValue === "object" && newValue !== null
        ? newValue.value
        : newValue;
    onSelect(valueToSend || "");
    setSelectedValue(valueToSend);
  };

  return (
    <Autocomplete
      freeSolo
      options={options}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.label
      }
      onInputChange={handleInputChange}
      onChange={handleChange}
      value={isReadOnly ? selectedOption : null}
      readOnly={isReadOnly}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {!params.InputProps.endAdornment ? (
                  <MagnifierIcon />
                ) : (
                  params.InputProps.endAdornment
                )}
              </InputAdornment>
            ),
          }}
          sx={{
            borderRadius: "4px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: colors.outline,
              },
              "&:hover fieldset": {
                borderColor: colors.primary,
              },
              "&.Mui-focused fieldset": {
                borderColor: colors.primary,
              },
            },
            "& .MuiFormLabel-root": {
              color: colors.onSurface,
              "&.Mui-focused": {
                color: colors.primary,
              },
            },
          }}
        />
      )}
      PaperComponent={({ children, ...paperProps }) => (
        <ul
          {...paperProps}
          style={{
            backgroundColor: colors.surfaceContainer,
            padding: 0,
            margin: 0,
            borderRadius: "4px",
            boxShadow:
              "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
          }}
        >
          {children}
        </ul>
      )}
      renderOption={(props, option) => (
        <li
          {...props}
          key={option.key}
          className={`option-item ${option.value === selectedValue ? "option-item-selected" : ""}`}
        >
          {option.label}
        </li>
      )}
    />
  );
};

export default AutocompleteStyledSelect;
