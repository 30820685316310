import { useParams, useNavigate } from "react-router-dom";

import ChildForm from "entities/Child/components/Form";

import { useMyFamily } from "entities/Family/sdk";
import { childUpdateInUserFamily, IChild } from "entities/Child/sdk";

import { URLS } from "config/urls";

const ChildEditPage = () => {
  const { childId: childIdParam } = useParams<{ childId: string }>();
  const childId = parseInt(String(childIdParam));
  const navigate = useNavigate();
  const { data: family } = useMyFamily();
  const child = family?.children.find((child) => child.id === childId);

  const handleSave = (data: Partial<IChild>) => {
    return childUpdateInUserFamily({ childId, data: data }).then(() => {
      navigate(URLS.FAMILY_INFORMATION_CHILDREN);
    });
  };

  return <ChildForm initialValues={child} onSave={handleSave} />;
};

export default ChildEditPage;
