import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Container from "components/Container";
import FullPageDialog from "components/FullPageDialog";
import Stack from "components/Stack";
import Link from "components/Link";
import BasicButton from "components/BasicButton";
import AddButton from "components/AddButton";

import PlacementListItem from "entities/Placement/components/ListItem";

import { useMyFamily, IFamily } from "entities/Family/sdk";

import { URLS } from "config/urls";
import { reverse } from "utils/urls";
import { colors } from "theme";

const PlacementListPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: family } = useMyFamily();
  const placements = family?.vouchers.flatMap((voucher) => voucher.placements);

  const hasMissingFields = (
    placement: IFamily["vouchers"][number]["placements"][number]
  ) => {
    if (!family?.missing_fields) {
      return false;
    }

    return (
      family.missing_fields
        .flatMap((voucher) => voucher.missing_fields.placement_missing_fields)
        .filter(
          (placementMissingFields) =>
            placementMissingFields.id === placement.id &&
            placementMissingFields.missing_fields.length > 0
        ).length > 0
    );
  };

  if (!placements) {
    return null;
  }

  return (
    <FullPageDialog
      title={t("placements")}
      progressBarPercentage={40}
      backUrl={URLS.MISSING_INFORMATION_AUTHORIZATION_LIST}
      closeUrl={URLS.HOME}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 2,
        }}
      >
        {placements.map((placement) => (
          <PlacementListItem
            placement={placement}
            voucher={placement.voucher}
            onEdit={() => {
              navigate(
                reverse(URLS.MISSING_INFORMATION_PLACEMENT_EDIT, {
                  placementId: placement.id,
                })
              );
            }}
            hasMissingFields={hasMissingFields(placement)}
          />
        ))}
        <Link
          to={URLS.MISSING_INFORMATION_PLACEMENT_ADD}
          style={{ display: "components" }}
        >
          <AddButton
            label={t("addPlacement")}
            backgroundColor={colors.secondaryContainer}
          />
        </Link>
      </Container>
      <Stack
        sx={{
          marginTop: "30px",
          alignItems: "center",
          borderTop: "1px solid " + colors.outlineVariant,
          paddingTop: (theme) => theme.spacing(3),
        }}
      >
        <Link to={URLS.HOME}>
          <BasicButton label={t("next")} />
        </Link>
      </Stack>
    </FullPageDialog>
  );
};

export default PlacementListPage;
