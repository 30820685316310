import { useTranslation } from "react-i18next";

import { IWeeklyScheduleData } from "entities/Documents/sdk";
import {
  IAdditionalInformation,
  IAddressUpdate,
  IChildCreate,
  IEmploymentStatusUpdate,
  ISpecialCircumstances,
  employmentStatus,
  gender,
} from "entities/FamilyMember/sdk";
import { CompletedStatus } from "entities/FamilySpecialist/sdk";

import { BASE_URL, post, useFetch } from "utils/sdk";

export const contactMethodOptions = [
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
  { label: "No Concept", value: "NO_CONCEPT" },
];

export interface ParentData {
  preferred_name: string;
  first_name: string;
  last_name: string;
  gender: gender;
  preferred_language: string;
  employment_status: employmentStatus;
  employer_name?: string;
  other_status_description?: string;
  id?: number;
  date_of_birth?: string;
}

export interface AuthenticationData extends ISpecialCircumstances {
  type?: string;
  status?: CompletedStatus;
  children?: string[];
  created_at?: string;
  is_on_waitlist?: boolean;
  referral_date?: string;
  child_care_status?: string;
  referral_source?: string;
  shelter?: string;
  shelter_contact?: string;
  shelter_contact_email?: string;
  shelter_phone?: string;
  dta_referral_number?: string;
  ccrr?: string;
  dta_program?: string;
  service_need?: string;
  dcf_case_number?: string;
  dcf_authorization_number?: string;
  dcf_region?: string;
  dta_referral_type?: string;
  tafdc_closing_date?: string;
  issuing_location?: string;
  proof_of_identification_verified?: boolean;
}

export const useTypeOptions = () => {
  const { t } = useTranslation();
  return [
    { label: t("waitlist"), value: "WAITLIST" },
    { label: t("dtaReferral"), value: "DTA_REFERRAL" },
    { label: t("dcfReferral"), value: "DCF_REFERRAL" },
    { label: t("homelessReferral"), value: "HOMELESS_REFERRAL" },
    { label: t("unknown"), value: "UNKNOWN" },
  ];
};

export const useCreateTypeOptions = () => {
  const { t } = useTranslation();
  return [
    { label: t("waitlist"), value: "WAITLIST" },
    { label: t("dtaReferral"), value: "DTA_REFERRAL" },
    { label: t("dcfReferral"), value: "DCF_REFERRAL" },
    { label: t("homelessReferral"), value: "HOMELESS_REFERRAL" },
  ];
};

export const useStatusOptions = () => {
  const { t } = useTranslation();
  return [
    {
      label: t("incompleteProfile"),
      value: "INCOMPLETE_PROFILE",
    },
    { label: t("readyToAddToWaitlist"), value: "READY_TO_ADD_TO_WAITLIST" },
    { label: t("incompleteSignUp"), value: "INCOMPLETE_SIGN_UP" },
    { label: t("onWaitlist"), value: "ON_WAITLIST" },
    { label: t("missingReferralLetter"), value: "MISSING_REFERRAL_LETTER" },
    { label: t("onCCFA"), value: "ON_CCFA" },
    { label: t("readyForCCFA"), value: "READY_FOR_CCFA" },
    {
      label: t("incompleteReferralInformation"),
      value: "INCOMPLETE_REFERRAL_INFORMATION",
    },
  ];
};

export enum PreferredContactMethod {
  SMS = "SMS",
  MAIL = "MAIL",
}

export enum PhoneStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  NO_CONCEPT = "NO_CONCEPT",
}

export enum EmailStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  NO_CONCEPT = "NO_CONCEPT",
}
export interface IPhoneContactMethod {
  id?: number;
  phone_number: string;
  status: PhoneStatus;
}

export interface IEmailContactMethod {
  id?: number;
  email: string;
  status: EmailStatus;
}

export interface ICreator {
  preferred_language: string;
  preferred_contact_method: PreferredContactMethod;
  phone_contact_methods: IPhoneContactMethod[];
  email_contact_methods: IEmailContactMethod[];
}

interface IContactMethods {
  phone_contact_methods: IPhoneContactMethod[];
  email_contact_methods: IEmailContactMethod[];
}

export interface IFamilyUpdateData {
  household_id: string;
  family_id: string;
  preferred_contact: string;
  creator: ICreator;
  street_address: string;
  unit_address?: string;
  city: string;
  zip_code: string;
}

export interface IWaitlistCreateData {
  preferred_name: string;
  first_name: string;
  last_name: string;
  preferred_language?: string;
  gender?: gender;
  contact_methods: IContactMethods;
  preferred_contact_method: PreferredContactMethod;
  completed_status: CompletedStatus;
}

export interface IParents {
  family_members: Array<{
    first_name: string;
    last_name: string;
    preferred_name: string;
    preferred_language: string;
    employment_status: employmentStatus;
    employer_name?: string;
    other_status_description?: string;
    gender: gender;
    date_of_birth?: string;
  }>;
  id: number;
}

interface IWaitlistResponse {
  id: number;
}

export interface ISummary {
  child_care_need_summary: string;
}

export interface IDailyData {
  schedule: string;
  transportation: string;
}

export interface IPlacementData {
  child: number;
  child_care_need?: string;
  child_care_duration?: string;
  preferred_program_type?: string;
  child_care_transportation_needs?: string;
  provider_name?: string;
  provider_program?: string;
}

interface IChild {
  id: number;
  first_name: string;
  last_name: string;
}
export interface IPlacement {
  id: number;
  child_care_need?: string;
  child_care_duration?: string;
  preferred_program_type?: string;
  child_care_transportation_needs?: string;
  provider_name?: string;
  provider_program?: string;
  updated_at: string;

  child: IChild;

  monday_schedule: string;
  monday_transportation: string;
  tuesday_schedule: string;
  tuesday_transportation: string;
  wednesday_schedule: string;
  wednesday_transportation: string;
  thursday_schedule: string;
  thursday_transportation: string;
  friday_schedule: string;
  friday_transportation: string;
  saturday_schedule: string;
  saturday_transportation: string;
  sunday_schedule: string;
  sunday_transportation: string;
}

export const familyUpdate = (id: number, data: IFamilyUpdateData) =>
  post<IFamilyUpdateData>(
    `${BASE_URL}/api/family-specialists/family/${id}/update/`,
    data
  );

export const authorizationCreate = ({
  id,
  data,
}: {
  id: number;
  data: AuthenticationData;
}) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${id}/authorization/create/`,
    data
  );

export const authorizationUpdate = ({
  id,
  authorization_id,
  data,
}: {
  id: number;
  authorization_id: number;
  data: AuthenticationData;
}) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${id}/authorization/${authorization_id}/update/`,
    data
  );

export const waitlistCreate = (data: IWaitlistCreateData) =>
  post<IWaitlistResponse>(
    `${BASE_URL}/api/family-specialists/family/waitlist/create/`,
    data
  );

export const familySpecialistEmploymentStatusUpdate = (
  data: IEmploymentStatusUpdate,
  family_id: number
) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${family_id}/employment-status/update/`,
    data
  );

export const familySpecialistAddressUpdate = (
  data: IAddressUpdate,
  family_id: number
) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${family_id}/mailing/update/`,
    data
  );

export const useFamilySpecialistChildren = (family_id: number) => {
  return useFetch<IChildCreate[]>(
    `/api/family-specialists/family/${family_id}/children/`
  );
};

export const useFamilySpecialistParents = (family_id: number) => {
  return useFetch<IParents>(
    `/api/family-specialists/family/${family_id}/parents/`
  );
};

export const familySpecialistAdditionalInformationUpdate = (
  id: number,
  data: IAdditionalInformation
) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${id}/additional-information/update/`,
    data
  );

export const familySpecialistSpecialCircumstanceUpdate = (
  family_id: number,
  data: ISpecialCircumstances
) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${family_id}/update-family-booleans/ `,
    data
  );

export const familySpecialistSummaryUpdate = (
  family_id: number,
  data: ISummary
) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${family_id}/summary-update/ `,
    data
  );

export const addNote = (family_id: number, data: { content: string }) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${family_id}/note/create/ `,
    data
  );

export const bulkReassign = (
  from_specialist_id: number,
  to_specialist_id: number
) =>
  post(
    `${BASE_URL}/api/family-specialists/re-assign-families/${from_specialist_id}/${to_specialist_id}/ `
  );

export const placementUpdate = (
  family_id: number,
  placement_id: number,
  data: IPlacementData
) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${family_id}/placement/${placement_id}/update/`,
    data
  );

export const placementCreate = (family_id: number, data: IPlacementData) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${family_id}/placement/create/`,
    data
  );

export const weaklyScheduleTableUpdate = (
  id: number,
  weekly_schedule_id: number,
  data: IWeeklyScheduleData
) =>
  post(
    `${BASE_URL}/api/family-specialists/family/${id}/weekly-schedule/${weekly_schedule_id}/update/ `,
    data
  );
