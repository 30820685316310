import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import useMediaQuery from "components/useMediaQuery";
import Chip from "components/Chip";

import { useSignedUser } from "entities/Auth/sdk";
import useReadableStatus, {
  CompletedStatus,
} from "entities/FamilySpecialist/sdk";
import { useMyFamily } from "entities/Family/sdk";

import { ProfileIcon, EditIcon, AttentionIcon, CalendarIcon } from "icons";
import { colors } from "theme";
import { URLS } from "config/urls";
import { formatToCamelCase } from "utils/format";

const Home = () => {
  const matches = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const { data: family } = useMyFamily();
  const { t } = useTranslation();
  const { getReadableCompletedStatus } = useReadableStatus();
  const { data: user } = useSignedUser();

  const handleClick = () => {
    navigate(URLS.MISSING_INFORMATION_PRIMARY_CONTACT);
  };

  const handleButtonClick = () => {
    navigate(URLS.GET_HELP);
  };

  const openScheduleMeeting = () => {
    window.open(
      "https://booknow.appointment-plus.com/180n6yhv/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const hasMissingFields = family?.missing_fields
    ? family.missing_fields.filter((voucher) => {
        return (
          voucher.missing_fields.family_missing_fields.length > 0 ||
          voucher.missing_fields.voucher_missing_fields.length > 0 ||
          voucher.missing_fields.global_errors.length > 0 ||
          voucher.missing_fields.placement_missing_fields.length > 0 ||
          voucher.missing_fields.parent_missing_fields.filter(
            (parent) => parent.missing_fields.length > 0
          ).length > 0 ||
          voucher.missing_fields.child_missing_fields.filter(
            (child) => child.missing_fields.length > 0
          ).length > 0
        );
      }).length > 0
    : false;

  if (!user) return null;

  return (
    <Stack
      sx={{ height: "calc(100vh - 100px)", justifyContent: "space-between" }}
    >
      <Stack gap="15px">
        {hasMissingFields && (
          <Stack
            onClick={handleClick}
            direction="row"
            gap="15px"
            alignItems="center"
            sx={{
              backgroundColor: colors.secondaryContainer,
              padding: "15px",
              cursor: "pointer",
            }}
          >
            <AttentionIcon width="56px" height="56px" />
            <Stack>
              <Text variant="titleMedium" sx={{ mt: 2 }}>
                {t("actionNeeded")}:
              </Text>
              <Text variant="titleMedium" sx={{ mb: 1 }}>
                {t("completeFamilyInformation")}
              </Text>
            </Stack>
          </Stack>
        )}
        <Stack
          direction="row"
          gap="15px"
          alignItems="center"
          sx={{ backgroundColor: colors.surfaceContainer, padding: "15px" }}
        >
          <ProfileIcon width="56px" height="56px" />
          {user.vouchers && user.vouchers[0].status !== "ON_WAITLIST" && (
            <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
              {t("myStatus", {
                currentStatus: getReadableCompletedStatus(
                  user.vouchers[0]?.status as CompletedStatus
                ),
              })}
            </Text>
          )}
          {user.vouchers && user.vouchers[0]?.status === "ON_WAITLIST" && (
            <Stack>
              <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
                {user.first_name} {user.last_name}
              </Text>
              <Text variant="titleMedium" sx={{ mb: 1 }}>
                {user.vouchers[0]?.type &&
                  t(formatToCamelCase(user.vouchers[0]?.type))}
              </Text>
              <Text variant="titleMedium" sx={{ mb: 1 }}>
                {user.vouchers[0]?.status &&
                  getReadableCompletedStatus(
                    user.vouchers[0]?.status as CompletedStatus
                  )}
              </Text>
              {user.household_id && (
                <Chip
                  label={`${t("householdId")}: ${user.household_id}`}
                  sx={{
                    border: `1px solid ${colors.outline}`,
                    color: colors.onSurfaceVariant,
                    backgroundColor: colors.surfaceDim,
                    borderRadius: "8px",
                  }}
                />
              )}
            </Stack>
          )}
        </Stack>
        <Stack
          direction="row"
          gap="15px"
          alignItems="center"
          sx={{
            backgroundColor: colors.surfaceContainer,
            padding: "15px",
            cursor: "pointer",
          }}
          onClick={openScheduleMeeting}
        >
          <CalendarIcon width={matches ? "56px" : "86px"} height="56px" />
          <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
            {t("scheduleMeetingWithFamilyAdministrator")}
          </Text>
        </Stack>
        <Stack
          direction="row"
          gap="15px"
          alignItems="center"
          sx={{ backgroundColor: colors.surfaceContainer, padding: "15px" }}
        >
          <EditIcon width="56px" height="56px" />
          <Text variant="titleMedium" sx={{ mt: 2, mb: 1 }}>
            {t("updateMyInformation")}
          </Text>
        </Stack>
      </Stack>
      {!matches && (
        <Stack
          alignItems="center"
          sx={{
            backgroundColor: colors.surfaceContainer,
            position: "absolute",
            bottom: 0,
            width: "100%",
            left: 0,
            height: "115px",
            borderRadius: "10px",
          }}
        >
          <BasicButton
            label={t("needHelp")}
            width={matches ? "322px" : "161px"}
            height="56px"
            onClick={handleButtonClick}
            sx={{ borderRadius: "16px", position: "absolute", bottom: "85px" }}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default Home;
