import { useState } from "react";
import { useTranslation } from "react-i18next";

import BasicButton from "components/BasicButton";
import Stack from "components/Stack";
import Link from "components/Link";

import { BASE_URL } from "config/urls";

import { colors } from "theme";
import BulkReassignDialog from "entities/FamilyEdit/BulkReassignDialog";

const Admin = () => {
  const { t } = useTranslation();
  const [openBulkReassign, setOpenBulkReassign] = useState(false);

  const handleBulkReassignOpen = () => {
    setOpenBulkReassign(true);
  };

  const handleBulkReassignClose = () => {
    setOpenBulkReassign(false);
  };

  return (
    <Stack
      justifyContent="flex-start"
      gap="20px"
      sx={{
        minHeight: "calc(100vh - 60px)",
        backgroundColor: colors.surface,
        padding: "30px",
      }}
    >
      <Stack alignItems={"center"}>
        <Link to={`${BASE_URL}/admin/`} external target="_blank">
          <BasicButton label={"Super User Admin"} />
        </Link>
      </Stack>
      <Stack alignItems={"center"}>
        <BasicButton
          label={t("bulkReassignFamilies")}
          onClick={handleBulkReassignOpen}
        />
      </Stack>
      <BulkReassignDialog
        open={openBulkReassign}
        onClose={handleBulkReassignClose}
      />
    </Stack>
  );
};
export default Admin;
