import { useNavigate } from "react-router-dom";

import { URLS } from "config/urls";

import { useTranslation } from "react-i18next";

import BasicButton from "components/BasicButton";
import Stack from "components/Stack";
import Text from "components/Text";
import LanguageSelector from "components/LanguageSelector";
import useMediaQuery from "components/useMediaQuery";

import LoginLogo from "assets/login-page-logo.png";

import { colors } from "theme/palette";

const InitialPage = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const { t } = useTranslation();
  const handleSignIn = () => {
    navigate(URLS.LOGIN);
  };
  const handleSignUp = () => {
    navigate(URLS.SIGNUP);
  };

  return (
    <div
      style={{
        backgroundColor: colors.surfaceContainer,
        height: "100vh",
        display: "flex",
        flexDirection: matches ? "row" : "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: colors.primary,
          width: "100%",
          height: matches ? "auto" : "50vh",
          flex: 2,
        }}
      >
        <img src={LoginLogo} alt="ParentPortal Logo" />
      </div>
      <Stack
        spacing={2}
        direction="column"
        sx={{
          flexGrow: 1,
          justifyContent: matches ? "space-evenly" : "flex-start",
          alignItems: matches ? "center" : "flex-start",
          margin: "20px",
          flex: 1,
        }}
      >
        <Stack sx={{ alignSelf: "center" }}>
          <LanguageSelector />
        </Stack>
        <Stack alignItems="center" gap="15px">
          <Text variant="headlineLarge" sx={{ textAlign: "center" }}>
            {t("welcomeMessage")}
          </Text>
          <Text variant="titleMedium" sx={{ textAlign: "center" }}>
            {t("ourGoal")}
          </Text>
        </Stack>
        <Stack
          gap="20px"
          style={{
            width: "100%",
            marginTop: "30px",
            alignItems: matches ? "center" : "flex-start",
          }}
        >
          <BasicButton
            label={t("signIn")}
            backgroundColor={colors.secondaryContainer}
            color={colors.onSecondaryContainer}
            onHoverBackground={colors.onHoverSecondaryContainer}
            onClick={handleSignIn}
          />
          <BasicButton label={t("signUp")} onClick={handleSignUp} />
        </Stack>
      </Stack>
    </div>
  );
};

export default InitialPage;
