import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import Box from "components/Box";
import IconButton from "components/IconButton";
import Text from "components/Text";
import Stack from "components/Stack";

import {
  useReadableStatus,
  AuthorizationStatus,
} from "entities/Authorization/sdk";

import { EditIcon } from "icons";
import { colors } from "theme";
import { capitalCaseToCamelCase } from "utils/common";

type IProps = {
  authorization: {
    title: string;
    updated_at: string;
    type: string;
    status: AuthorizationStatus;
  };
  onEdit: () => void;
  hasIncompleteWarning?: boolean;
};

const AuthorizationListItem = ({
  authorization: { title, updated_at, type, status },
  onEdit,
  hasIncompleteWarning = false,
}: IProps) => {
  const { t } = useTranslation();
  const { getReadableCompletedStatus } = useReadableStatus();

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: colors.outline,
        borderRadius: "8px",
        padding: "16px",
        marginBottom: "16px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Stack gap="15px">
        <Text variant="titleMedium">{title}</Text>
        <Text variant="labelSmall" color="textSecondary">
          Last Activity: {dayjs(updated_at).format("MM/DD/YYYY")}
        </Text>
        <Stack direction="row" gap="30px">
          <Text variant="titleMedium">{t(capitalCaseToCamelCase(type))}</Text>
          <Text variant="titleMedium">
            {getReadableCompletedStatus(status)}
          </Text>
        </Stack>
        {hasIncompleteWarning && (
          <Text sx={{ color: colors.error }}>Incomplete</Text>
        )}
      </Stack>
      <IconButton
        sx={{
          position: "absolute",
          top: "16px",
          right: "16px",
          height: "40px",
          width: "40px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          onEdit();
        }}
      >
        <EditIcon />
      </IconButton>
    </Box>
  );
};

export default AuthorizationListItem;
