import { useTranslation } from "react-i18next";

import Stack from "components/Stack";
import Text from "components/Text";
import Box from "components/Box";
import useMediaQuery from "components/useMediaQuery";

import { colors } from "theme";

const ThankYouPage = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Stack
      flexDirection={"column"}
      justifyContent={"space-between"}
      alignItems="center"
      sx={{
        minHeight: `calc(100vh - 140px)`,
      }}
    >
      <Box sx={{ width: matches ? "50%" : "auto" }}>
        <Stack alignItems="center">
          <Text variant="titleMedium" sx={{ mt: 2, mb: 2 }}>
            {t("followUpHeader")}
          </Text>
          <Text variant="titleMedium" sx={{ mt: 2 }}>
            {t("needHelpNow")}
          </Text>

          <Text variant="titleLarge" color={colors.primary} sx={{ mt: "5px" }}>
            {t("callUs")}
          </Text>
        </Stack>
      </Box>
    </Stack>
  );
};

export default ThankYouPage;
