import Stack from "components/Stack";
import Link from "components/Link";
import Text from "components/Text";
import IconButton from "components/IconButton";
import { ArrowLeftIcon, CloseIcon } from "icons";
import theme, { colors } from "theme";

interface IProps {
  title?: React.ReactNode;
  progressBarPercentage?: number;
  backUrl?: string;
  closeUrl?: string;
}

const FullPageDialog = ({
  title,
  children,
  backUrl,
  closeUrl,
  progressBarPercentage = 0,
}: React.PropsWithChildren<IProps>) => {
  return (
    <Stack>
      <Stack
        direction="row"
        sx={{
          flex: "64px 0 0",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 4px",
          [theme.breakpoints.up("md")]: {
            padding: "0px 16px",
          },
        }}
      >
        {backUrl ? (
          <Link to={backUrl}>
            <IconButton size="large" sx={{ margin: "4px" }}>
              <ArrowLeftIcon style={{ fill: colors.onSurfaceVariant }} />
            </IconButton>
          </Link>
        ) : (
          <div />
        )}
        <Text
          variant="titleLarge"
          sx={{ fontWeight: 600, color: colors.onSurfaceVariant }}
        >
          {title}
        </Text>
        {closeUrl ? (
          <Link to={closeUrl}>
            <IconButton size="large" sx={{ margin: "4px" }}>
              <CloseIcon style={{ fill: colors.onSurfaceVariant }} />
            </IconButton>
          </Link>
        ) : (
          <div />
        )}
      </Stack>
      <div
        style={{ display: "flex", gap: "4px", padding: theme.spacing(0.5, 2) }}
      >
        <div
          style={{
            flex: progressBarPercentage,
            borderRadius: "4px",
            height: "4px",
            backgroundColor: "#B30838",
          }}
        ></div>
        <div
          style={{
            flex: 100 - progressBarPercentage,
            borderRadius: "4px",
            height: "4px",
            backgroundColor: "#DA2C52",
          }}
        ></div>
      </div>
      {children}
    </Stack>
  );
};

export default FullPageDialog;
