import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Stack from "components/Stack";
import Text from "components/Text";
import BasicButton from "components/BasicButton";
import Box from "components/Box";
import useMediaQuery from "components/useMediaQuery";

import overviewVideoEnglish from "assets/overview_video_english.mp4";
import overviewVideoSpanish from "assets/overview_video_spanish.mp4";

import { URLS } from "config/urls";
import { colors } from "theme";
import { getLanguage } from "utils/storage";

const Overview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const storedLanguage = getLanguage();

  const handleNextClick = () => {
    navigate(URLS.FAMILY_INFORMATION_ABOUT_YOU);
  };

  return (
    <>
      <Stack alignItems="center">
        <Text variant="titleMedium" sx={{ mt: 2, mb: 2 }}>
          {t("financialAssistanceHelp")}
        </Text>
        <Text variant="titleMedium" sx={{ mt: 2 }}>
          {t("learnMoreVideo")}
        </Text>
        <Box
          sx={{
            maxWidth: storedLanguage === "es" ? "800px" : "600px",
            width: "100%",
            height: "auto",
            mt: 2,
            mb: 2,
            overflow: "hidden",
          }}
        >
          <video
            width="100%"
            height="100%"
            controls
            style={{ display: "block", width: "100%", height: "100%" }}
          >
            <source
              src={
                storedLanguage === "es"
                  ? overviewVideoSpanish
                  : overviewVideoEnglish
              }
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Box>
        <Text variant="titleMedium" sx={{ mt: "5px" }}>
          {t("navigateProcessHelp")}
        </Text>
      </Stack>
      <Stack
        sx={{
          borderTop: matches ? "1px solid" : "none",
          borderColor: colors.outline,
          marginTop: "30px",
        }}
      >
        <Stack
          gap="20px"
          alignItems="center"
          sx={{
            textAlign: "right",
            mt: "30px",
          }}
        >
          <BasicButton label={t("next")} onClick={handleNextClick} />
        </Stack>
      </Stack>
    </>
  );
};

export default Overview;
