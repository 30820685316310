import { colors } from "theme/palette";
import { createTheme } from "@mui/material/styles";
import typographyVariants from "./fonts";

const theme = createTheme({
  typography: {
    fontFamily: "Public Sans, sans-serif",
    ...typographyVariants,
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          border: "1px solid " + colors.outline,
          backgroundColor: colors.surfaceDim,
        },
      },
    },
  },
});
export { colors };

export default theme;
