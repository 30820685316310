import { styled } from "@mui/system";
import OriginalRadio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { colors } from "theme";

const Radio = styled(OriginalRadio)(() => ({
  "&.Mui-checked": {
    color: colors.primary,
  },
}));

export { RadioGroup };
export default Radio;
