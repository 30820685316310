import _ from "lodash";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import useSWR from "swr";
import { getLanguage } from "utils/storage";
import qs from "qs";
import { isArrayOfStrings, flattenObjectDeep } from "utils/common";

const BASE_BACKEND_URL = import.meta.env.VITE_BASE_BACKEND_URL;
export const BASE_URL = `${BASE_BACKEND_URL}`;
const ENV_NAME = import.meta.env.VITE_ENV_NAME;
export const IS_PRODUCTION = ENV_NAME === "production";

export type IConfig<D> = AxiosRequestConfig<D> & {
  isFileUpload?: boolean;
  useSharableToken?: boolean;
};

export const getConfig = <D>(params?: IConfig<D>) => {
  const selectedLanguage = getLanguage();
  const { headers = {}, isFileUpload = false, ...options } = params || {};

  const contentType = isFileUpload ? "multipart/form-data" : "application/json";

  const config: IConfig<D> = {
    headers: {
      "Content-Type": contentType,
      "Accept-Language": selectedLanguage,
      ...headers,
    } as IConfig<D>["headers"],
    withCredentials: true,
    ...options,
  };

  return config;
};

const extractData = <R, D>(response: AxiosResponse<R, D>) => response.data;

const apiErrorMessages = ["Signature has expired.", "Token has expired."];

const handleErrorResponse = (error: any) => {
  if (
    error.response?.status === 401 &&
    apiErrorMessages.includes(error?.response?.data?.detail)
  ) {
    // Perhaps logout the person by sending them to "/"
    // removeToken();
  }

  if (error.response?.status === 400 && error.response?.data.detail) {
    // Handle form errors
    const errorObject = flattenObjectDeep(
      error.response.data.detail,
      isArrayOfStrings
    );
    const formErrors = Object.keys(errorObject).map((fieldName) => ({
      fieldName,
      messages: _.get(error.response.data.detail, fieldName),
    }));

    const setFormErrors = (
      setError: (
        name: string,
        error: { type: "custom"; message: string }
      ) => void
    ) => {
      formErrors?.map(
        ({
          fieldName,
          messages,
        }: {
          fieldName: string;
          messages: string[];
        }) => {
          setError(fieldName as any, {
            type: "custom",
            message: messages.join(" "),
          });
        }
      );
    };

    return Promise.reject({
      ...error.response.data,
      setFormErrors,
      formErrors,
    });
  }

  return Promise.reject(error?.response?.data || "Something went wrong");
};

export const get = <R, D = any>(url: string, params?: IConfig<D>): Promise<R> =>
  axios
    .get<R>(url, getConfig(params))
    .then(extractData)
    .catch(handleErrorResponse);

export const post = <R, D = any>(
  url: string,
  data?: D,
  params?: IConfig<D>
): Promise<R> =>
  axios
    .post<R>(url, data, getConfig(params))
    .then(extractData)
    .catch(handleErrorResponse);

export const useFetch = <D>(
  url: string | undefined,
  queryParams?: Record<string, any>
) => {
  const queryString = queryParams
    ? `?${qs.stringify(queryParams, { arrayFormat: "repeat" })}`
    : "";
  const fullUrl = url ? `${BASE_URL}${url}${queryString}` : undefined;

  return useSWR<D>(fullUrl, get, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });
};
