import { useRef, useState, ChangeEvent, useEffect } from "react";
import { toast } from "react-toastify";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import BasicButton from "components/BasicButton";
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "components/Dialog";
import IconButton from "components/IconButton";
import Stack from "components/Stack";
import StyledSelect from "components/StyledSelect";
import TextField from "components/TextField";
import Text from "components/Text";
import ToggleButton, { ToggleButtonGroup } from "components/ToggleButton";
import Checkbox from "components/Checkbox";
import FormControlLabel from "components/FormControlLabel";
import InputAdornment from "components/InputAdornment";
import Box from "components/Box";
import List, { ListItem, ListItemText } from "components/List";
import FormLabel from "components/FormLabel";
import FormHelperText from "components/FormHelperText";
import LoadingScreen from "components/LoadingScreen";

import {
  AttachFileIcon,
  ChatIcon,
  CloseIcon,
  MailOutlinedIcon,
  CheckIcon,
} from "icons";
import { colors } from "theme";

import "./CustomStyles.css";
import {
  communicationTemplateRender,
  messageCreate,
  useCommunicationsTemplates,
  useFamilyContactMethods,
} from "../sdk";
import { filesUpload } from "entities/Files/sdk";
import AutocompleteStyledSelect from "components/AutocompleteStyledSelect";

interface IProps {
  open: boolean;
  onClose: () => void;
  familyId?: number;
  preferredContactMethod?: string;
}

interface FileWithPreview extends File {
  preview: string;
  id: number;
}

interface FormValues {
  family: string;
  communication_type: "EMAIL" | "SMS";
  template: string;
  subject: string;
  emails: boolean[];
  phones: boolean[];
}

function SendMessageDialog({
  open,
  onClose,
  familyId,
  preferredContactMethod,
}: IProps) {
  const { control, watch, setValue, register, handleSubmit, getValues, reset } =
    useForm<FormValues>({
      defaultValues: {
        family: familyId ? familyId.toString() : "",
        communication_type:
          preferredContactMethod && preferredContactMethod === "SMS"
            ? "SMS"
            : "EMAIL",
        template: "",
        subject: "",
        emails: [],
        phones: [],
      },
    });
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const { data: contactMethods } = useFamilyContactMethods({
    search: searchQuery,
    ...(familyId ? { family_id: familyId } : {}),
  });

  const { data: communicationTemplates } = useCommunicationsTemplates();

  const contactMethodsOptions =
    contactMethods?.results.map((contact) => ({
      label: `${contact.creator.first_name} ${contact.creator.last_name}`,
      value: contact.id.toString(),
      key: `${contact.creator.first_name}_${contact.creator.last_name}_${contact.id}`, // Assuming `contact.id` is unique
    })) || [];

  const communicationTemplatesOptions =
    communicationTemplates?.map((template) => ({
      label: template.name,
      value: template.id.toString(),
    })) || [];
  const contactMethod = watch("communication_type");
  const [message, setMessage] = useState<string>("");
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedContactId, setSelectedContactId] = useState<string | null>(
    familyId ? familyId.toString() : null
  );
  const [uploadedFileIds, setUploadedFileIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const MAX_FILE_SIZE = 15 * 1024 * 1024;

  useEffect(() => {
    if (familyId) {
      setSelectedContactId(familyId.toString());
      setValue("family", familyId.toString());
    }
  }, [familyId, open]);

  const selectedContact = contactMethods?.results.find(
    (contact) => contact.id.toString() === selectedContactId
  );
  const [errors, setErrors] = useState({
    communication_type: "",
    emails: "",
    phones: "",
    family: "",
    subject: "",
    body: "",
  });

  useEffect(() => {
    if (selectedContact) {
      selectedContact.creator.email_contact_methods.forEach((email) => {
        setValue(`emails.${email.id}`, false);
      });
      selectedContact.creator.phone_contact_methods.forEach((phone) => {
        setValue(`phones.${phone.id}`, false);
      });
    }
  }, [selectedContact, setValue]);

  const handleEditorChange = (_event: any, editor: any) => {
    const data = editor.getData();
    setMessage(data);
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    setIsLoading(true);
    const fileList = Array.from(event.target.files);
    const validFiles: File[] = [];
    const filesTooLarge: File[] = [];

    fileList.forEach((file) => {
      if (file.size > MAX_FILE_SIZE) {
        filesTooLarge.push(file);
      } else {
        validFiles.push(file);
      }
    });

    filesTooLarge.forEach((file) => {
      toast.error(t("fileExceedsLimit", { filename: file.name }));
    });

    if (validFiles.length === 0) {
      setIsLoading(false);
      return;
    }

    const uploadPromises = validFiles.map((file) => {
      return filesUpload({ file })
        .then((response) => {
          const fileWithPreview: FileWithPreview = {
            ...file,
            name: file.name,
            size: file.size,
            type: file.type,
            preview: URL.createObjectURL(file),
            id: response.data.id,
          };
          setFiles((prevFiles) => [...prevFiles, fileWithPreview]);
          setUploadedFileIds((prevIds) => [...prevIds, response.data.id]);
          toast.success(t("fileUploadSuccess", { filename: file.name }));
        })
        .catch((error) => {
          if (error.response) {
            const errorMessage =
              error.response?.data?.detail?.non_field_errors?.[0] ||
              t("uploadFailed");
            toast.error(errorMessage);
          } else {
            toast.error(t("uploadTimeoutError"));
          }
        });
    });

    Promise.all(uploadPromises).finally(() => {
      setIsLoading(false);
    });
  };

  const handleRemoveFile = (file: FileWithPreview) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    URL.revokeObjectURL(file.preview);
    setUploadedFileIds((prevIds) => prevIds.filter((id) => id !== file.id));
  };

  const triggerFileSelect = () => {
    fileInputRef.current?.click();
  };

  const editorConfiguration = {
    toolbar: [
      "bold",
      "italic",
      "underline",
      "bulletedList",
      "numberedList",
      "undo",
      "redo",
    ],
  };

  const handleTemplateChange = async (templateId: string) => {
    setValue("template", templateId);
    if (selectedContactId && templateId) {
      try {
        const templateContent = await communicationTemplateRender(
          Number(templateId),
          { family: Number(selectedContactId) }
        );
        setMessage(templateContent.body);
        setValue("subject", templateContent.subject);
      } catch (error) {
        toast.error(t("failedToLoadTemplate"));
      }
    } else {
      toast.error(t("selectRecipientAndTemplate"));
    }
  };

  const handleContactChange = async (contactId: string) => {
    setSelectedContactId(contactId);
    setValue("family", contactId);
    const values = getValues();
    if (values.template) {
      try {
        const templateContent = await communicationTemplateRender(
          Number(values.template),
          { family: Number(contactId) }
        );
        setMessage(templateContent.body);
        setValue("subject", templateContent.subject);
      } catch (error) {
        toast.error(t("failedToLoadTemplate"));
      }
    }
  };

  const handleClose = () => {
    reset({
      family: "",
      template: "",
      subject: "",
    });
    setSelectedContactId(null);
    setUploadedFileIds([]);
    setFiles([]);
    setMessage("");
    onClose();
  };

  const onSubmit = async () => {
    const values = getValues();
    let valid = true;
    const newErrors = {
      communication_type: "",
      emails: "",
      phones: "",
      family: "",
      subject: "",
      body: "",
    };

    if (!values.family) {
      newErrors.family = t("fieldRequired");
      valid = false;
    }

    if (!values.communication_type) {
      newErrors.communication_type = t("communicationTypeRequired");
      valid = false;
    }

    const hasEmails = values.emails?.some((value) => value) ?? false;
    if (values.communication_type === "EMAIL" && !hasEmails) {
      newErrors.emails = t("atLeastOneEmailRequired");
      valid = false;
    }

    const hasPhones = values.phones?.some((value) => value) ?? false;
    if (values.communication_type === "SMS" && !hasPhones) {
      newErrors.phones = t("atLeastOnePhoneRequired");
      valid = false;
    }
    if (values.communication_type === "EMAIL" && !values.subject) {
      newErrors.subject = t("fieldRequired");
      valid = false;
    }
    if (!message) {
      newErrors.body = t("fieldRequired");
      valid = false;
    }

    setErrors(newErrors);
    if (!valid) {
      return;
    }

    setIsSubmitting(true);
    const familyId = parseInt(selectedContactId || "0", 10);
    const templateId = parseInt(values.template, 10);

    const emailContactMethodIds: number[] =
      selectedContact?.creator.email_contact_methods
        .filter((email) => values.emails[email.id])
        .map((email) => email.id) || [];

    const phoneContactMethodIds: number[] =
      selectedContact?.creator.phone_contact_methods
        .filter((phone) => values.phones[phone.id])
        .map((phone) => phone.id) || [];

    const messageData = {
      family: familyId,
      body: message,
      communication_type: values.communication_type,
      email_contact_methods: emailContactMethodIds,
      phone_contact_methods: phoneContactMethodIds,
      files: uploadedFileIds,
      ...(values.communication_type === "EMAIL" &&
        templateId && {
          template: templateId,
        }),
      ...(values.communication_type === "EMAIL" && {
        subject: values.subject,
      }),
    };

    try {
      await messageCreate(messageData);
      toast.success(t("messageSentSuccessfully"));
      setIsSubmitting(false);
      handleClose();
    } catch (error) {
      toast.error(t("failedToSendMessage"));
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {isLoading && <LoadingScreen message={t("uploadingYourFiles")} />}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            backgroundColor: colors.surfaceContainerLow,
          },
        }}
      >
        <DialogTitle>
          {t("sendMessage")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon style={{ fill: colors.onSurfaceVariant }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Controller
            name="family"
            control={control}
            render={({ field }) => (
              <AutocompleteStyledSelect
                {...field}
                label={t("sendTo")}
                options={contactMethodsOptions}
                value={selectedContactId}
                onSelect={
                  ((value: string) => {
                    handleContactChange(value);
                    field.onChange(value);
                  }) as any
                }
                fullWidth
                margin="normal"
                isReadOnly={!!familyId}
                error={!!errors.family}
                helperText={errors.family}
                onSearch={setSearchQuery}
              />
            )}
          />

          <Stack direction="row" alignItems={"center"}>
            <Text variant="labelLarge" sx={{ flex: 1 }}>
              {t("contactMethod")}
            </Text>
            <Controller
              name="communication_type"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ToggleButtonGroup
                  value={value}
                  exclusive
                  onChange={(newValue) => onChange(newValue)}
                  fullWidth
                  sx={{
                    flex: 3,
                    "& .MuiToggleButtonGroup-grouped": {
                      margin: "8px 0",
                      border: `2px solid ${colors.outline}`,
                      textTransform: "capitalize",
                      justifyContent: "flex-start",
                      "&.Mui-selected": {
                        bgcolor: colors.secondaryContainer,
                        color: colors.onSecondaryContainer,
                        "&:hover": {
                          bgcolor: colors.onHoverSecondaryContainer,
                          color: colors.onSecondaryContainer,
                        },
                      },
                      "&:not(:first-of-type)": {
                        borderRadius: "0 50px 50px 0",
                      },
                      "&:first-of-type": {
                        borderRadius: "50px 0 0 50px",
                        borderRight: "1px solid",
                        borderColor: colors.outline,
                      },
                    },
                  }}
                >
                  <ToggleButton
                    value="SMS"
                    style={{
                      justifyContent: "flex-start",
                      paddingLeft: "30px",
                      height: "40px",
                    }}
                  >
                    {value === "SMS" && (
                      <CheckIcon style={{ marginRight: "15px" }} />
                    )}
                    {t("textMessage")}
                  </ToggleButton>
                  <ToggleButton
                    value="EMAIL"
                    style={{
                      justifyContent: "flex-start",
                      paddingLeft: "30px",
                      height: "40px",
                    }}
                  >
                    {value === "EMAIL" && (
                      <CheckIcon style={{ marginRight: "15px" }} />
                    )}
                    {t("email")}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          </Stack>
          <Stack gap="20px" sx={{ mt: "15px" }}>
            {contactMethod === "EMAIL" &&
              selectedContact?.creator.email_contact_methods
                .filter((email) => email.is_active)
                .map((email) => (
                  <FormControlLabel
                    key={email.id}
                    control={<Checkbox {...register(`emails.${email.id}`)} />}
                    label={
                      <TextField
                        label={t("familyEmail")}
                        fullWidth
                        disabled
                        value={email.email}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MailOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                ))}

            {contactMethod === "SMS" &&
              selectedContact?.creator.phone_contact_methods
                .filter((phone) => phone.is_active)
                .map((phone) => (
                  <FormControlLabel
                    key={phone.id}
                    control={<Checkbox {...register(`phones.${phone.id}`)} />}
                    label={
                      <TextField
                        label={t("familyPhoneNumber")}
                        fullWidth
                        disabled
                        value={phone.phone_number}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <ChatIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                ))}
            {contactMethod === "EMAIL" && (
              <FormHelperText error>{errors.emails}</FormHelperText>
            )}
            {contactMethod === "SMS" && (
              <FormHelperText error>{errors.phones}</FormHelperText>
            )}
            {contactMethod === "EMAIL" &&
              (selectedContact?.creator.email_contact_methods.length === 0 ||
                (selectedContact?.creator.email_contact_methods &&
                  !selectedContact?.creator.email_contact_methods.some(
                    (email) => email.is_active
                  ))) && (
                <Text
                  variant="titleMedium"
                  color={colors.primary}
                  sx={{ mb: "15px" }}
                >
                  {t("noEmailAddressesAvailable")}
                </Text>
              )}
            {contactMethod === "SMS" &&
              (selectedContact?.creator.phone_contact_methods.length === 0 ||
                (selectedContact?.creator.phone_contact_methods &&
                  !selectedContact?.creator.phone_contact_methods.some(
                    (phone) => phone.is_active
                  ))) && (
                <Text
                  variant="titleMedium"
                  color={colors.primary}
                  sx={{ mb: "15px" }}
                >
                  {t("noPhonesAvailable")}
                </Text>
              )}
          </Stack>
          {contactMethod === "EMAIL" && (
            <Controller
              name="template"
              control={control}
              render={({ field }) => (
                <StyledSelect
                  {...field}
                  label={t("useMessageTemplate")}
                  options={communicationTemplatesOptions}
                  fullWidth
                  margin="normal"
                  onChange={(e) => handleTemplateChange(e.target.value)}
                />
              )}
            />
          )}
          {contactMethod === "EMAIL" && (
            <Controller
              name="subject"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("subject")}
                  fullWidth
                  margin="normal"
                  placeholder="Subject"
                  error={!!errors.subject}
                  helperText={errors.subject}
                />
              )}
            />
          )}
          <Box sx={{ mt: 1.5 }}>
            {contactMethod === "EMAIL" && (
              <>
                <FormLabel sx={{ mb: 1 }}>{t("addYourMessage")}</FormLabel>
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data={message}
                  onChange={handleEditorChange}
                />
                <FormHelperText error>{errors.body}</FormHelperText>
              </>
            )}
            {contactMethod === "SMS" && (
              <TextField
                label={t("addYourMessage")}
                fullWidth
                multiline
                minRows={8}
                variant="outlined"
                onChange={(e) => setMessage(e.target.value)}
                error={!!errors.body}
                helperText={errors.body}
              />
            )}
            {contactMethod === "EMAIL" && (
              <>
                <IconButton
                  color="primary"
                  onClick={triggerFileSelect}
                  style={{ marginTop: 20 }}
                >
                  <AttachFileIcon />
                </IconButton>
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <List dense>
                  {files.map((file, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={file.name}
                        secondary={`${(file.size / 1024).toFixed(2)} KB`}
                      />
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleRemoveFile(file)}
                      >
                        <CloseIcon style={{ fill: colors.onSurfaceVariant }} />
                      </IconButton>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", mb: "20px" }}>
          <BasicButton
            label={t("sendMessage")}
            onClick={handleSubmit(onSubmit)}
            isDisabled={isSubmitting}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SendMessageDialog;
